import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import SelectedClientForm from 'mgr/actualslideout/components/client/SelectedClientForm'

SelectedClientForm.propTypes = {
  actions: React.PropTypes.object.isRequired,
  venue: React.PropTypes.object,
  selectedClient: React.PropTypes.object,
  venueClientTagGroups: React.PropTypes.array.isRequired,
  showMoreClientFields: React.PropTypes.bool,
  validateFieldMap: React.PropTypes.object.isRequired,
  formErrors: React.PropTypes.object.isRequired,
}

SelectedClientForm.defaultProps = {
  actions: {},
}

const mapStateToProps = state => {
  const venue = state.bookState.selectedVenue
  const { selectedClient, clientTagGroupsByVenue, showMoreClientFields } = state.bookClientState
  const { lockContactInfoOnPresenceOfThisField } = state.appState.venue
  const doLockFields = !!(
    _.isObject(selectedClient) &&
    lockContactInfoOnPresenceOfThisField &&
    // eslint-disable-next-line no-prototype-builtins
    selectedClient.hasOwnProperty(lockContactInfoOnPresenceOfThisField) &&
    !_.isEmpty(selectedClient[lockContactInfoOnPresenceOfThisField])
  )

  return {
    venue,
    venueClientTagGroups: venue ? clientTagGroupsByVenue[venue.id] || [] : [],
    selectedClient,
    bypassRequiredFields: _.isObject(state.bookSourceState.selectedSource) || (_.isObject(selectedClient) && !_.isEmpty(selectedClient.id)),
    showMoreClientFields,
    isDisabled: doLockFields,
    lockableClientFields: venue.lockableClientFields,
    alwaysLockedClientFields: venue.alwaysLockedClientFields,
    formErrors: state.bookState.firstInvalidStep === 'client' ? state.bookState.formErrors : {},
    canViewClientSpendData: state.bookClientState.initVenue.features.can_view_client_spend_data,
    salutationOptions: state.slideoutState.salutationOptions,
  }
}

export default connect(mapStateToProps)(SelectedClientForm)
