import { useMemo } from 'react'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormPercentageInput } from '@sevenrooms/core/ui-kit/form'
// eslint-disable-next-line import/no-relative-packages
import { useStoreSelector } from '../../../../../application/site/static/app/manager/actualslideout/src/selectors/useStoreSelector'
import { paymentMessages } from '../Payment.locales'
import { ChargeLine } from './ChargeLine'
import type { ChargesForm, ClientSelectGratuityForm } from '../Payment.zod'

interface GratuityProps {
  amount: number | null
  field: Field<ChargesForm>
  oldValue?: number | null
  override: boolean
  currencyCode: string
  clientSelectGratuity: Field<ClientSelectGratuityForm>
  forceDisableEdit?: boolean // need for beta
}

export function Gratuity({ amount, field, oldValue, currencyCode, override, forceDisableEdit, clientSelectGratuity }: GratuityProps) {
  const { formatMessage } = useLocales()

  const [gratuityValue, applyGratuity, gratuityClientSelect] = useWatchMany(field, ['gratuity', 'applyGratuity', 'gratuityClientSelect'])
  const [cGratuity, cGratuityClientSelect] = useWatchMany(clientSelectGratuity, ['gratuity', 'gratuityClientSelect'])

  const isShowInput = applyGratuity

  const { cardEntryOption } = useStoreSelector(state => state.bookPaymentState)

  const gratuity = useMemo(() => {
    if (!applyGratuity || (cardEntryOption === 'paylink' && !oldValue && gratuityClientSelect)) {
      return 0
    }

    return (cGratuityClientSelect && gratuityClientSelect ? cGratuity : gratuityValue) ?? 0
  }, [applyGratuity, cardEntryOption, oldValue, cGratuityClientSelect, gratuityClientSelect, cGratuity, gratuityValue])
  const chargeAmount = amount && applyGratuity ? amount * (gratuity / 100) : 0

  return (
    <ChargeLine
      title={
        !gratuityClientSelect ? formatMessage(paymentMessages.gratuity) : formatMessage(paymentMessages.clientSelectableGratuityLineItem)
      }
      // eslint-disable-next-line no-nested-ternary
      field={override && !forceDisableEdit ? (!gratuityClientSelect ? field.prop('applyGratuity') : undefined) : undefined}
      testId="sr-supafly-apply-gratuity-charge"
      oldAmount={oldValue != null && Number(oldValue) !== gratuity ? oldValue : null}
      amount={chargeAmount}
      currencyCode={currencyCode}
    >
      {isShowInput && (
        <FormPercentageInput
          data-test="sr-supafly-gratuity-charge"
          field={field.prop('gratuity')}
          fieldHeight="s"
          hideErrorMessage
          disabled={gratuityClientSelect ? true : !override || forceDisableEdit}
        />
      )}
    </ChargeLine>
  )
}
