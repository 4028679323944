import { type TypedUseSelectorHook, useSelector } from 'react-redux'
import type { InternalAvailabilityTimeslot, MixedAvailabilityTimeslot } from '@sevenrooms/core/api'
import type { GratuityType, PaymentForm } from '@sevenrooms/mgr-reservation-slideout/Payment'
import type { initialState as initialAvailabilityState } from '../reducers/BookAvailabilityReducer'
import type { initialState as initialClientState } from '../reducers/BookClientReducer'
import type { initialState as initialDetailsState } from '../reducers/BookDetailsReducer'
import type { initialState as initialPaymentState } from '../reducers/BookPaymentReducer'
import type { Actual, Charges, Venue, Transaction } from '../reducers/BookPaymentSlice.types'
import type { initialState as initialBookState, stepCollapsedStateDefault } from '../reducers/BookReducer'
import type { UpgradesState } from '../reducers/UpgradesSlice'

type BookAvailabilityState = {
  searchVenues: { id: string; internalDisplayName: string }[]
  availabilityByVenue: Record<
    string,
    { availableTimes?: InternalAvailabilityTimeslot[]; isTimesLoading: boolean; isTimesLoadError: boolean }
  >
  actual: Actual
  selectedTimeSlot: MixedAvailabilityTimeslot
  previousSelectedTimeSlot: MixedAvailabilityTimeslot
  internalArBookingEnabled?: boolean
  dailyEventsByVenueDate: { [dt: string]: { [v: string]: { events: unknown; notes: unknown; [k: string]: unknown } } }
} & Omit<
  typeof initialAvailabilityState,
  'searchVenues' | 'actual' | 'selectedTimeSlot' | 'previousSelectedTimeSlot' | 'dailyEventsByVenueDate'
>
type BookPaymentState = {
  outstandingPaylinkTransaction: Transaction
  outstandingAddFlowTransaction: Transaction
  allTransactions: Transaction[]
  availabilityChargeData: Charges | null
  paylinkGratuityType: GratuityType
  upgrades: {
    upsellAmount: number
    automaticallyIncludedUpsells: []
    selectedUpsells: {}
    chargeUpsellSnapshot?: {}
  }
  formSnapshot: Pick<PaymentForm, 'amount'>
} & Omit<
  typeof initialPaymentState,
  | 'outstandingPaylinkTransaction'
  | 'outstandingAddFlowTransaction'
  | 'allTransactions'
  | 'availabilityChargeData'
  | 'paylinkGratuityType'
  | 'upgrades'
>
type BookDetailsState = typeof initialDetailsState
type BookState = {
  isStepCollapsed: typeof stepCollapsedStateDefault
  selectedVenue: Venue
  isConsumerFeatureEnabled: boolean
  isConsumerTagsEnabled: boolean
} & Omit<typeof initialBookState, 'isStepCollapsed' | 'selectedVenue'>
type BookClientState = {
  selectedClient: {
    id: string
    // eslint-disable-next-line camelcase
    client_tags: {
      tagHash: string
      tagName: string
      tagGroup: {
        // eslint-disable-next-line camelcase
        color_hex: string
      }
    }[]
  }
} & Omit<typeof initialClientState, 'selectedClient'>

export interface State {
  bookState: BookState
  bookAvailabilityState: BookAvailabilityState
  bookPaymentState: BookPaymentState
  bookDetailsState: BookDetailsState
  upgradesState: UpgradesState
  bookClientState: BookClientState
}

export const useStoreSelector: TypedUseSelectorHook<State> = useSelector
