import _ from 'lodash'
import { isTimeSlotBookable } from 'mgr/actualslideout/selectors/BookSelectors'
import AvailabilityServices from 'mgr/lib/services/AvailabilityServices'
import EventsServices from 'mgr/lib/services/EventsServices'
import { fetchSeatingAreaTables } from 'mgr/lib/services/SeatingServices'
import { fetchShifts } from 'mgr/lib/services/ShiftServices'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
// eslint-disable-next-line import/no-cycle
import * as SlideoutActions from './SlideoutActions'
import { getPaylinkGratuityType, getChargesFromAvailabilityTimeslot } from '../components/availability/helpers'
import moment from 'moment'
import { TransactionStatus } from 'svr/manager/lib/utils/Constants'
import { mixTimeslot } from 'mgr/actualslideout/components/availability/createARTimeslotHelper'
import { onChangeSelectedVenue as onChangeSelectedVenueDetailsAction } from './BookDetailsActions'
import { onChangeSelectedVenue as onChangeSelectedVenueClientAction } from './BookClientActions'

export const changeSearchResultsPage = searchResultsPage => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_SEARCH_RESULTS_PAGE,
  searchResultsPage,
})
export const changeDate = (date, venue) => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_DATE,
  date,
  venue,
})
export const changePartySize = (partySize, venue) => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_PARTY_SIZE,
  partySize,
  venue,
})
export const changeShift = shift => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_SHIFT,
  shift,
})
export const changeSearchTimeSlot = searchTimeSlot => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_SEARCH_TIME_SLOT,
  searchTimeSlot,
})
export const changeSelectedTimeSlot = (
  venue,
  selectedTimeSlot,
  isPreselectedTime = false,
  isPreviousTime = false,
  lockToAvoidGettingResHoldWhileLoading = false,
  source = null,
  isDirectlySelected = false
) => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT,
  venue,
  selectedTimeSlot,
  isPreselectedTime,
  isPreviousTime,
  accountId: venue.accountId,
  connectedSetupIntents: venue.connectedSetupIntents,
  lockToAvoidGettingResHoldWhileLoading,
  source,
  isDirectlySelected,
})

export const holdReservationStart = () => ({
  type: ActionTypes.BOOK_AVAILABILITY_HOLD_RESERVATION_START,
})

export const holdReservationSuccess = (
  reservationHoldId,
  holdDurationSeconds,
  previousHoldResDate,
  previousHoldDuration,
  previousHoldPartySize,
  previousHoldTime,
  previousHoldVenueId,
  previousHoldAccessPersistentId,
  previousHoldShiftPersistentId
) => ({
  type: ActionTypes.BOOK_AVAILABILITY_HOLD_RESERVATION_SUCCESS,
  reservationHoldId,
  holdDurationSeconds,
  previousHoldResDate,
  previousHoldDuration,
  previousHoldPartySize,
  previousHoldTime,
  previousHoldVenueId,
  previousHoldAccessPersistentId,
  previousHoldShiftPersistentId,
})
export const holdReservationFailure = (errorMessageForHoldReservationFailure, logLevel) => ({
  type: ActionTypes.BOOK_AVAILABILITY_HOLD_RESERVATION_FAILURE,
  errorMessageForHoldReservationFailure,
  logLevel,
})
export const releaseReservationHoldSuccess = () => ({
  type: ActionTypes.BOOK_AVAILABILITY_RELEASE_RESERVATION_HOLD_SUCCESS,
})
export const changeExternalAvailabilityTimeSlot = (
  venue,
  selectedTimeSlot,
  isPreselectedTime = false,
  isPreviousTime = false,
  lockToAvoidGettingResHoldWhileLoading = true,
  isDirectlySelected = false
) => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_EXTERNAL_AVAILABILITY_TIME_SLOT,
  venue,
  selectedTimeSlot,
  isPreselectedTime,
  isPreviousTime,
  lockToAvoidGettingResHoldWhileLoading,
  isDirectlySelected,
})
export const changeChargesFromExternalTimeSlot = (dispatch, getState, venue, timeSlot) => {
  const state = getState()
  const { duration, partySize } = state.bookAvailabilityState
  const { transactions } = state.viewResState
  dispatch({
    type: ActionTypes.BOOK_AVAILABILITY_CHANGE_CHARGES_FROM_EXTERNAL_TIME_SLOT,
    venue,
    chargeData: getChargesFromAvailabilityTimeslot(timeSlot, venue),
    accessTimeSlot: timeSlot,
    partySize,
    duration,
    previouslyChargedAmount: getPreviouslyChargedAmount(transactions),
    accountId: venue.accountId,
    connectedSetupIntents: venue.connectedSetupIntents,
  })
}
export const changeDuration = (duration, venue) => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_DURATION,
  duration,
  venue,
})
export const changeSeatingArea = seatingArea => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_SEATING_AREA,
  seatingArea,
})
export const changeShowAccessRules = showAccessRules => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_SHOW_ACCESS_RULES,
  showAccessRules,
})
export const changeAudience = audienceId => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_AUDIENCE,
  audienceId,
})
export const changeType = typeFilter => ({
  type: ActionTypes.BOOK_AVAILABILITY_CHANGE_TYPE,
  typeFilter,
})

export const setExperiences = experiences => ({
  type: ActionTypes.BOOK_AVAILABILITY_SET_EXPERIENCES,
  experiences,
})
export const updateDuration = (selectedAccessRuleTimeslot, accessRuleDurationsEnabled) => ({
  type: ActionTypes.BOOK_AVAILABILITY_UPDATE_DURATION,
  selectedAccessRuleTimeslot,
  accessRuleDurationsEnabled,
})
export const timesDataReady = (slideoutState, appState, bookAvailabilityState) => ({
  type: ActionTypes.BOOK_AVAILABILITY_TIMES_DATA_READY,
  slideoutState,
  appState,
  bookAvailabilityState,
})

export const copyViewActualsToBookDetails = (actual, venue) => ({
  type: ActionTypes.COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS,
  actual,
  venue,
  accountId: venue.accountId,
  connectedSetupIntents: venue.connectedSetupIntents,
})
export const enableLockToAvoidGettingANewResHold = _params => ({
  type: ActionTypes.ENABLE_LOCK_TO_AVOID_GETTING_A_NEW_RES_HOLD,
})

const isActiveSingleVenueId = (store, venueId) => {
  const { searchVenues } = store.bookAvailabilityState
  if (searchVenues.length !== 1) {
    return false
  }
  const activeVenue = searchVenues[0]
  return activeVenue.id === venueId || activeVenue.urlKey === venueId
}

/* workflow to load date-specific data */
export const onChangeVenuesOrDate = (dispatch, getState, updatedVenueIds, updatedDate) => {
  const store = getState()
  const venueIds = updatedVenueIds || store.bookAvailabilityState.searchVenues.map(v => v.id)
  const date = updatedDate || store.bookAvailabilityState.date

  if (venueIds.length === 1) {
    // Single-venue, load shifts first
    const venueId = venueIds[0]
    dispatch(tryGetShiftsAction(venueId, date)).then(() => onChangeShift(dispatch, getState, updatedDate))
  } else {
    // Multi-venue, jump straight into time search
    reloadAvailableTimes(dispatch, getState, {
      isIncrementalVenues: !_.isNil(updatedVenueIds),
    })
  }

  const dailyEventsBundle = venueIds.filter(vid => _.isEmpty((store.bookAvailabilityState.dailyEventsByVenueDate[vid] || {})[date]))
  if (!_.isEmpty(dailyEventsBundle)) {
    dispatch(tryGetDailyEvents(dailyEventsBundle, date))
  }

  const selectedVenueId = store.bookState?.selectedVenue?.id
  if (selectedVenueId) {
    onChangeSelectedVenueDetailsAction(dispatch, getState, selectedVenueId)
    onChangeSelectedVenueClientAction(dispatch, getState, selectedVenueId)
  }
}

const getTimesRequestHash = store => {
  const {
    date,
    partySize,
    shiftPersistentId,
    searchTimeSlot,
    duration,
    usingDefaultDuration,
    seatingAreaId,
    isMultiVenueSeatingAreaSearchAny,
  } = store.bookAvailabilityState
  return [
    date.format(),
    partySize,
    shiftPersistentId,
    searchTimeSlot,
    usingDefaultDuration ? null : duration,
    seatingAreaId,
    isMultiVenueSeatingAreaSearchAny,
  ].join('##')
}

/* workflow to initialize a shift change */
export const onChangeShift = (dispatch, getState, updateDate) => {
  const store = getState()
  const date = updateDate || store.bookAvailabilityState.date
  return reloadAvailableTimes(dispatch, getState, {
    doReloadSeatingAreaTables: true,
    date,
  })
}

export const onChangePartySizeTimeDurationSeatingArea = (dispatch, getState) => reloadAvailableTimes(dispatch, getState)

export const onChangeSearchResultsPage = (dispatch, getState) => reloadAvailableTimes(dispatch, getState, { isIncrementalVenues: true })

/* workflow to initialize a times search change */
export const reloadAvailableTimes = (dispatch, getState, args = {}) => {
  const store = getState()
  const { actualId } = store.slideoutState
  const {
    bufferMins,
    searchVenues,
    searchResultsPage,
    resultsPerPage,
    partySize,
    preselectedSortOrder,
    preselectedTableId,
    previousSelectedTimeSlot,
  } = store.bookAvailabilityState
  const date = args.date || store.bookAvailabilityState.date
  const requestHash = getTimesRequestHash(store)
  const promises = []
  let shiftPersistentId
  let seatingAreaId
  let duration
  let usingDefaultDuration
  let searchTimeSlot
  const isSingleVenueSearch = searchVenues.length === 1
  if (isSingleVenueSearch) {
    // Single-venue searches operate on shift
    ;({ shiftPersistentId, duration, usingDefaultDuration, seatingAreaId } = store.bookAvailabilityState)
    const venue = searchVenues[0]
    if (args.doReloadSeatingAreaTables === true) {
      promises.push(dispatch(tryGetSeatingAreaTablesAction(venue.id, date, shiftPersistentId)))
    }
  } else {
    // Multi-venue search operate on time
    ;({ searchTimeSlot } = store.bookAvailabilityState)
    const { isMultiVenueSeatingAreaSearchAny } = store.bookAvailabilityState
    seatingAreaId = isMultiVenueSeatingAreaSearchAny ? null : '_default_'
  }
  let venuesToReload = _.slice(searchVenues, searchResultsPage * resultsPerPage, (1 + searchResultsPage) * resultsPerPage)
  if (args.isIncrementalVenues === true || args.isPagination === true) {
    venuesToReload = venuesToReload.filter(v => {
      const loadedAvailability = store.bookAvailabilityState.availabilityByVenue[v.id]
      return _.isNil(loadedAvailability) || loadedAvailability.isMultiVenueSearch === isSingleVenueSearch
    })
  }
  if (!_.isEmpty(venuesToReload)) {
    promises.push(
      dispatch(
        tryGetTimesAction(
          requestHash,
          actualId,
          venuesToReload,
          date,
          partySize,
          shiftPersistentId,
          usingDefaultDuration ? null : duration,
          seatingAreaId,
          searchTimeSlot,
          preselectedSortOrder,
          preselectedTableId,
          previousSelectedTimeSlot,
          bufferMins
        )
      )
    )
  }

  return Promise.all(promises).then(() => {
    const latestStore = getState()
    return dispatch(timesDataReady(latestStore.slideoutState, latestStore.appState, latestStore.bookAvailabilityState))
  })
}

/* SHIFTS */

const getShiftsBegin = () => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_SHIFTS_START,
})
const getShiftsSuccess = (venue, shifts) => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_SHIFTS_SUCCESS,
  venue,
  shifts,
})
const getShiftsFail = () => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_SHIFTS_FAIL,
})

const tryGetShiftsAction = (venueId, date) => (dispatch, getState) => {
  dispatch(getShiftsBegin())
  return fetchShifts(venueId, date).then(
    shifts => {
      const latestStore = getState()
      if (!isActiveSingleVenueId(latestStore, venueId)) {
        window.svrDebug('Ignoring slow request response for getShifts')
        return undefined
      }
      const venue = _.find(latestStore.appState.userDomain.venues, {
        id: venueId,
      })
      return dispatch(getShiftsSuccess(venue, shifts))
    },
    error => dispatch(batchActions([getShiftsFail(), SlideoutActions.showNotificationError(`Error getting shifts: ${error}`)]))
  )
}

/* DAILY EVENTS & NOTES */

const getDailyEventsBegin = _venueId => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_DAILY_EVENTS_START,
})
const getDailyEventsSuccess = (venueId, date, events, note) => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_DAILY_EVENTS_SUCCESS,
  venueId,
  date,
  events,
  note,
})
const getDailyEventsFail = _venueId => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_DAILY_EVENTS_FAIL,
})

const tryGetDailyEvents = (venueIds, date) => (dispatch, _getState) => {
  dispatch(batchActions(venueIds.map(venueId => getDailyEventsBegin(venueId))))
  return Promise.all(
    venueIds.map(venueId => {
      const errHandler = error =>
        dispatch(batchActions([getDailyEventsFail(venueId), SlideoutActions.showNotificationError(`Error getting daily events: ${error}`)]))
      return EventsServices.fetchDailyEventsAndNotes(venueId, date, errHandler).then(({ venueId, date, events, note }) =>
        dispatch(getDailyEventsSuccess(venueId, date, events, note))
      )
    })
  )
}

/* SEATING AREAS AND TABLES */

const gettingSeatingAreaTables = () => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_START,
})
const getSeatingAreaTablesSuccess = seatingAreaTables => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_SUCCESS,
  seatingAreaTables,
})
const getSeatingAreaTablesFail = () => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_FAIL,
})

const tryGetSeatingAreaTablesAction = (venueId, date, shiftPersistentId) => (dispatch, getState) => {
  dispatch(gettingSeatingAreaTables())
  return fetchSeatingAreaTables(venueId, date, shiftPersistentId).then(
    seatingAreaTables => {
      const latestStore = getState()
      if (!isActiveSingleVenueId(latestStore, venueId)) {
        window.svrDebug('Ignoring slow request response for getSeatingAreaTables')
        return undefined
      }
      return dispatch(getSeatingAreaTablesSuccess(seatingAreaTables))
    },
    error =>
      dispatch(
        batchActions([
          getSeatingAreaTablesFail(),
          SlideoutActions.showNotificationError(`Error getting seating areas and tables: ${error}`),
        ])
      )
  )
}

/* workflow to load available timeslots */

const getTimesBegin = venue => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_TIMES_START,
  venue,
})
const getTimesSuccess = (venue, availableTimes, internalCutoff, chargeData, partySize, duration) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.BOOK_AVAILABILITY_GET_TIMES_SUCCESS,
    venue,
    availableTimes,
    internalCutoff,
    chargeData,
    partySize,
    duration,
    previouslyChargedAmount: getPreviouslyChargedAmount(state.viewResState.transactions),
    accountId: state.bookState.selectedVenue.accountId,
    connectedSetupIntents: state.bookState.selectedVenue.connectedSetupIntents,
  })
}

export const getPreviouslyChargedAmount = transactions =>
  transactions.filter(t => t.is_charge && t.status === TransactionStatus.SUCCEEDED).reduce((acc, amt) => acc + amt.base_amount_decimal, 0)

const getTimesFail = venue => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_TIMES_FAIL,
  venue,
})
const getTimesNotReady = venue => ({
  type: ActionTypes.BOOK_AVAILABILITY_GET_TIMES_NOT_READY,
  venue,
})

const getARTimeSelectionIfAvailable = (venue, availableTimes, isEditMode, previousSelectedTimeSlot) => {
  if (!previousSelectedTimeSlot || !availableTimes) {
    return null
  }

  // there is might not be venue_id, because it was just inited by access rule from actual model on COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS
  const isPreselectedTime = isEditMode && !previousSelectedTimeSlot.venue_id
  const internalTimeslot = availableTimes.find(t => t.sort_order === previousSelectedTimeSlot.sort_order)

  if (!internalTimeslot) {
    return null
  }

  const mixedTimeslot = mixTimeslot(previousSelectedTimeSlot, previousSelectedTimeSlot.shift_category, internalTimeslot)
  return changeExternalAvailabilityTimeSlot(venue, mixedTimeslot, isPreselectedTime, !isPreselectedTime)
}

const getTimeSelectionIfAvailable = (venue, availableTimes, sortOrder, tableId, isEditMode, isPreselectedTime, isPreviousTime) => {
  if (!_.isNumber(sortOrder)) {
    return null
  }
  const timeFilter = {
    sort_order: sortOrder,
    ...(isPreviousTime ? { status: 'available' } : {}),
  }
  const selectedTime = _.find(availableTimes, timeFilter)
  if (_.isUndefined(selectedTime)) {
    return null
  }
  const skipPermission = isPreselectedTime && isEditMode
  if (!skipPermission && !isTimeSlotBookable(selectedTime, venue, tableId)) {
    return null
  }
  return changeSelectedTimeSlot(venue, selectedTime, isPreselectedTime, isPreviousTime, false, 'getTimeSelectionIfAvailable')
}

const getAutoTimeSelection = (venue, availableTimes, preselectedSortOrder, preselectedTableId, isEditMode, previousSelectedTimeSlot) =>
  getTimeSelectionIfAvailable(
    venue,
    availableTimes,
    preselectedSortOrder,
    preselectedTableId,
    isEditMode,
    true, // isPreselectedTime
    false // isPreviousTime
  ) ||
  (previousSelectedTimeSlot &&
    previousSelectedTimeSlot.venue_id === venue.id &&
    getTimeSelectionIfAvailable(
      venue,
      availableTimes,
      previousSelectedTimeSlot.sort_order,
      null, // tableId
      isEditMode,
      false, // isPreselectedTime
      true // isPreviousTime
    ))

const SEARCH_HALO = 6 // Number of 15-min slots
const tryGetTimesAction =
  (
    requestHash,
    actualId,
    searchVenues,
    date,
    partySize,
    shiftPersistentId,
    duration,
    seatingAreaId,
    searchTimeSlot,
    preselectedSortOrder,
    preselectedTableId,
    previousSelectedTimeSlot,
    bufferMins
  ) =>
  (dispatch, getState) => {
    if (_.isNaN(partySize)) {
      return dispatch(batchActions(searchVenues.map(venue => getTimesNotReady(venue))))
    }
    dispatch(batchActions(searchVenues.map(venue => getTimesBegin(venue))))
    return Promise.all(
      searchVenues.map(venue => {
        const errHandler = error =>
          dispatch(batchActions([getTimesFail(venue), SlideoutActions.showNotificationError(`Error looking up available times: ${error}`)]))
        const startTimeSlot = _.isNumber(searchTimeSlot) ? Math.max(0, searchTimeSlot - SEARCH_HALO) : null
        const endTimeSlot = _.isNumber(searchTimeSlot) ? Math.min(96, searchTimeSlot + SEARCH_HALO) : null
        const venueSeatingAreaId = seatingAreaId === '_default_' ? venue.bookSettings.defaultSeatingAreaId : seatingAreaId
        return AvailabilityServices.fetchAvailabilityTimes(
          actualId,
          venue.id,
          date,
          partySize,
          shiftPersistentId,
          duration,
          venueSeatingAreaId,
          startTimeSlot,
          endTimeSlot,
          errHandler,
          bufferMins
        ).then(data => {
          const finalStore = getState()
          if (getTimesRequestHash(finalStore) !== requestHash) {
            window.svrDebug(`Ignoring slow request response for getTimes: ${requestHash}`)
            return undefined
          }
          if (data && !data.error) {
            const { taxGroups } = finalStore.bookState.selectedVenue.bookSettings
            const taxGroup = taxGroups.find(obj => obj.id === data.tax_group_id)
            const chargeTax = taxGroup ? taxGroup.tax_rate : 0
            const serviceCharge = data.service_charge ? data.service_charge : ''
            const gratuityCharge = data.gratuity ? data.gratuity : ''
            const chargeData = {
              currencyCode: finalStore.bookState.selectedVenue.currencyCode,
              chargeType: data.cc_charge_type,
              cardRequired: data.require_credit_card,
              paymentRule: data.cc_payment_rule,
              partySizeMinRule: data.cc_party_size_min,
              costMinRule: data.cc_cost,
              requiredGratuity: data.gratuity,
              chargeApplyTax: data.cc_apply_tax_rate,
              taxGroupId: data.tax_group_id,
              chargeTax,
              applyServiceCharge: data.apply_service_charge,
              serviceCharge,
              applyGratuityCharge: data.apply_gratuity_charge,
              gratuityType: data.gratuity_type,
              requiredGratuityCharge: data.require_gratuity_charge,
              gratuityCharge,
              paylinkGratuityType: getPaylinkGratuityType(data),
            }

            // create the charge data for the ar slot and pass it to getTimesSuccess to keep state in order,
            // unless the ar slot is marked to use shift payment policy
            const previousSelectedTimeSlotChargeData =
              previousSelectedTimeSlot?.access_persistent_id && !previousSelectedTimeSlot?.use_shift_payment_and_policy && venue
                ? getChargesFromAvailabilityTimeslot(previousSelectedTimeSlot, venue)
                : null

            const availableTimes = data.availability.map(timeslot => ({
              ...timeslot,
              upsell_categories: finalStore.bookAvailabilityState?.shiftsByPersistentId[shiftPersistentId]?.upsell_categories,
            }))
            const timesSuccessAction = getTimesSuccess(
              venue,
              availableTimes,
              data.internal_cutoff,
              previousSelectedTimeSlotChargeData ?? chargeData,
              partySize,
              duration
            )
            const isEditMode = !_.isEmpty(actualId)
            const autoTimeSelectionAction = !previousSelectedTimeSlot?.access_persistent_id
              ? getAutoTimeSelection(venue, availableTimes, preselectedSortOrder, preselectedTableId, isEditMode, previousSelectedTimeSlot)
              : getARTimeSelectionIfAvailable(venue, availableTimes, isEditMode, previousSelectedTimeSlot)
            const toDispatch = _.isNil(autoTimeSelectionAction)
              ? timesSuccessAction
              : batchActions([timesSuccessAction, autoTimeSelectionAction])
            return dispatch(toDispatch)
          }
          return dispatch(getTimesNotReady(venue))
        })
      })
    )
  }

export const releaseReservationHold = (dispatch, getState, venue) => {
  const state = getState()
  const availability = state.bookAvailabilityState

  const venueId = availability?.selectedTimeSlot?.venue_id || availability?.previousSelectedTimeSlot?.venue_id
  let venueKey = null
  if (venueId) {
    venueKey = Object.values(availability.searchVenues)?.find(e => e.id === venueId)?.urlKey
  }
  if (!venueKey) {
    venueKey = venue?.urlKey
  }
  if (!venueKey) {
    venueKey = Object.values(availability.searchVenues)[0]?.urlKey
  }

  dispatch(holdReservationStart())
  if (!availability.reservationHoldId) {
    dispatch(releaseReservationHoldSuccess())
    return
  }

  const holdHasExpired = availability.expirationMoment && moment().isAfter(availability.expirationMoment)

  if (holdHasExpired) {
    dispatch(releaseReservationHoldSuccess())
    return
  }

  AvailabilityServices.releaseReservationHold({
    venueKey,
    previousHoldShiftPersistentId: availability.previousHoldShiftPersistentId,
    reservationHoldId: availability.reservationHoldId,
    previousHoldResDate: availability.previousHoldResDate,
  })
    .then(_response => {
      dispatch(releaseReservationHoldSuccess())
    })
    .catch(_e => {
      dispatch(releaseReservationHoldSuccess())
    })
}
const getReservationHold = (dispatch, request, selectedTimeSlot, getState) => {
  dispatch(holdReservationStart())
  const state = getState()
  return AvailabilityServices.getReservationHold({ ...request, channel: state.bookAvailabilityState.audienceId })
    .then(response => {
      if (!response.error && !response.errors) {
        const { reservation_hold_id, hold_duration_sec } = response.data
        if (reservation_hold_id && hold_duration_sec) {
          dispatch(
            holdReservationSuccess(
              reservation_hold_id,
              hold_duration_sec,
              request.date,
              request.duration,
              request.partySize,
              request.time,
              request.venueId,
              request.accessPersistentId,
              request.shiftPersistentId
            )
          )
          return
        }
      } else if (selectedTimeSlot?.status === 'available') {
        dispatch(holdReservationFailure('That time slot is no longer available. Would you like to select another time?'))
        reloadAvailableTimes(dispatch, getState)
        return
      }
      dispatch(releaseReservationHoldSuccess())
    })
    .catch(_e => {
      dispatch(releaseReservationHoldSuccess())
    })
}
export const tryGetOrReleaseReservationHold = async (dispatch, getState, venue, params, forceReleaseWhenLeaving) => {
  const state = getState()
  const availability = state.bookAvailabilityState
  const { slideoutState } = state

  if (!availability.availabilityLockInternalBookingEnabled) {
    return
  }

  if (availability.lockToAvoidGettingResHoldWhileLoading) {
    return
  }

  let availableTimes = Object.values(availability.availabilityByVenue || params.availableTimes || {})
  availableTimes = availableTimes.length > 0 ? availableTimes[0] : []
  availableTimes = availableTimes?.availableTimes?.map(t => t.time)

  const partySize = params.partySize || availability.partySize
  const duration = params.duration || availability.duration
  const date = params.date || availability.date?.format('MM-DD-Y')
  const time = params.time || availability.selectedTimeSlot?.time
  // exists when access rules are shown
  let venueId = availability?.selectedTimeSlot?.venue_id
  const accessPersistentId =
    params.accessPersistentId || availability.selectedTimeSlot?.accessPersistentId || availability.selectedTimeSlot?.access_persistent_id

  let shiftPersistentId = availability.selectedTimeSlot?.shift_persistent_id || params.shiftPersistentId
  const shiftCategory = availability.selectedTimeSlot?.shift_category
  // For a timeslot AR the shift_persistent_id is not a valid field
  if (!shiftPersistentId && shiftCategory) {
    shiftPersistentId = availability.shifts?.find(shift => shift.category === shiftCategory)?.persistent_id
  }

  let venueKey = null
  if (venueId) {
    venueKey = Object.values(availability.searchVenues)?.find(e => e.id === venueId)?.urlKey
  }
  if (!venueKey) {
    venueKey = venue?.urlKey
    venueId = venue?.id
  }
  if (!venueKey) {
    const firstVenue = Object.values(availability.searchVenues)[0]
    venueKey = firstVenue?.urlKey
    venueId = firstVenue?.id
  }

  if (!availability.selectedTimeSlot?.time) {
    releaseReservationHold(dispatch, getState, venue)
    return
  }

  const holdHasExpired = availability.expirationMoment && moment().isAfter(availability.expirationMoment)

  if (forceReleaseWhenLeaving && holdHasExpired) {
    // just clean internal state
    releaseReservationHold(dispatch, getState, venue)
    return
  }

  const requiredFieldsCompleted = partySize && duration && date && time && shiftPersistentId

  const aNewTimeslotWasChosen =
    date !== availability.previousHoldResDate ||
    shiftPersistentId !== availability.previousHoldShiftPersistentId ||
    duration !== availability.previousHoldDuration ||
    partySize !== availability.previousHoldPartySize ||
    time !== availability.previousHoldTime ||
    venueId !== availability.previousHoldVenueId ||
    accessPersistentId !== availability.previousHoldAccessPersistentId

  if (!holdHasExpired && !aNewTimeslotWasChosen) {
    if (forceReleaseWhenLeaving) {
      releaseReservationHold(dispatch, getState, venue)
    }
    return
  }

  const allowTryToGetNewHold = !forceReleaseWhenLeaving && ((aNewTimeslotWasChosen && requiredFieldsCompleted) || holdHasExpired)

  let onlyRelease = !allowTryToGetNewHold && forceReleaseWhenLeaving

  if (onlyRelease && !availability.reservationHoldId) {
    // no reservation hold was acquired
    dispatch(releaseReservationHoldSuccess())
    return
  }

  if (allowTryToGetNewHold) {
    if (!availableTimes?.includes(time)) {
      onlyRelease = true // if time is not available in the new selected date, the just release...
    }
  }
  if (!onlyRelease && !allowTryToGetNewHold) {
    dispatch(releaseReservationHoldSuccess())
    return
  }

  if (onlyRelease) {
    releaseReservationHold(dispatch, getState, venue)
    return
  }

  const request = {
    venueId,
    venueKey,
    previousHoldShiftPersistentId: availability.previousHoldShiftPersistentId,
    reservationHoldId: availability.reservationHoldId,
    previousHoldResDate: availability.previousHoldResDate,
    date,
    time,
    shiftPersistentId,
    accessPersistentId,
    partySize,
    duration: availability.usingDefaultDuration ? null : duration,
    actualId: slideoutState.actualId,
  }

  try {
    if (!holdHasExpired && availability.reservationHoldId && availability.previousHoldResDate) {
      dispatch(holdReservationStart())
      try {
        await AvailabilityServices.releaseReservationHold({
          venueKey,
          previousHoldShiftPersistentId: availability.previousHoldShiftPersistentId,
          reservationHoldId: availability.reservationHoldId,
          previousHoldResDate: availability.previousHoldResDate,
        })
          .then(_response => {
            dispatch(releaseReservationHoldSuccess())
          })
          .catch(_e => {
            dispatch(releaseReservationHoldSuccess())
          })
        // eslint-disable-next-line no-empty
      } catch (ex) {}
    }
    if (!slideoutState.isViewMode && allowTryToGetNewHold) {
      await getReservationHold(dispatch, request, availability.selectedTimeSlot, getState)
    }
  } catch (e) {
    dispatch(releaseReservationHoldSuccess())
  }
}

export const toggleBuffer = () => ({
  type: ActionTypes.TOGGLE_BUFFER,
})

export const setExcludeFromShiftPacing = value => ({
  type: ActionTypes.SET_EXCLUDE_FROM_SHIFT_PACING,
  value,
})
