import { type Field, useWatch } from '@sevenrooms/core/form'
import { FormatService } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Flex, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomFormCurrencyInput } from '../customComponents/CustomFormCurrencyInput'

interface AmountProps {
  title: string
  field: Field<number | null>
  oldValue?: number | null
  count?: number
  override: boolean
  currencyCode: string
  currencySymbol: string
}

export function Amount({ title, count, field, oldValue, override, currencySymbol, currencyCode }: AmountProps) {
  const theme = useTheme()
  const value = useWatch(field)

  const editableAmount = override

  return (
    <HStack height={theme.fieldHeight.m} pl="m" pr={editableAmount ? 'none' : 'm'} alignItems="center" borderTopColor="dividerLines">
      {count && (
        <Text type="div" width={theme.spacing.l}>
          {count}x
        </Text>
      )}
      <Flex flex="auto">
        <Text>{title}</Text>
      </Flex>
      {oldValue != null && oldValue !== value && (
        <Flex width="78px" justifyContent="end" pr="s">
          <Text textDecoration="line-through" color="error">
            {FormatService.formatCurrency(oldValue, currencyCode)}
          </Text>
        </Flex>
      )}
      <Flex width="110px" justifyContent="end">
        {editableAmount ? (
          <CustomFormCurrencyInput
            data-test="amount"
            field={field}
            currencySymbol={currencySymbol}
            fieldHeight="s"
            fixedDecimalScale
            hideErrorMessage
          />
        ) : (
          <Text>{value != null && FormatService.formatCurrency(value, currencyCode)}</Text>
        )}
      </Flex>
    </HStack>
  )
}
