import _ from 'lodash'
import * as ActionTypes from 'mgr/actualslideout/actions/ActionTypes'
import { COST_OPTION_NUM_MAP, COST_OPTIONS } from 'mgr/actualslideout/actions/BookActions'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { genericTagToDisplayTag } from 'mgr/lib/components/GenericTagsDropDown'
import { reduceUserDomainVenueAttrs } from 'mgr/lib/utils/AppStateUtils'
import { isMessageSentToClient, isMessageSentToSource } from 'mgr/lib/utils/Constants'
import moment from 'moment-timezone'

export const initialState = {
  // Init Data should not be in initState so that it doesn't get cleared on reset
  // currentUser

  // Res Data
  selectedVenue: null,
  isEditMode: false,
  reservationNotes: '',
  reservationTags: [],
  selectedTableIds: [],
  isAutoAssign: false,
  isCustomAssign: false,
  isConciergeReservation: false,
  overbookDirective: null,
  selectedBookedBy: null,
  additionalBookedBy: [],
  numberOfAdditionalBookedBySlots: 0,
  newPromoterName: '',
  perks: '',
  customBookedByEnabled: false,
  canEditBookedBy: true,
  showReminderToggles: false,
  showFeedbackToggles: false,
  isSourceSet: false,
  isSmsEnabled: false,
  isBookingSMSEnabled: false,
  isSmsDefaultReminderDestination: false,
  isSMSDefaultBookingDestination: false,
  isEmailDefaultBookingDestination: false,
  selectedCostOption: '',
  costOptionAmount: '',
  messagingFields: {
    sendClientEmail: false, // Highest precedence
    sendClientEmailToClient: true,
    sendClientEmailToSource: false,
    personalMessage: '',

    sendClientSMS: false,
    sendClientSMSToClient: true,
    sendClientSMSToSource: false,

    sendReminderEmail: true,
    sendReminderEmailToClient: true,
    sendReminderEmailToSource: false,

    sendFeedbackEmail: true,
    sendFeedbackEmailToClient: true,
    sendFeedbackEmailToSource: false,

    sendReminderSms: false,
    sendReminderSmsToClient: true,
    sendReminderSmsToSource: false,

    sendMarketingOptInEmail: false,
    resSMSOptIn: null,
  },
  selectedVenueSendEmailConfirmationsByDefault: true,
  customFields: {
    custom_group_0: '',
    custom_group_1: '',
    custom_group_2: '',
    custom_group_3: '',
    custom_group_4: '',
    custom_venue_0: '',
    custom_venue_1: '',
    custom_venue_2: '',
    custom_venue_3: '',
    custom_venue_4: '',
    custom_unindexed: {},
  },

  // Venue Data
  bookedByNamesByVenue: Object.create(null),
  reservationTagGroupsByVenue: Object.create(null),

  paylinkAutocancelDatetime: null,
}

const convertBookedByViewToEntry = bbv => ({
  id: bbv.key,
  name: bbv.full_name,
  is_user: bbv.user_type === 'user',
})

const isSelectedTableStillRecommended = (selectedTimeSlot, selectedTableIds) => {
  if (!_.isObject(selectedTimeSlot) || _.isNil(selectedTableIds) || selectedTableIds.length !== 1) {
    return false
  }
  const selectedTableId = selectedTableIds[0].value
  for (const table of selectedTimeSlot.recommended) {
    const tableId = table.id
    if (selectedTableId === tableId) {
      return true
    }
  }
  return false
}

const isPaylinkOutstanding = (state, paylinkAutocancelDatetime) =>
  paylinkAutocancelDatetime && moment.utc().isBefore(moment.utc(paylinkAutocancelDatetime))

const buildHotelClientReservationNotes = (hotelClient, hotelName) =>
  [
    hotelClient.confirmation_num && `Conf #${hotelClient.confirmation_num}`,
    hotelName,
    hotelClient.room_num && `Room ${hotelClient.room_num}`,
    hotelClient.profile_notes,
    hotelClient.check_in_date_display && `Check in ${hotelClient.check_in_date_display}`,
    hotelClient.check_out_date_display && `Check out ${hotelClient.check_out_date_display}`,
  ]
    .filter(Boolean)
    .join(', ')

const updatedTableFieldsAfterTimeSelection = (state, selectedVenue, selectedTimeSlot, isPreselectedTime) => {
  let { isAutoAssign, isCustomAssign, selectedTableIds } = state
  const isVenueChange = Boolean(!state.selectedVenue || state.selectedVenue.id !== selectedVenue.id)
  if (isVenueChange || (!isPreselectedTime && !isCustomAssign && !isSelectedTableStillRecommended(selectedTimeSlot, selectedTableIds))) {
    // Reset table picker, and use default auto assign
    // However, if user picked to overbook a time slot that only has smaller/larger tables available, pick the first table in the list
    isAutoAssign = selectedVenue.isAutoAssignEnabled
    isCustomAssign = false
    selectedTableIds = []
    if (isAutoAssign && selectedTimeSlot.override_unassigned) {
      // Hard unassign
      isAutoAssign = false
    } else if (isAutoAssign && selectedTimeSlot.override_default_table_id) {
      // Find best table to lock in by default
      isAutoAssign = false
      selectedTableIds.push({
        value: selectedTimeSlot.override_default_table_id,
      })
    }
  }
  return { isAutoAssign, isCustomAssign, selectedTableIds }
}

const buildOverbookDirective = selectedTimeSlot => {
  if (selectedTimeSlot.status === 'available') {
    return null
  } else if (!_.isEmpty(selectedTimeSlot.sub_status)) {
    return `${selectedTimeSlot.status}|${selectedTimeSlot.sub_status}`
  }
  return selectedTimeSlot.status
}

function getInitialAdditionalBookedBy(integrationUsersBookedByNamesByVenue) {
  const urlParams = Pmp.Utils.getParamsFromUrl()
  const fromGlobalInbox = Object.hasOwn(urlParams, 'fromGlobalInbox') && urlParams.fromGlobalInbox === 'true'
  const isConsumerFeatureEnabled = window.globalInit.venueSettings.is_consumer_feature_enabled

  if (fromGlobalInbox && isConsumerFeatureEnabled) {
    return [
      {
        id: 'Member Chat',
        name: 'Member Chat',
        is_user: false,
      },
    ]
  }
  return []
}

const bookDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GlobalActionTypes.INITIALIZE: {
      const { user } = action.globalInit
      return {
        ...state,
        currentUser: {
          id: user.id,
          name: user.full_name,
          is_user: true,
        },
        venueTimezone: action.globalInit.venueTimezone,
      }
    }
    case GlobalActionTypes.ON_USER_DOMAIN_VENUES_LOAD: {
      const initialUserDomainVenue = _.find(Pmp.Manager.Global.UserDomainVenues.venues, { id: window.globalInit.venueId })
      return {
        ...state,
        initVenue: reduceUserDomainVenueAttrs(initialUserDomainVenue),
      }
    }
    case ActionTypes.ENTER_ADD_RESERVATION:
      return {
        ...state,
        ...initialState,
        selectedVenue: state.initVenue,
        selectedTableIds: [{ value: action.tableId }],
        selectedBookedBy: state.currentUser,
        additionalBookedBy: getInitialAdditionalBookedBy(state.integrationUsersBookedByNamesByVenue),
        numberOfAdditionalBookedBySlots: 0,
        reservationNotes: action.reservationNotes || '',
      }
    case ActionTypes.RESET_BOOKED_BY_NAMES: {
      return {
        ...state,
        selectedBookedBy: state.currentUser,
        isConciergeReservation: false,
        additionalBookedBy: getInitialAdditionalBookedBy(state.integrationUsersBookedByNamesByVenue),
        numberOfAdditionalBookedBySlots: 0,
        canEditBookedBy: true,
      }
    }
    case ActionTypes.COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS: {
      // View/edit
      const { actual, venue } = action
      const isConciergeReservation = actual.is_concierge_reservation
      const selectedBookedBy = convertBookedByViewToEntry(actual.bookedby_view)
      const additionalBookedBy = _.isEmpty(actual.bookedby_alt_view) ? [] : actual.bookedby_alt_view.map(convertBookedByViewToEntry)
      const canEditBookedBy = !actual.is_concierge_reservation
      const reservationNotes = actual.private_notes
      const reservationTags = (actual.tags_display || []).map(genericTagToDisplayTag)
      const isSourceSet = !_.isNil(actual.source_client)
      const perks = actual.perks_freeform
      const isEditMode = true
      let selectedCostOption = COST_OPTION_NUM_MAP[actual.cost_option]

      if (actual.is_comp) {
        selectedCostOption = COST_OPTIONS.COMPED
      } else if (actual.is_nomin) {
        selectedCostOption = COST_OPTIONS.NO_MINIMUM
      }

      const costOptionAmount =
        action.selectedCostOption === COST_OPTIONS.NO_MINIMUM ||
        action.selectedCostOption === COST_OPTIONS.COMPED ||
        _.isNil(actual.min_price)
          ? ''
          : actual.min_price
      const clientEmailDestExists = !_.isNil(actual.send_client_email_destination)
      const clientSMSDestExists = !_.isNil(actual.send_client_sms_destination)
      const reminderEmailDestExists = !_.isNil(actual.send_reminder_email_destination)
      const feedbackEmailDestExists = !_.isNil(actual.send_feedback_email_destination)
      const sourceClientExists = !_.isNil(actual.source_client_id)
      const paylinkAutocancelDatetime = actual.paylink_auto_cancel_datetime
      const messagingFields = {
        ...initialState.messagingFields,
        personalMessage: actual.public_notes,
        sendReminderEmail: actual.send_reminder_email,
        sendFeedbackEmail: actual.send_feedback_email,
        sendReminderSms: actual.send_reminder_sms,
        resSMSOptIn: actual.reservation_sms_opt_in,

        sendClientEmail: isPaylinkOutstanding(state, paylinkAutocancelDatetime),
        sendClientEmailToClient: clientEmailDestExists ? isMessageSentToClient(actual.send_client_email_destination) : !sourceClientExists,
        sendClientEmailToSource: clientEmailDestExists ? isMessageSentToSource(actual.send_client_email_destination) : sourceClientExists,

        sendClientSMS: actual.send_client_sms,
        sendClientSMSToClient: clientSMSDestExists ? isMessageSentToClient(actual.send_client_sms_destination) : !sourceClientExists,
        sendClientSMSToSource: clientSMSDestExists ? isMessageSentToSource(actual.send_client_sms_destination) : sourceClientExists,

        sendReminderEmailToClient: reminderEmailDestExists
          ? isMessageSentToClient(actual.send_reminder_email_destination)
          : !sourceClientExists,
        sendReminderEmailToSource: reminderEmailDestExists
          ? isMessageSentToSource(actual.send_reminder_email_destination)
          : sourceClientExists,

        sendFeedbackEmailToClient: feedbackEmailDestExists
          ? isMessageSentToClient(actual.send_feedback_email_destination)
          : !sourceClientExists,
        sendFeedbackEmailToSource: feedbackEmailDestExists
          ? isMessageSentToSource(actual.send_feedback_email_destination)
          : sourceClientExists,
      }
      const customFields = _.mapValues(initialState.customFields, (v, k) => actual[k] || initialState.customFields[k])

      return {
        ...state,
        ...initialState,
        isEditMode,
        selectedVenue: venue,
        selectedBookedBy,
        canEditBookedBy,
        reservationNotes,
        reservationTags,
        messagingFields,
        isSourceSet,
        isConciergeReservation,
        additionalBookedBy,
        perks,
        costOptionAmount,
        selectedCostOption,
        customFields,
        paylinkAutocancelDatetime,
      }
    }
    case ActionTypes.ENTER_EDIT_RESERVATION: {
      // Send these in enterEditReservation step, since they may have changed in table dropdown on view slideout before going into edit mode
      const { selectedTableIds, isAutoAssign, isCustomAssign } = action
      return { ...state, selectedTableIds, isAutoAssign, isCustomAssign }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT: {
      const { selectedTimeSlot, isPreselectedTime } = action
      const hasSelectedTimeSlot = _.isObject(selectedTimeSlot)
      const selectedVenue = hasSelectedTimeSlot ? action.venue : state.selectedVenue
      const selectedVenueSendEmailConfirmationsByDefault = selectedVenue.sendEmailConfirmationsByDefault
      const isSmsEnabled = selectedVenue.remindersSmsEnabled
      const isSmsDefaultReminderDestination = isSmsEnabled && ['SMS', 'EMAIL_AND_SMS'].includes(selectedVenue.defaultReminderMethod)
      const isEmailDefaultReminderDestination = ['EMAIL', 'EMAIL_AND_SMS'].includes(selectedVenue.defaultReminderMethod)
      const category = hasSelectedTimeSlot && selectedTimeSlot.shift_category
      const { shiftReminderTimes, shiftFeedbackTimes } = selectedVenue
      const showReminderToggles = !_.isEmpty(shiftReminderTimes) && !!shiftReminderTimes[category]
      const showFeedbackToggles = !_.isEmpty(shiftFeedbackTimes) && !!shiftFeedbackTimes[category]
      const isBookingSMSEnabled = selectedVenue.smsBookingNotificationEnabled
      const isSMSDefaultBookingDestination = isBookingSMSEnabled && ['SMS', 'EMAIL_AND_SMS'].includes(selectedVenue.defaultDeliveryMethod)
      const isEmailDefaultBookingDestination = ['EMAIL', 'EMAIL_AND_SMS'].includes(selectedVenue.defaultDeliveryMethod)

      const { isEditMode } = state
      const { isAutoAssign, isCustomAssign, selectedTableIds } = updatedTableFieldsAfterTimeSelection(
        state,
        selectedVenue,
        selectedTimeSlot,
        isPreselectedTime
      )
      const overbookDirective = buildOverbookDirective(selectedTimeSlot)
      const smsOptedOut = state.messagingFields && state.messagingFields.resSMSOptIn === false
      const messagingFields = {
        ...state.messagingFields,
        sendClientEmail:
          isPaylinkOutstanding(state, state.paylinkAutocancelDatetime) ||
          (!(isPreselectedTime && isEditMode) &&
            selectedVenueSendEmailConfirmationsByDefault &&
            (isEmailDefaultBookingDestination || smsOptedOut)),
        sendClientSMS:
          !(isPreselectedTime && isEditMode) &&
          selectedVenueSendEmailConfirmationsByDefault &&
          isSMSDefaultBookingDestination &&
          !smsOptedOut,
        ...(isEditMode
          ? {}
          : {
              sendReminderEmail: isEmailDefaultReminderDestination,
              sendReminderSms: isSmsDefaultReminderDestination,
              sendFeedbackEmail: showFeedbackToggles,
            }),
      }

      const { costOptions } = selectedVenue
      let { selectedCostOption } = state
      let { costOptionAmount } = state

      const doesSelectedCostOptionExist = costOptions.indexOf(state.selectedCostOption) > -1
      if (!doesSelectedCostOptionExist) {
        selectedCostOption = !_.isEmpty(costOptions) ? costOptions[0] : ''
        costOptionAmount = ''
      }

      const validEntityKeys = [selectedVenue.id, selectedVenue.venueGroupId]
      const reservationTags = state.reservationTags.filter(t => _.includes(validEntityKeys, t.tagGroup.entity_key))
      return {
        ...state,
        selectedVenue,
        selectedTableIds,
        isAutoAssign,
        isCustomAssign,
        overbookDirective,
        messagingFields,
        showReminderToggles,
        showFeedbackToggles,
        reservationTags,
        selectedVenueSendEmailConfirmationsByDefault,
        isBookingSMSEnabled,
        isSMSDefaultBookingDestination,
        isEmailDefaultBookingDestination,
        isSmsEnabled,
        isSmsDefaultReminderDestination,
        isEmailDefaultReminderDestination,
        selectedCostOption,
        costOptionAmount,
      }
    }

    case ActionTypes.BOOK_CLIENT_DESELECT_CLIENT: {
      const { previouslySelectedClient } = action
      let { reservationTags, reservationNotes } = state
      let newReservationTags = []
      const hadSelectedClient = _.isObject(previouslySelectedClient)
      const hadSelectedHotelClient = hadSelectedClient && _.isObject(previouslySelectedClient.hotel_client)
      if (hadSelectedHotelClient) {
        newReservationTags = reservationTags.filter(tag => !previouslySelectedClient.hotel_client.reservation_tags?.includes(tag.tagHash))
        const hotelName =
          previouslySelectedClient.hotel_client.hotel_name ||
          state.initVenue.clientLookupHotelList.find(i => i.id === previouslySelectedClient.hotel_client.selectedHotelId)?.name
        // Search and remove everything that is from the 'Hotel data' to ';' . so it may pickup profile notes.
        const regex = new RegExp(`${buildHotelClientReservationNotes(previouslySelectedClient.hotel_client, hotelName)}.*?; `)
        reservationNotes = reservationNotes.replace(regex, '')
      }
      reservationTags = newReservationTags
      return { ...state, reservationTags, reservationNotes }
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_SELECTED_CLIENT: {
      const { selectedClient } = action
      const hotelClient = selectedClient?.hotel_client
      let { reservationNotes } = state
      const hasSelectedClient = _.isObject(selectedClient)
      const hasSelectedHotelClient = hasSelectedClient && _.isObject(selectedClient.hotel_client)

      if (hasSelectedHotelClient) {
        const resNotesPrepend = buildHotelClientReservationNotes(hotelClient, hotelClient.hotel_name)
        reservationNotes = resNotesPrepend ? `${resNotesPrepend}; ${reservationNotes || ''}` : reservationNotes || ''
      }
      return { ...state, reservationNotes }
    }
    case ActionTypes.BOOK_DETAILS_GET_BOOKED_BY_NAMES_SUCCESS: {
      const { venueId, bookedByNames } = action
      const newState = { ...state }
      newState.bookedByNamesByVenue = { ...state.bookedByNamesByVenue }
      newState.bookedByNamesByVenue[venueId] = bookedByNames
      return newState
    }
    case ActionTypes.BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_SUCCESS: {
      const { venueId, tagGroups } = action
      const reservationTagGroupsByVenue = {
        ...state.reservationTagGroupsByVenue,
        [venueId]: tagGroups,
      }
      return { ...state, reservationTagGroupsByVenue }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_PERKS_FIELD: {
      return { ...state, perks: action.value }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_RESERVATION_NOTES:
      return { ...state, reservationNotes: action.value }
    case ActionTypes.BOOK_DETAILS_CHANGE_RESERVATION_TAGS:
      return { ...state, reservationTags: action.value }
    case ActionTypes.BOOK_DETAILS_CHANGE_SELECTED_TABLES: {
      const { isCustomAssign } = state
      const selectedTableIds = action.value
      const isAutoAssign = action.value === 'auto' && !isCustomAssign
      return { ...state, selectedTableIds, isAutoAssign }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_IS_CUSTOM_ASSIGN: {
      const { isCustomAssign } = action
      return { ...state, isCustomAssign }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_SELECTED_BOOKED_BY:
      return state.canEditBookedBy
        ? {
            ...state,
            selectedBookedBy: action.value,
            customBookedByEnabled: false,
            newPromoterName: '',
          }
        : state
    case ActionTypes.BOOK_DETAILS_ADD_ADDITIONAL_BOOKED_BY_SLOT: {
      return state.canEditBookedBy
        ? {
            ...state,
            numberOfAdditionalBookedBySlots: state.numberOfAdditionalBookedBySlots + 1,
          }
        : state
    }
    case ActionTypes.BOOK_DETAILS_ADD_ADDITIONAL_BOOKED_BY: {
      const newAdditionBookedBySlotsNumber = state.numberOfAdditionalBookedBySlots - 1
      const numberOfAdditionalBookedBySlots = newAdditionBookedBySlotsNumber > 0 ? newAdditionBookedBySlotsNumber : 0
      const withAddedBbNameAdditionalBookedBy = _.concat(state.additionalBookedBy, action.chosenBookedByName)
      const additionalBookedBy = _.uniq(withAddedBbNameAdditionalBookedBy, 'id')
      return state.canEditBookedBy
        ? {
            ...state,
            numberOfAdditionalBookedBySlots,
            additionalBookedBy: state.canEditBookedBy ? additionalBookedBy : state.additionalBookedBy,
          }
        : state
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_ADDITIONAL_BOOKED_BY: {
      const { chosenBookedByName, idx } = action
      const clonedAdditionalBookedBy = _.concat(state.additionalBookedBy)
      clonedAdditionalBookedBy[idx] = chosenBookedByName
      const additionalBookedBy = _.uniqBy(clonedAdditionalBookedBy, 'id')
      return state.canEditBookedBy ? { ...state, additionalBookedBy } : state
    }
    case ActionTypes.BOOK_DETAILS_REMOVE_ADDITIONAL_BOOKED_BY: {
      const additionalBookedBy = state.additionalBookedBy.filter(bookedByItem => bookedByItem.id !== action.chosenBookedByNameId)

      return { ...state, additionalBookedBy }
    }
    case ActionTypes.BOOK_DETAILS_REMOVE_UNFILLED_ADDITIONAL_BOOKED_BY: {
      return {
        ...state,
        numberOfAdditionalBookedBySlots: state.numberOfAdditionalBookedBySlots - 1,
      }
    }
    case ActionTypes.BOOK_DETAILS_ENABLE_CUSTOM_BOOKED_BY_NAME: {
      return { ...state, customBookedByEnabled: true }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_CUSTOM_BOOKED_BY_NAME: {
      return { ...state, newPromoterName: action.customBookedByName }
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_CLIENT_FIELD: {
      const { selectedVenueSendEmailConfirmationsByDefault, isSMSDefaultBookingDestination, isEmailDefaultBookingDestination } = state
      if (_.includes(['first_name', 'last_name'], action.field)) {
        const smsOptedOut = state.messagingFields && !state.messagingFields.resSMSOptIn
        const messagingFields = {
          ...state.messagingFields,
          sendClientEmail:
            state.messagingFields.sendClientEmail ||
            (selectedVenueSendEmailConfirmationsByDefault && (isEmailDefaultBookingDestination || smsOptedOut)),
          sendClientEmailToClient: true,

          sendClientSMS:
            state.messagingFields.sendClientSMS ||
            (selectedVenueSendEmailConfirmationsByDefault && isSMSDefaultBookingDestination && !smsOptedOut),
          sendClientSMSToClient: true,
        }
        return { ...state, messagingFields }
      }
      return state
    }

    case ActionTypes.BOOK_SOURCE_CHANGE_SELECTED_SOURCE: {
      const {
        selectedVenueSendEmailConfirmationsByDefault,
        isSmsDefaultReminderDestination,
        isEmailDefaultReminderDestination,
        isSMSDefaultBookingDestination,
        isEmailDefaultBookingDestination,
      } = state
      const { selectedSource } = action
      const isSourceSet = !_.isNil(selectedSource)
      const smsOptedOut = state.messagingFields && !state.messagingFields.resSMSOptIn
      if (isSourceSet) {
        const messagingFields = {
          ...state.messagingFields,
          sendClientEmail:
            state.messagingFields.sendClientEmail ||
            (selectedVenueSendEmailConfirmationsByDefault && (isEmailDefaultBookingDestination || smsOptedOut)),
          sendClientEmailToClient: false,
          sendClientEmailToSource: true,

          sendClientSMS:
            state.messagingFields.sendClientSMS ||
            (selectedVenueSendEmailConfirmationsByDefault && isSMSDefaultBookingDestination && !smsOptedOut),
          sendClientSMSToClient: false,
          sendClientSMSToSource: true,

          sendReminderEmailToClient: false,
          sendReminderEmailToSource: isEmailDefaultReminderDestination,

          sendReminderSmsToClient: false,
          sendReminderSmsToSource: isSmsDefaultReminderDestination,

          sendFeedbackEmailToClient: true,
          sendFeedbackEmailToSource: false,
        }
        return { ...state, messagingFields, isSourceSet }
      }
      const messagingFields = {
        ...state.messagingFields,
        sendClientEmail:
          state.messagingFields.sendClientEmail ||
          (selectedVenueSendEmailConfirmationsByDefault && (isEmailDefaultBookingDestination || smsOptedOut)),
        sendClientEmailToClient: true,
        sendClientEmailToSource: false,

        sendClientSMS:
          state.messagingFields.sendClientSMS ||
          (selectedVenueSendEmailConfirmationsByDefault && isSMSDefaultBookingDestination && !smsOptedOut),
        sendClientSMSToClient: true,
        sendClientSMSToSource: false,

        sendReminderEmailToClient: isEmailDefaultReminderDestination,
        sendReminderEmailToSource: false,

        sendReminderSmsToClient: isSmsDefaultReminderDestination,
        sendReminderSmsToSource: false,

        sendFeedbackEmailToClient: false,
        sendFeedbackEmailToSource: true,
      }
      return { ...state, messagingFields, isSourceSet }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_MESSAGING_FIELD: {
      const messagingFields = { ...state.messagingFields }
      messagingFields[action.field] = action.value
      const { isSmsDefaultReminderDestination, isEmailDefaultReminderDestination } = state
      // Messaging field changes that cause other message field changes
      // TODO: Handle this in a less messy way
      switch (action.field) {
        case 'sendClientEmailToClient':
        case 'sendClientEmailToSource': {
          messagingFields.sendClientEmail = messagingFields.sendClientEmailToClient || messagingFields.sendClientEmailToSource
          break
        }
        case 'sendClientSMSToClient':
        case 'sendClientSMSToSource': {
          messagingFields.sendClientSMS = messagingFields.sendClientSMSToClient || messagingFields.sendClientSMSToSource
          break
        }
        case 'sendReminderEmailToClient':
        case 'sendReminderEmailToSource': {
          messagingFields.sendReminderEmail = messagingFields.sendReminderEmailToClient || messagingFields.sendReminderEmailToSource
          break
        }

        case 'sendReminderSmsToClient':
        case 'sendReminderSmsToSource': {
          messagingFields.sendReminderSms = messagingFields.sendReminderSmsToClient || messagingFields.sendReminderSmsToSource
          break
        }
        case 'sendFeedbackEmailToClient':
        case 'sendFeedbackEmailToSource': {
          messagingFields.sendFeedbackEmail = messagingFields.sendFeedbackEmailToClient || messagingFields.sendFeedbackEmailToSource
          break
        }
        case 'sendClientEmail': {
          if (messagingFields.sendClientEmail && !messagingFields.sendClientEmailToClient && !messagingFields.sendClientEmailToSource) {
            if (state.isSourceSet) {
              messagingFields.sendClientEmailToSource = true
              messagingFields.sendClientEmailToClient = false
            } else {
              messagingFields.sendClientEmailToClient = true
              messagingFields.sendClientEmailToSource = false
            }
          }
          break
        }
        case 'sendClientSMS': {
          if (messagingFields.sendClientSMS && !messagingFields.sendClientSMSToClient && !messagingFields.sendClientSMSToSource) {
            if (state.isSourceSet) {
              messagingFields.sendClientSMSToSource = true
              messagingFields.sendClientSMSToClient = false
            } else {
              messagingFields.sendClientSMSToClient = true
              messagingFields.sendClientSMSToSource = false
            }
          }
          break
        }

        case 'sendReminderEmail': {
          if (
            messagingFields.sendReminderEmail &&
            !messagingFields.sendReminderEmailToClient &&
            !messagingFields.sendReminderEmailToSource
          ) {
            if (state.isSourceSet) {
              messagingFields.sendReminderEmailToSource = isEmailDefaultReminderDestination
              messagingFields.sendReminderEmailToClient = false
            } else {
              messagingFields.sendReminderEmailToClient = isEmailDefaultReminderDestination
              messagingFields.sendReminderEmailToSource = false
            }
          }
          break
        }

        case 'sendReminderSms': {
          if (messagingFields.sendReminderSms && !messagingFields.sendReminderEmailToClient && !messagingFields.sendReminderSmsToSource) {
            if (state.isSourceSet) {
              messagingFields.sendReminderSmsToSource = isSmsDefaultReminderDestination
              messagingFields.sendReminderSmsToClient = false
            } else {
              messagingFields.sendReminderSmsToClient = isSmsDefaultReminderDestination
              messagingFields.sendReminderSmsToSource = false
            }
          }
          break
        }

        case 'sendFeedbackEmail': {
          if (
            messagingFields.sendFeedbackEmail &&
            !messagingFields.sendFeedbackEmailToClient &&
            !messagingFields.sendFeedbackEmailToSource
          ) {
            if (state.isSourceSet) {
              messagingFields.sendFeedbackEmailToSource = false
              messagingFields.sendFeedbackEmailToClient = true
            } else {
              messagingFields.sendFeedbackEmailToClient = false
              messagingFields.sendFeedbackEmailToSource = true
            }
          }
          break
        }
        default:
      }
      return { ...state, messagingFields }
    }

    case ActionTypes.BOOK_DETAILS_SET_SELECTED_COST_OPTION: {
      const amount =
        action.selectedCostOption === COST_OPTIONS.NO_MINIMUM || action.selectedCostOption === COST_OPTIONS.COMPED
          ? ''
          : state.costOptionAmount
      return {
        ...state,
        selectedCostOption: action.selectedCostOption,
        costOptionAmount: amount,
      }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_SELECTED_COST_AMOUNT: {
      return { ...state, costOptionAmount: action.costOptionAmount }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_CUSTOM_INDEXED_FIELD: {
      return {
        ...state,
        customFields: {
          ...state.customFields,
          [action.field]: action.value,
        },
      }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_CUSTOM_UNINDEXED_FIELD: {
      return {
        ...state,
        customFields: {
          ...state.customFields,
          custom_unindexed: {
            ...state.customFields.custom_unindexed,
            [action.system_name]: action.value,
          },
        },
      }
    }
    default:
      return state
  }
}

export default bookDetailsReducer
