import moment from 'moment-timezone'
import money from 'money-math'
import styled from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import SegmentedControl from 'mgr/lib/components/SegmentedControl'
import TextInput from 'mgr/lib/forms/TextInput'
import { InputRestrictions } from 'mgr/lib/forms/TextInput.typed'
import { TransactionTypes } from 'mgr/lib/utils/Constants'
import { getVenueLocalTime } from 'svr/common/TimeUtil'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import type { MixedAvailabilityTimeslot } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { InfoButton, Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, Banner } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { CalculateTotalToReducer } from '@sevenrooms/mgr-reservation-slideout/Payment/payment-utils'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { PaylinkBanner } from '../view/PaylinkBanner'
import { ActualPaymentLocales } from './ActualPayment.locales'
import { CardForm } from './CardForm'
import { useChangedReservationInfoWithPaylinkFlow } from './hooks'
import { PaymentField } from './PaymentField'
import type { ChargeDetails, PaymentRules } from './PaymentStep.types'
import type * as PaymentActions from '../../actions/PaymentActions'
import type { ValidateFieldMaps } from '../../containers/BookReservation.types'
import type { CardDetails, Client, ExtendedStripeInstance, ResCard, Venue } from '../../reducers/BookPaymentSlice.types'

const CC_AUTO_CANCEL_MINUTES = [-1, 30, 60, 120, 360, 720, 1440, 2880, 4320, 5760, 7200, 8640, 10080, 14400, 20160, 30240]
const CC_AUTO_CANCEL_STRINGS = [
  'Do not auto-cancel',
  '30 minutes',
  '1 hour',
  '2 hours',
  '6 hours',
  '12 hours',
  '24 hours',
  '48 hours',
  '3 days',
  '4 days',
  '5 days',
  '6 days',
  '7 days',
  '10 days',
  '14 days',
  '21 days',
]
const autoCancelReservationOptions = CC_AUTO_CANCEL_MINUTES.map((minutes, index) => ({
  name: CC_AUTO_CANCEL_STRINGS[index],
  value: minutes,
}))

const CreditCardCollectionOptionsEnum = {
  BOTH_DEFAULT_MANUAL: 'BOTH_DEFAULT_MANUAL',
  BOTH_DEFAULT_PAYLINK: 'BOTH_DEFAULT_PAYLINK',
  ONLY_MANUAL: 'ONLY_MANUAL',
  ONLY_PAYLINK: 'ONLY_PAYLINK',
}

const manualEntry = { name: 'Manually Enter Details', value: 'manual' }
const paylinkEntry = { name: 'Collect Details via Paylink', value: 'paylink' }

interface PaymentSectionFormProps {
  actions: typeof PaymentActions
  paylinkOnly: boolean
  selectedClient: Client
  validateFieldMap: ValidateFieldMaps['payment']
  formErrors: Record<string, boolean>
  cardDetails: CardDetails
  resCardDetails: ResCard
  chargeDetails: ChargeDetails
  upgradesCharges: CalculateTotalToReducer['upgrades']
  paymentRules: PaymentRules
  stripeInstance: ExtendedStripeInstance
  venueTimezone: string
  selectedTimeSlot: MixedAvailabilityTimeslot
  venue: Venue
}

export function PaymentSectionForm({
  actions,
  paylinkOnly,
  selectedClient,
  validateFieldMap,
  formErrors,
  cardDetails,
  chargeDetails,
  upgradesCharges,
  resCardDetails,
  paymentRules,
  stripeInstance,
  venueTimezone,
  selectedTimeSlot,
  venue,
}: PaymentSectionFormProps) {
  const { formatMessage } = useLocales()
  const [isEditWithOutstandingPaylinkFlow, changedReservationInfo] = useChangedReservationInfoWithPaylinkFlow()

  const { actual } = useStoreSelector(state => state.bookAvailabilityState)
  const { allTransactions } = useStoreSelector(state => state.bookPaymentState)

  const paylinkAllowed =
    chargeDetails.creditCardCollection !== CreditCardCollectionOptionsEnum.ONLY_MANUAL || chargeDetails.outstandingPaylink
  const paylinkOnlyCombined =
    paylinkOnly || !paymentRules.isMotoEnabled || chargeDetails.creditCardCollection === CreditCardCollectionOptionsEnum.ONLY_PAYLINK

  const isPaymentProviderAvailable = !!stripeInstance || chargeDetails.paymentSystem !== AccountTypes.STRIPE
  const entryOption = paylinkOnlyCombined && !resCardDetails.resCardId ? 'paylink' : chargeDetails.cardEntryOption
  const canAcceptPaymentInfo = entryOption !== 'manual' || isPaymentProviderAvailable

  const isPaymentOverrideLocked =
    !paymentRules.canOverridePayment ||
    (chargeDetails.override &&
      actual?.booked_with_override &&
      allTransactions.filter(
        t => t.transaction_type !== TransactionTypes.REMOVED && t.transaction_type !== TransactionTypes.REQUEST_REMOVED
      ).length > 0)
  const overrideBlock = (
    <div>
      <FieldGroup key="FieldGroup_OverrideReqPayment">
        <Checkbox
          name="override_payment_requirement"
          checked={chargeDetails.override}
          onChange={e => actions.changeOverride(e.target.checked)}
          disabled={isPaymentOverrideLocked}
        >
          {formatMessage(ActualPaymentLocales.overrideLabel)}
        </Checkbox>
      </FieldGroup>
      <HorizSeparator />
    </div>
  )

  let cardEntryOptions = []

  if (!paylinkOnlyCombined) {
    cardEntryOptions.push(manualEntry)
  }

  if (paylinkAllowed) {
    cardEntryOptions.push(paylinkEntry)
  }

  if (resCardDetails.resCardId) {
    const cardOption = {
      name: `${resCardDetails.resCardType} ....${resCardDetails.resCardLast4}`,
      value: resCardDetails.resCardId,
    }
    cardEntryOptions.push(cardOption)
  }

  const clientCards = (chargeDetails.clientCards || []).map(card => ({
    name: `${card.brand} ....${card.last_4}`,
    value: card.card_id,
  }))

  cardEntryOptions = cardEntryOptions.concat(clientCards)

  const showEntryOptions = cardEntryOptions.length > 1 || paylinkOnlyCombined

  const entryOptionsField = (
    <DropdownArrowsPicker
      name={formatMessage(ActualPaymentLocales.creditCardDetailsLabel)}
      choices={cardEntryOptions}
      value={entryOption}
      onChangeHandler={actions.changeCardEntryOption}
      isLightTheme
      disabled={cardEntryOptions.length === 1}
      style={{
        float: 'left',
        height: 44,
        width: '51%',
        marginBottom: 10,
      }}
    />
  )

  const takePaymentOptions = [
    {
      name: formatMessage(ActualPaymentLocales.segmentedControlNone) as string,
      value: 'none',
      testId: 'sr-button-no_payment',
      disabled: !chargeDetails.override && upgradesCharges?.upgradesTotal && !money.isZero(chargeDetails.chargeTotal ?? ''),
    },
    {
      name: formatMessage(ActualPaymentLocales.segmentedControlCharge),
      value: 'take',
      testId: 'sr-button-charge_card_now',
      disabled: !chargeDetails.canCharge,
    },
  ]

  if (paymentRules.canSaveCard) {
    takePaymentOptions.push({
      name: formatMessage(ActualPaymentLocales.segmentedControlSave),
      value: 'save',
      testId: 'sr-button-save_card_for_later',
      disabled: false,
    })
  }

  const showPaymentOptions =
    ((entryOption === 'manual' || entryOption === 'paylink') &&
      paymentRules.paymentRule == null &&
      (upgradesCharges === undefined || upgradesCharges?.upsellAmount === 0) &&
      paymentRules.isMotoEnabled &&
      isPaymentProviderAvailable) ||
    chargeDetails.override ||
    (chargeDetails.takePaymentOrSave === 'none' && !parseFloat(chargeDetails.chargeAmount) && !upgradesCharges?.upsellAmount)

  const takePaymentOptionsField = (
    <SegmentedControl
      options={takePaymentOptions}
      value={chargeDetails.takePaymentOrSave}
      width={takePaymentOptions.length > 2 ? 388 : 262}
      height={37}
      onChangeHandler={actions.changeTakePaymentOrSave}
    />
  )

  const takePaymentOptionsBlock = (
    <FieldGroup key="FieldGroup_TakePaymentOrSave" data-test="takePaymentOrSave-container">
      <div style={{ position: 'relative' }}>{takePaymentOptionsField}</div>
      <InlineNote>
        {chargeDetails.takePaymentOrSave === 'take' && formatMessage(ActualPaymentLocales.chargeCardNowNote)}
        {chargeDetails.takePaymentOrSave === 'save' && formatMessage(ActualPaymentLocales.chargeCardLaterNote)}
      </InlineNote>
    </FieldGroup>
  )

  if (entryOption === 'paylink') {
    const fromReservationToNowMinutes = selectedTimeSlot
      ? moment(selectedTimeSlot.real_datetime_of_slot ?? selectedTimeSlot.time_iso).diff(getVenueLocalTime(venueTimezone), 'minutes')
      : Infinity
    // integration into legacy custom validation mechanism
    // eslint-disable-next-line no-param-reassign
    validateFieldMap.paylink_auto_cancel = {
      isValid: () => {
        const skipNoAmount =
          chargeDetails.takePaymentOrSave === 'none' ||
          (chargeDetails.takePaymentOrSave === 'take' && !parseFloat(chargeDetails.chargeAmount))
        return chargeDetails.paylinkAutoCancel != null &&
          chargeDetails.paylinkAutoCancel !== -1 &&
          !skipNoAmount &&
          fromReservationToNowMinutes < chargeDetails.paylinkAutoCancel
          ? formatMessage(ActualPaymentLocales.autoCancelResError)
          : true
      },
    }
  }
  const autoCancelReservationField = (
    <DropdownArrowsPicker
      useOutsideLabel
      name={formatMessage(ActualPaymentLocales.autoCancelResLabel)}
      testId="sr-supafly-auto-cancel-reservation"
      choices={autoCancelReservationOptions}
      value={chargeDetails.paylinkAutoCancel}
      onChangeHandler={actions.changePaylinkAutoCancel}
      isValid={!formErrors.paylink_auto_cancel}
      isLightTheme
      height={40}
      labelStyle={{
        color: '#000000',
        fontSize: '14px',
        fontWeight: 400,
      }}
      style={{
        width: '100%',
      }}
      disabled={!chargeDetails.override && (parseFloat(chargeDetails.chargeAmount) || chargeDetails.cardRequired)}
    />
  )

  let cardDescriptionLabel: string = formatMessage(ActualPaymentLocales.cardDescriptionLabelDefault)
  if (entryOption === 'paylink') {
    cardDescriptionLabel = isEditWithOutstandingPaylinkFlow
      ? formatMessage(ActualPaymentLocales.cardDescriptionLabelPaylinkUpdated)
      : formatMessage(ActualPaymentLocales.cardDescriptionLabelPaylink)
  }
  const cardDescriptionField = (
    <TextInput
      label={cardDescriptionLabel}
      placeholder=""
      inputRestriction={InputRestrictions.none}
      value={chargeDetails.chargeDescription}
      onChange={actions.changeChargeDescription}
    />
  )

  const isRefund = money.isNegative(chargeDetails.chargeTotal ?? '')

  if (isEditWithOutstandingPaylinkFlow) {
    return (
      <div>
        {overrideBlock}
        <FieldGroup key="FieldGroup_PaylinkTimer" style={{ width: '367px' }}>
          <PaylinkBanner autoCancelDatetime={chargeDetails.outstandingPaylink as string} />
        </FieldGroup>
        {(changedReservationInfo?.dateTime || changedReservationInfo?.partySize) && chargeDetails.takePaymentOrSave === 'take' && (
          <FieldGroup key={`FieldGroup_Description-${entryOption}`}>{cardDescriptionField}</FieldGroup>
        )}
        <PaymentField validateFieldMap={validateFieldMap} isShow={false} cardEntryOption={entryOption} />
      </div>
    )
  }

  if (chargeDetails.takePaymentOrSave === 'none' && showPaymentOptions) {
    return (
      <div>
        {overrideBlock}
        {takePaymentOptionsBlock}
        <HorizSeparator />
        <PaymentField validateFieldMap={validateFieldMap} isShow={false} cardEntryOption={entryOption} />
      </div>
    )
  }

  return (
    <div>
      {overrideBlock}
      {showPaymentOptions && takePaymentOptionsBlock}
      {chargeDetails.override && chargeDetails.takePaymentOrSave === 'take' ? <OverridePaymentWarning /> : null}
      <HorizSeparator />

      {showEntryOptions && <FieldGroup key="FieldGroup_EntryOptions">{entryOptionsField}</FieldGroup>}

      {!canAcceptPaymentInfo && (
        <FieldGroup>
          <PaymentsNotAvailableErrorMessage>
            {chargeDetails.chargeTotal === '0' || paymentRules.canOverridePayment
              ? formatMessage(ActualPaymentLocales.errorMessageRefreshOrProceed)
              : formatMessage(ActualPaymentLocales.errorMessageRefresh)}
          </PaymentsNotAvailableErrorMessage>
        </FieldGroup>
      )}

      {isPaymentProviderAvailable && entryOption === 'manual' && !isRefund && (
        <Box mb="s">
          <CardForm
            actions={actions}
            selectedClient={selectedClient}
            validateFieldMap={validateFieldMap}
            formErrors={formErrors}
            cardDetails={cardDetails}
            useGuestProfilePhoneNumber={chargeDetails.useGuestProfilePhoneNumber}
            paymentSystem={chargeDetails.paymentSystem}
            stripeInstance={stripeInstance}
            venue={venue}
          />
        </Box>
      )}

      <PaymentField
        validateFieldMap={validateFieldMap}
        isShow={canAcceptPaymentInfo && chargeDetails.takePaymentOrSave === 'take'}
        cardEntryOption={entryOption}
      />

      {entryOption === 'paylink' && <FieldGroup key="FieldGroup_AutoCancel">{autoCancelReservationField}</FieldGroup>}

      {canAcceptPaymentInfo && chargeDetails.takePaymentOrSave === 'take' && (
        <FieldGroup key={`FieldGroup_Description-${entryOption}`}>{cardDescriptionField}</FieldGroup>
      )}
    </div>
  )
}

const HorizSeparator = styled.div`
  border-bottom: 1px dashed ${props => props.theme.lightGrey};
`

const InlineNote = styled.div`
  color: #999;
  font-size: 12px;
  font-style: oblique;
  margin: 9px;
`

const PaymentsNotAvailableErrorMessage = styled.div`
  font-size: 14px;
  color: red;
`

const FieldGroup = styled.div`
  margin: 15px 12px;
  ${props => props.theme.clearFix};
`

function OverridePaymentWarning() {
  const { formatMessage } = useLocales()
  return (
    <FieldGroup key="FieldGroup_OverridePaymentWarning">
      <Banner
        data-test="override-payment-warning"
        canDismiss={false}
        type="warning"
        title={
          <HStack alignItems="center" columnGap="xs">
            <Text fontSize="l" fontWeight="bold">
              {formatMessage(ActualPaymentLocales.overrideChargeCardWarningHeader)}
            </Text>
            <InfoButton
              tooltip={{
                content: (
                  <Text color="lightFont">
                    {formatMessage(ActualPaymentLocales.overrideChargeCardWarningTooltip, {
                      p: (chunks: string[]) => <p>{chunks}</p>,
                      b: (chunks: string[]) => <b>{chunks}</b>,
                    })}
                  </Text>
                ),
              }}
              data-test="override-payment-warning-info"
            />
          </HStack>
        }
        description={formatMessage(ActualPaymentLocales.overrideChargeCardWarningBody)}
        icon={<Icon name="VMSWeb-warning" color="warning" size="2x" />}
      />
    </FieldGroup>
  )
}
