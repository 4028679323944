import { defineMessages } from '@sevenrooms/core/locales'

export const ActualPaymentLocales = defineMessages({
  paylinkConfirmationTitle: {
    id: 'actual.payment.paylinkConfirmationTitle',
    defaultMessage: 'Are you sure you want to create another paylink?',
  },
  paylinkConfirmationBody: {
    id: 'actual.payment.paylinkConfirmationBody',
    defaultMessage:
      'There is an auto-cancel paylink that has not been completed by the guest. Instead of creating a new paylink, press "Resend" on that existing paylink to send it to the guest again.',
  },
  paylinkConfirmationCancel: {
    id: 'actual.payment.paylinkConfirmationCancel',
    defaultMessage: 'No, Go Back',
  },
  paylinkConfirmationSubmit: {
    id: 'actual.payment.paylinkConfirmationSubmit',
    defaultMessage: 'Yes, Create New Paylink',
  },
  amountRequiredError: {
    id: 'actual.payment.amountRequiredError',
    defaultMessage: 'Amount is required',
  },
  gratuityRequiredError: {
    id: 'actual.payment.gratuityRequiredError',
    defaultMessage: 'Gratuity is required',
  },
  overrideLabel: {
    id: 'actual.payment.overrideLabel',
    defaultMessage: 'Override: do not enforce required payment',
  },
  creditCardDetailsLabel: {
    id: 'actual.payment.creditCardDetailsLabel',
    defaultMessage: 'CREDIT CARD DETAILS',
  },
  segmentedControlNone: {
    id: 'actual.payment.segmentedControlNone',
    defaultMessage: 'No Payment',
  },
  segmentedControlCharge: {
    id: 'actual.payment.segmentedControlCharge',
    defaultMessage: 'Charge Card Now',
  },
  segmentedControlSave: {
    id: 'actual.payment.segmentedControlSave',
    defaultMessage: 'Save Card for Later',
  },
  chargeCardNowNote: {
    id: 'actual.payment.chargeCardNowNote',
    defaultMessage: 'You will be charging this card now.',
  },
  chargeCardLaterNote: {
    id: 'actual.payment.chargeCardLaterNote',
    defaultMessage: 'You will be able to charge this card up to 7 days following the reservation date.',
  },
  autoCancelResError: {
    id: 'actual.payment.autoCancelResError',
    defaultMessage: 'Auto-Cancel Reservation interval should be less reservation time',
  },
  autoCancelResLabel: {
    id: 'actual.payment.autoCancelResLabel',
    defaultMessage: 'Auto-Cancel Reservation if paylink not completed within:',
  },
  cardDescriptionLabelDefault: {
    id: 'actual.payment.cardDescriptionLabelDefault',
    defaultMessage: 'DESCRIPTION',
  },
  cardDescriptionLabelPaylink: {
    id: 'actual.payment.cardDescriptionLabelPaylink',
    defaultMessage: 'NOTE TO GUEST',
  },
  cardDescriptionLabelPaylinkUpdated: {
    id: 'actual.payment.cardDescriptionLabelPaylinkUpdated',
    defaultMessage: 'UPDATED NOTE TO GUEST',
  },
  errorMessageRefresh: {
    id: 'actual.payment.errorMessageRefresh',
    defaultMessage:
      'We are unable to process payments right now and this reservation requires payment.\n' +
      'Please refresh the page or try again later.',
  },
  errorMessageRefreshOrProceed: {
    id: 'actual.payment.chargeCardNowNote',
    defaultMessage:
      'We are unable to process payments right now.\n' +
      'Please refresh the page, try again later or proceed by saving this reservation without payment.',
  },
  cardRequiredFieldLong: {
    id: 'actual.payment.cardRequiredFieldLong',
    defaultMessage: 'Required',
  },
  cardRequiredFieldShort: {
    id: 'actual.payment.cardRequiredFieldShort',
    defaultMessage: "Req'd",
  },
  cardHolderLabel: {
    id: 'actual.payment.cardHolderLabel',
    defaultMessage: "CARD HOLDER'S NAME",
  },
  cardNumberLabel: {
    id: 'actual.payment.cardNumberLabel',
    defaultMessage: 'CARD NUMBER',
  },
  cardExpMonthLabel: {
    id: 'actual.payment.cardExpMonthLabel',
    defaultMessage: 'EXP. MONTH',
  },
  cardExpYearLabel: {
    id: 'actual.payment.cardExpYearLabel',
    defaultMessage: 'EXP. YEAR',
  },
  cardCvvLabel: {
    id: 'actual.payment.cardCvvLabel',
    defaultMessage: 'CVV',
  },
  cardHolderPhoneLabel: {
    id: 'actual.payment.cardHolderPhoneLabel',
    defaultMessage: "CARD HOLDER'S PHONE",
  },
  useGuestProfilePhoneNumberLabel: {
    id: 'actual.payment.useGuestProfilePhoneNumberLabel',
    defaultMessage: 'Use guest profile phone number',
  },
  overrideChargeCardWarningHeader: {
    id: 'actual.payment.overrideChargeCardWarningHeader',
    defaultMessage: 'Override with Caution',
  },
  overrideChargeCardWarningBody: {
    id: 'actual.payment.overrideChargeCardWarningBody',
    defaultMessage:
      'Overriding Payment disables automatic calculations for future Reservation edits and should only be done in special circumstances.',
  },
  overrideChargeCardWarningTooltip: {
    id: 'actual.payment.overrideChargeCardWarningTooltip',
    defaultMessage: `<p>Overrides are used to increase or decrease a specific item under Reservation Price or Upgrades. Do not override
    Payment when you edit a Reservation party size or select a time slot with different payment requirements. These payment adjustments are
    automatically calculated when you rebook the Reservation.</p><p>Do not override the Reservation Payment to issue a charge for
    something not included in the Reservation. Instead, select <b>Add separate charge</b> under the <b>Credit Card</b> section
    of the Reservation.</p>`,
  },
} as const)
