export const SET_DATA_FOR_3D_SECURE = 'SET_DATA_FOR_3D_SECURE'
export const SET_DATA_FOR_PENDING_PAYMENT = 'SET_DATA_FOR_PENDING_PAYMENT'
export const SET_PAYMENT_ENGINE = 'SET_PAYMENT_ENGINE'

// Adyen
export const SET_ADYEN_PAYMENT_TYPE = 'SET_ADYEN_PAYMENT_TYPE'

// Stripe
export const SET_STRIPE_ELEMENTS = 'SET_STRIPE_ELEMENTS'
export const SET_STRIPE_WALLET_PAYMENT_TYPE = 'SET_STRIPE_WALLET_PAYMENT_TYPE'
export const SET_STRIPE_ADDITIONAL_PAYMENT_METHODS_AVAILABLE = 'SET_STRIPE_ADDITIONAL_PAYMENT_METHODS_AVAILABLE'
export const SET_STRIPE_PAYMENT_METHOD_EVENT = 'SET_STRIPE_PAYMENT_METHOD_EVENT'
export const SET_STRIPE_INTENT_CLIENT_SECRET = 'SET_STRIPE_INTENT_CLIENT_SECRET'
export const SET_STRIPE_PAYMENT_TYPE = 'SET_STRIPE_PAYMENT_TYPE'
