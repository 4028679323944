import { skipToken } from '@reduxjs/toolkit/query'
import camelcaseKeys from 'camelcase-keys'
import { memo, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useGetUpgradesQuery } from '@sevenrooms/core/api'
import type { ReservationWidget } from '@sevenrooms/core/domain'
import { type Field, useForm, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import {
  getClientSelectGratuityValueFromUpgrades,
  Payment,
  type PaymentForm,
  type PaymentProps,
  useDefaultValues,
  usePaymentForm,
} from '@sevenrooms/mgr-reservation-slideout/Payment'
import { calculateTotalToReducer } from '@sevenrooms/mgr-reservation-slideout/Payment/payment-utils'
import { changeCardEntryOption, changePaymentForm, changePaymentFormSnapshot } from '../../actions/PaymentActions'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { defaultUpgrades } from '../availability/defaults'
import { ActualPaymentLocales } from './ActualPayment.locales'
import { initPreviousFulfiledPayment, initPreviousPayment } from './utils'
import type { ValidateFieldMaps } from '../../containers/BookReservation.types'
import type { CardEntryOption } from '../../reducers/BookPaymentSlice.types'

interface PaymentFieldProps {
  validateFieldMap: ValidateFieldMaps['payment']
  isShow: boolean
  cardEntryOption?: CardEntryOption
}

export function PaymentField({ validateFieldMap, isShow, cardEntryOption }: PaymentFieldProps) {
  const { formatMessage } = useLocales()

  const { selectedVenue, isPreselectedTime } = useStoreSelector(state => state.bookState)
  const { categories: categoriesForm, isDirty: isSelectableDirty } = useStoreSelector(state => state.upgradesState)
  const { partySize, selectedTimeSlot } = useStoreSelector(state => state.bookAvailabilityState)
  const bookPaymentState = useStoreSelector(state => state.bookPaymentState)

  const overrideUpgradesState = useMemo(
    () => (bookPaymentState.override ? bookPaymentState.upgrades : null),
    // eslint-disable-next-line
    [bookPaymentState.override, JSON.stringify(bookPaymentState.upgrades)]
  )

  const { paymentRule, override, allTransactions, defaultGratuity, defaultServiceCharge, formSnapshot } = bookPaymentState
  const isAdvancedPayment = paymentRule === 'advanced_payment'
  const { currencyCode, currencySymbol } = selectedVenue

  const { data = defaultUpgrades } = useGetUpgradesQuery(selectedVenue?.id ?? skipToken)

  const taxGroups = useMemo(
    () => camelcaseKeys(selectedVenue?.bookSettings.taxGroups ?? [], { deep: true }),
    [selectedVenue?.bookSettings.taxGroups]
  )

  const oldValues = useMemo(
    () =>
      allTransactions
        ? initPreviousPayment({
            allTransactions,
            upgrades: data,
            taxGroups,
          })
        : undefined,
    [allTransactions, data, taxGroups]
  )
  const oldFulfilledValues = useMemo(
    () =>
      allTransactions
        ? initPreviousFulfiledPayment({
            allTransactions,
            upgrades: data,
            taxGroups,
          })
        : undefined,
    [allTransactions, data, taxGroups]
  )

  const bundledUpgrades = selectedTimeSlot?.selected_automatic_upsells

  // current client selectable gratuity charge. This ensures default values are set as expected
  const clientSelectGratuityCharge = useMemo(() => {
    // if this hasnt been marked true yet, do no calculations
    if (!bookPaymentState.clientSelectGratuity.gratuityClientSelect) {
      return null
    }

    if (cardEntryOption === 'paylink' && !oldFulfilledValues) {
      return 0
    }

    let gratuity: number | null = 0
    // check if we have already persisted this value in state, we can grab it from there
    if (bookPaymentState.clientSelectGratuity.gratuity) {
      gratuity = Number(bookPaymentState.clientSelectGratuity.gratuity)
      // check the main charge gratuity type and grab it from there if its client select
    } else if (bookPaymentState.gratuityType === 'CLIENT_GRATUITY') {
      gratuity = Number(bookPaymentState.gratuityCharge ?? 0)
      // else we can loop through the upgrades and check if any of them have it set and return the value
    } else {
      gratuity = getClientSelectGratuityValueFromUpgrades(bookPaymentState.upgrades)
    }

    return gratuity
  }, [
    cardEntryOption,
    bookPaymentState.clientSelectGratuity.gratuity,
    bookPaymentState.clientSelectGratuity.gratuityClientSelect,
    bookPaymentState.gratuityCharge,
    bookPaymentState.gratuityType,
    bookPaymentState.upgrades,
    oldFulfilledValues,
  ])

  const defaultValues = useDefaultValues({
    partySize,
    chargeDetails: bookPaymentState,
    upgrades: data,
    bundledUpgrades,
    categoriesForm,
    isSelectableDirty,
    defaultGratuity,
    defaultServiceCharge,
    isPreselectedTime,
    oldValues,
    clientSelectGratuityCharge,
    override,
    overrideUpgradesState,
    formSnapshot,
  })
  const paymentsSchema = usePaymentForm()
  const { field, trigger, setError, reset } = useForm(paymentsSchema, { defaultValues })

  const [amount] = useWatchMany(field, ['amount'])

  // reset and keep dirty values when default values change
  useEffect(() => {
    reset(defaultValues, { keepDirty: true, keepDirtyValues: true })
    // eslint-disable-next-line
  }, [defaultValues])

  // reset and remove dirty values when override checkbox changes
  useEffect(() => {
    reset(defaultValues, { keepDirty: false, keepDirtyValues: false })
    // eslint-disable-next-line
  }, [override])

  // if we are in override state and selected upsells changes, we want to reset the form so that count is updated
  useEffect(() => {
    if (override) {
      reset(defaultValues, { keepDirty: false, keepDirtyValues: false })
    }
    // eslint-disable-next-line
  }, [defaultValues.categories])

  // integration into legacy custom validation mechanism
  // eslint-disable-next-line no-param-reassign
  validateFieldMap.amount = {
    isValid: () => {
      if (amount || !isAdvancedPayment) {
        return true
      }
      setError('amount', { type: 'custom', message: formatMessage(ActualPaymentLocales.amountRequiredError) }, { shouldFocus: true })
      return formatMessage(ActualPaymentLocales.amountRequiredError)
    },
  }
  // eslint-disable-next-line no-param-reassign
  validateFieldMap.gratuity = {
    isValid: () =>
      trigger('clientSelectGratuity.gratuity', { shouldFocus: true }).then(valid => {
        // null gratuity is ok when paylink option is selected
        if (!valid && cardEntryOption === 'paylink') {
          return true
        }
        return valid || override ? true : formatMessage(ActualPaymentLocales.gratuityRequiredError)
      }),
  }

  return (
    <>
      {isShow && (
        <>
          <FieldGroup key="FieldGroup_TakePayment">
            <Payment
              field={field}
              oldValues={oldValues}
              oldFulfilledValues={oldFulfilledValues}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
              override={override}
              taxGroups={taxGroups}
            />
          </FieldGroup>
          <HorizSeparator />
        </>
      )}
      <ReduxDispatcher
        field={field}
        oldValues={oldFulfilledValues}
        taxGroups={taxGroups}
        cardEntryOption={cardEntryOption}
        override={override}
      />
    </>
  )
}

// integration into legacy redux. Separate component to reduce renders. When redux would be removed from res slideout it will not be needed
const ReduxDispatcher = memo(
  ({
    field,
    oldValues,
    taxGroups,
    cardEntryOption,
    override,
  }: {
    field: Field<PaymentForm>
    oldValues?: PaymentProps['oldValues']
    taxGroups: ReservationWidget.TaxGroup[]
    cardEntryOption?: CardEntryOption
    override?: boolean
  }) => {
    const dispatch = useDispatch()
    const { selectedTimeSlot } = useStoreSelector(state => state.bookAvailabilityState)
    const bundledUpgrades = useMemo(
      () => selectedTimeSlot?.selected_automatic_upsells ?? [],
      [selectedTimeSlot?.selected_automatic_upsells]
    )

    const [amount, charges, categories, categoriesBundled, clientSelectGratuity] = useWatchMany(field, [
      'amount',
      'charges',
      'categories',
      'categoriesBundled',
      'clientSelectGratuity',
    ])

    useEffect(() => {
      if (charges) {
        const values = { amount, charges, categories, categoriesBundled, clientSelectGratuity }
        dispatch(changePaymentForm(calculateTotalToReducer(values, taxGroups, bundledUpgrades, oldValues)))
        dispatch(changeCardEntryOption(cardEntryOption))
      }
      if (override) {
        dispatch(changePaymentFormSnapshot({ amount }))
      }
      // eslint-disable-next-line
    }, [amount, charges, categories, categoriesBundled, clientSelectGratuity, bundledUpgrades, cardEntryOption])

    return null
  }
)

const HorizSeparator = styled.div`
  border-bottom: 1px dashed ${props => props.theme.lightGrey};
`

const FieldGroup = styled.div`
  margin: 15px 12px;
  ${props => props.theme.clearFix};
`
