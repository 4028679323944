import _ from 'lodash'
// eslint-disable-next-line import/no-cycle
import { changeCardHolderName } from 'mgr/actualslideout/actions/PaymentActions'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import ActualServices from 'mgr/lib/services/ActualServices'
import PaymentServices from 'mgr/lib/services/PaymentServices'
import { NotificationLevel } from 'mgr/lib/utils/Constants'
import { makeEnum } from 'mgr/lib/utils/Enum'
import { selectEnabledLanguages } from 'mgr/pages/single-venue/settings/selectors/language'
import { batchActions } from 'svr/common/ReduxUtils'
import * as Validators from 'svr/common/Validators'
import { AccountTypes } from 'svr/lib/Payments/Constants'
// eslint-disable-next-line import/no-cycle
import * as ActionTypes from './ActionTypes'
import * as SlideoutActions from './SlideoutActions'
import { releaseReservationHold } from './BookAvailabilityActions'

// Extracted from form/reservation.py
// Whose idea was it to use a string, then an integer and then
// a different string to represent the same thing?
const COST_OPTION_MAP = {
  COMPED: 'comp_table',
  MINIMUM_DOLLARS: 'dollar',
  MINIMUM_BOTTLES: 'bottle',
  NO_MINIMUM: 'nomin_table',
  TOTAL_DOLLARS: 'total_dollars',
}

export const COST_OPTION_NUM_MAP = {
  0: 'MINIMUM_DOLLARS',
  1: 'MINIMUM_BOTTLES',
  2: 'NO_MINIMUM',
  3: 'COMPED',
  4: 'TOTAL_DOLLARS',
}

export const COST_OPTIONS = makeEnum(_.keys(COST_OPTION_MAP))

export const onBeforePageUnload = () => ({
  type: ActionTypes.ON_BEFORE_PAGE_UNLOAD,
})
export const displayHigherChargeWarning = () => ({
  type: ActionTypes.DISPLAY_HIGHER_CHARGE_WARNING,
})
export const dismissUnsavedWarning = () => ({
  type: ActionTypes.DISMISS_UNSAVED_WARNING,
})
export const ignoreUnsavedWarning = () => ({
  type: ActionTypes.IGNORE_UNSAVED_WARNING,
})
export const dismissHigherChargeWarning = () => ({
  type: ActionTypes.DISMISS_HIGHER_CHARGE_WARNING,
})
export const ignoreHigherChargeWarning = () => ({
  type: ActionTypes.IGNORE_HIGHER_CHARGE_WARNING,
})

export const displayMissingEmailWarning = () => ({
  type: ActionTypes.DISPLAY_MISSING_EMAIL_WARNING,
})
export const dismissMissingEmailWarning = () => ({
  type: ActionTypes.DISMISS_MISSING_EMAIL_WARNING,
})
export const ignoreMissingEmailWarning = () => ({
  type: ActionTypes.IGNORE_MISSING_EMAIL_WARNING,
})

export const changeSearchVenues = (searchVenues, shiftPersistentId) => dispatch =>
  Promise.resolve(dispatch(GlobalActions.ensureHaveBookingInfoMany(searchVenues.map(({ id }) => id)))).then(fullVenues => {
    dispatch({
      type: ActionTypes.BOOK_CHANGE_SEARCH_VENUES,
      searchVenues: fullVenues,
      shiftPersistentId,
    })
  })

export const toggleStep = (stepKey, shouldScrollIntoView = true) => ({
  type: ActionTypes.BOOK_TOGGLE_STEP,
  stepKey,
  shouldScrollIntoView,
})
export const clearScrollIntoView = () => ({
  type: ActionTypes.BOOK_CLEAR_SCROLL_INTO_VIEW,
})
export const changeBookStepHeight = stepKey => ({
  type: ActionTypes.BOOK_CHANGE_BOOK_STEP_HEIGHT,
  stepKey,
})
export const bookFormValidated = (formErrors, firstInvalidStep, invalidMessages) => ({
  type: ActionTypes.BOOK_FORM_VALIDATED,
  formErrors,
  firstInvalidStep,
  invalidMessages,
})
export const dismissBookErrorDisplay = () => ({
  type: ActionTypes.BOOK_DISMISS_ERROR_DISPLAY,
})
export const clickBookReservation = (isChargeWarningApproved, override, overlappingResIds) => ({
  type: ActionTypes.BOOK_CLICK_BOOK_RESERVATION,
  isChargeWarningApproved,
  override,
  overlappingResIds,
})

export const receiveToken = response => (dispatch, getState) => {
  if (typeof response?.holderName === 'string') {
    dispatch(changeCardHolderName(response.holderName))
  }

  dispatch({
    type: ActionTypes.RECEIVE_TOKEN,
    token: response.token,
    data: response.data || null,
  })
}

const shouldDisplayHigherChargeWarningModal = (state, hasDateTimeChanged) => {
  const partySizeIncreased = state.bookAvailabilityState.partySize > state.viewResState.actual.max_guests
  let isHigherCharge = state.bookPaymentState.chargeAmountDiff > 0
  if (state.bookPaymentState.outstandingPaylink && state.bookPaymentState.changedReservationInfo) {
    isHigherCharge = state.bookPaymentState.changedReservationInfo.charges && state.bookPaymentState.chargeAmountDiff > 0
  }
  return isHigherCharge && (partySizeIncreased || hasDateTimeChanged)
}

const shouldDisplayChargeModal = (dispatch, state, isChargeWarningApproved) => {
  if (!state.bookDetailsState.isEditMode || !state.viewResState.actual || state.bookPaymentState.override || isChargeWarningApproved) {
    return false
  }

  const hasDateTimeChanged =
    state.bookAvailabilityState.selectedTimeSlot.time_iso !== state.viewResState.actual.date_arrival_time_dt_sync_dt_formatted

  if (shouldDisplayHigherChargeWarningModal(state, hasDateTimeChanged)) {
    dispatch(displayHigherChargeWarning())
    return true
  }
  return false
}

const isValidPaymentFields = (dispatch, state) => {
  const { bookPaymentState } = state
  const providerWithoutCardholder = [AccountTypes.SHIFT_4, AccountTypes.ADYEN]
  if (providerWithoutCardholder.includes(state.bookState.selectedVenue.bookSettings.paymentSystem)) {
    return true
  }
  if (!Validators.validateNotEmpty(bookPaymentState.cardHolderName)) {
    dispatch(bookFormValidated({ card_holder_name: 'Card Holders Name is required' }, 'payment'))
    return false
  }
  return true
}

const shouldDisplayMissingEmailModal = (dispatch, state) => {
  const { actualId } = state.slideoutState
  const { missingEmailWarningIgnored } = state.bookState
  const { email_address: emailAddress } = state.bookClientState.selectedClient
  const { cardEntryOption, takePaymentOrSave } = state.bookPaymentState

  if (
    !actualId && // edit mode
    !emailAddress && // no email address
    cardEntryOption === 'paylink' && // paylink selected
    takePaymentOrSave !== 'none' && // save card or take payment selected
    !missingEmailWarningIgnored // warning not already dismissed
  ) {
    dispatch(displayMissingEmailWarning())
    return true
  }
  return false
}

export const onClickBookReservation = (dispatch, getState, isChargeWarningApproved, override, overlappingResIds) => {
  const state = getState()
  const isPaylinkSelected = state.bookPaymentState.cardEntryOption === 'paylink'

  if (shouldDisplayChargeModal(dispatch, state, isChargeWarningApproved)) {
    return
  }

  if (shouldDisplayMissingEmailModal(dispatch, state)) {
    return
  }

  const hasNewCharge = state.bookPaymentState.chargeAmountDiff > 0
  const revalidatePayments = hasNewCharge && !state.bookState.isDirty && !state.bookPaymentState.override && !isPaylinkSelected
  if (revalidatePayments && !state.bookPaymentState.resCardId && !isValidPaymentFields(dispatch, state)) {
    return
  }

  let promise = Promise.resolve('')

  const ce = state.bookPaymentState.stripeCardElement
  const stripeCheckout = ce && ce._complete

  const { saferpayNameEmpty } = state.bookPaymentState
  const saferpayCheckout = state.bookState.selectedVenue.bookSettings.paymentSystem === AccountTypes.SAFERPAY && !saferpayNameEmpty

  const hasPayment = state.viewResState.actual && state.viewResState.actual.prepayment_formatted
  const hasChargeTotal = Number(state.bookPaymentState.chargeTotal) > 0
  const requireCard = (state.bookPaymentState.cardRequired && !state.bookPaymentState.override && !hasPayment) || hasChargeTotal

  const isShift4 = AccountTypes.SHIFT_4 === state.bookState.selectedVenue.bookSettings.paymentSystem
  const shift4CheckoutRequired =
    isShift4 && state.bookPaymentState.takePaymentOrSave !== 'none' && !state.bookPaymentState.resCardId && !isPaylinkSelected

  if (
    stripeCheckout ||
    saferpayCheckout ||
    shift4CheckoutRequired ||
    state.bookPaymentState.cardHolderNumber ||
    (requireCard &&
      [AccountTypes.SAFERPAY, AccountTypes.ADYEN].includes(state.bookState.selectedVenue.bookSettings.paymentSystem) &&
      !isPaylinkSelected) ||
    (state.bookState.selectedVenue.bookSettings.paymentSystem === AccountTypes.ADYEN && state.bookPaymentState.cardHolderName)
  ) {
    promise = PaymentServices.getCreditCardToken(
      state.bookPaymentState,
      state.bookState.selectedVenue.bookSettings,
      state.bookState.selectedVenue,
      state.commonPayment
    ).then(response => {
      if ((saferpayCheckout && !response?.token && !state.bookPaymentState.override) || (shift4CheckoutRequired && !response?.token)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Invalid Credit Card Details')
      }
      return response
    })
  }

  // eslint-disable-next-line consistent-return
  return promise
    .then(response => {
      if (response?.holderName) {
        dispatch(changeCardHolderName(response.holderName))
      }
      dispatch(receiveToken(response))
      dispatch(trySubmitReservationAction(override, overlappingResIds))
    })
    .catch(error => {
      dispatch(
        batchActions([
          submitReservationFail(),
          error.data
            ? showWarningModal(error.data)
            : SlideoutActions.showNotificationError(
                (state.slideoutState.actualId ? 'Error saving reservation: ' : 'Error booking reservation: ') + error
              ),
        ])
      )
    })
}

/* SUBMIT RESERVATION */

const buildBookedMessage = (actual, isEditMode) => {
  const bookedOrUpdated = isEditMode ? 'Updated' : 'Booked'
  const name = actual.client_display_name
  const arrivalTime = !_.isEmpty(actual.arrival_time_display) && `at ${actual.arrival_time_display}`
  const partySize = !_.isEmpty(actual.max_guests_formatted) && `for ${actual.max_guests_formatted}`
  const minPrice =
    !_.isEmpty(actual.min_price_formatted) && actual.min_price_formatted !== 'No Min' && `with a ${actual.min_price_formatted} min`
  const date = `on ${actual.date_format_mostly_full_no_year}`
  return _.compact([bookedOrUpdated, name, arrivalTime, partySize, minPrice, date]).join(' ')
}

const submitReservationBegin = () => ({
  type: ActionTypes.BOOK_SUBMIT_RESERVATION_START,
})
const submitReservationSuccess = (actual, venue) => ({
  type: ActionTypes.BOOK_SUBMIT_RESERVATION_SUCCESS,
  actual,
  venue,
})
const submitReservationFail = () => ({
  type: ActionTypes.BOOK_SUBMIT_RESERVATION_FAIL,
})

const reservationBookedNotificationBanner = (actual, isEditMode) => {
  const message = buildBookedMessage(actual, isEditMode)
  return SlideoutActions.showNotificationBanner({
    message,
    level: NotificationLevel.SUCCESS,
    visible: true,
  })
}

const showWarningModal = duplicateResError => dispatch => {
  $('#warning-modal').show()
  SvrManager.Components.mountWarningModal(
    'warning-modal',
    'This client has another booking during this time.',
    duplicateResError.overlapping_res_array,
    duplicateResError.can_cancel ? `Book and Cancel Other Booking${duplicateResError.overlapping_res_array.length > 1 ? 's' : ''}` : null,
    'Book Anyway',
    duplicateResError.can_cancel
      ? unmount => {
          unmount()
          dispatch(clickBookReservation(false, true, JSON.stringify(duplicateResError.overlapping_res_ids)))
          $('#warning-modal').hide()
        }
      : null,
    unmount => {
      unmount()
      dispatch(clickBookReservation(false, true, null))
      $('#warning-modal').hide()
    },
    unmount => {
      unmount()
      dispatch(submitReservationFail())
      $('#warning-modal').hide()
    }
  )
}

const trySubmitReservationAction = (override, overlappingResIds) => (dispatch, getState) => {
  dispatch(submitReservationBegin())
  const state = getState()
  const { actualId } = state.slideoutState
  const { selectedVenue, reservationRequestId, selectedExperienceId } = state.bookState
  const { date, partySize, duration, selectedTimeSlot, usingDefaultDuration, audienceId, bufferMins, excludeFromShiftPacing } =
    state.bookAvailabilityState
  const languages = selectEnabledLanguages(state)

  const {
    reservationNotes,
    reservationTags,
    selectedTableIds,
    isAutoAssign,
    isCustomAssign,
    overbookDirective,
    selectedBookedBy,
    messagingFields,
    isConciergeReservation,
    additionalBookedBy,
    newPromoterName,
    customBookedByEnabled,
    perks,
    costOptionAmount,
    selectedCostOption,
    customFields,
  } = state.bookDetailsState

  const overrideUpgrades = state.upgradesState?.override
  const paymentData = state.bookPaymentState
  const adjustedSelectedCostOption = COST_OPTION_MAP[selectedCostOption]
  const selectedClient = { ...state.bookClientState.selectedClient }
  const selectedSource = { ...state.bookSourceState.selectedSource }
  const venueId = selectedVenue.id
  const venueLocale = selectedVenue.countryCode
  const isEditMode = !_.isEmpty(actualId)
  const errHandler = error => {
    // The following block of code reloads the Shift4 iframe, which is an unfortunately necessary hacky way to ensure that
    // the credit card fields can accept input after payment via Shift4 fails.
    if (state.bookState.selectedVenue.bookSettings.paymentSystem === AccountTypes.SHIFT_4) {
      const shift4iframe = document.getElementById('i4goFrame')?.querySelector('iframe')
      if (shift4iframe) {
        shift4iframe.src += ''
      }
    }

    dispatch(
      batchActions([
        submitReservationFail(),
        error.data
          ? showWarningModal(error.data)
          : SlideoutActions.showNotificationError((isEditMode ? 'Error saving reservation: ' : 'Error booking reservation: ') + error),
      ])
    )
  }
  const availability = state.bookAvailabilityState
  const { reservationHoldId } = availability

  const saveAction = isEditMode ? ActualServices.saveEditReservation : ActualServices.bookReservation
  return saveAction(
    {
      actualId,
      venueId,
      date,
      partySize,
      duration,
      usingDefaultDuration,
      selectedTimeSlot,
      selectedClient,
      selectedSource,
      reservationNotes,
      reservationTags,
      selectedTableIds,
      isAutoAssign,
      isCustomAssign,
      overbookDirective,
      selectedBookedBy,
      additionalBookedBy,
      newPromoterName,
      customBookedByEnabled,
      messagingFields,
      paymentData,
      isConciergeReservation,
      perks,
      costOptionAmount,
      adjustedSelectedCostOption,
      customFields,
      override,
      overrideUpgrades,
      overlappingResIds,
      audienceId,
      venueLocale,
      ...(reservationHoldId ? { reservationHoldId } : {}),
      bufferMins: window.globalInit.venueSettings.is_buffers_enabled ? bufferMins : 0,
      excludeFromShiftPacing,
      languages,
      experienceId: selectedExperienceId,
      reservationRequestId,
      locale: selectedVenue.locale,
      previousActual: state.viewResState.actual,
    },
    errHandler
  ).then(data => {
    if (_.isNil(data)) {
      return
    }
    const [actual, response] = data
    const actual_id_to_force_update = actualId === actual.id ? actualId : null
    const autoAssignmentsAndProblemResPromise = dispatch(
      SlideoutActions.tryGetAutoAssignmentsAndProblems(actual_id_to_force_update, actual)
    )
    if (isEditMode) {
      dispatch(SlideoutActions.postbackActualOnEdit(autoAssignmentsAndProblemResPromise, response))
    } else {
      dispatch(SlideoutActions.postbackActualOnBook(actual, response, autoAssignmentsAndProblemResPromise))
    }
    releaseReservationHold(dispatch, getState)
    // eslint-disable-next-line consistent-return
    return (
      actual &&
      dispatch(batchActions([submitReservationSuccess(actual, selectedVenue), reservationBookedNotificationBanner(actual, isEditMode)]))
    )
  })
}
