import _ from 'lodash'
import { findTags } from 'mgr/lib/components/GenericTagsDropDown'
import ClientServices from 'mgr/lib/services/ClientServices'
import GenericTagsServices from 'mgr/lib/services/GenericTagsServices'
import { splitIntoContactInfo } from 'mgr/lib/utils/String'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
// eslint-disable-next-line import/no-cycle
import * as SlideoutActions from './SlideoutActions'

export const findClientTags = (tagGroups, tagHashList) => {
  if (!tagGroups || !tagHashList) {
    return []
  }
  const foundTags = []
  for (const tg of tagGroups) {
    for (const tagHash of tagHashList) {
      const values = tagHash.split('##')
      const tg_id = values[1]
      const tag = values[3]
      if (tg.id === tg_id && tg.tags.includes(tag)) {
        foundTags.push({
          tag_group_id: tg.id,
          tag_group_name: tg.name,
          tag_group_name_display: tg.name_display,
          tag_color: tg.color_hex,
          is_private: tg.is_private,
          entity_key: tg.entity_key,
          tag_name: tag,
          is_source: tg.is_source,
          is_autotag: tg.is_autotag,
          tag_name_display: tag in tg.tag_name_displays ? tg.tag_name_displays[tag] : tag,
        })
      }
    }
  }
  return foundTags
}

export const changeSearchTerms = searchTerms => ({
  type: ActionTypes.BOOK_CLIENT_CHANGE_SEARCH_TERMS,
  searchTerms,
})

export const changeSelectedHotelId = selectedHotelId => ({
  type: ActionTypes.BOOK_CLIENT_CHANGE_SELECTED_HOTEL_ID,
  selectedHotelId,
})

export const selectHotelClient = (venue, hotelClient, selectedHotelId) => ({
  type: ActionTypes.BOOK_CLIENT_SELECT_HOTEL_CLIENT,
  venue,
  hotelClient,
  selectedHotelId,
})

export const selectExistingClient = (clientId, hotelClient) => ({
  type: ActionTypes.BOOK_CLIENT_SELECT_EXISTING_CLIENT,
  clientId,
  hotelClient,
})

export const changeSelectedClient = (selectedClient, clientHistory) => ({
  type: ActionTypes.BOOK_CLIENT_CHANGE_SELECTED_CLIENT,
  selectedClient,
  clientHistory,
})

export const deselectClient = previouslySelectedClient => ({
  type: ActionTypes.BOOK_CLIENT_DESELECT_CLIENT,
  previouslySelectedClient,
})

export const onDeselectClient = dispatch => {
  dispatch(changeSelectedClient(null))
}

export const changeClientField = (field, value) => ({
  type: ActionTypes.BOOK_CLIENT_CHANGE_CLIENT_FIELD,
  field,
  value,
})

export const changeClientCustomUnindexedField = (system_name, value) => ({
  type: ActionTypes.BOOK_CLIENT_CHANGE_CUSTOM_UNINDEXED_FIELD,
  system_name,
  value,
})

export const changeClientPhone = phoneAndCountry => ({
  type: ActionTypes.BOOK_CLIENT_CHANGE_PHONE_FIELD,
  phoneAndCountry,
})

export const changeClientAltPhone = phoneAndCountry => ({
  type: ActionTypes.BOOK_CLIENT_CHANGE_ALT_PHONE_FIELD,
  phoneAndCountry,
})

export const changeClientTags = value => ({
  type: ActionTypes.BOOK_CLIENT_CHANGE_CLIENT_TAGS,
  value,
})

export const toggleShowMoreClientFields = () => ({
  type: ActionTypes.BOOK_CLIENT_TOGGLE_SHOW_MORE_CLIENT_FIELDS,
})

export const changeReservationTags = value => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_RESERVATION_TAGS,
  value,
})

export const onChangeSelectedVenue = (dispatch, getState, updatedVenueId) => {
  const store = getState()
  const venueId = updatedVenueId || store.bookState.selectedVenue.id
  dispatch(tryGetClientTagGroupsAction(venueId))
  onChangeClientSearch(dispatch, getState)
}

export const onSelectExistingClient = (dispatch, getState, clientId, hotelClient) => {
  const store = getState()
  const venueId = store.bookState.selectedVenue.id
  dispatch(tryGetClientHydratedAction(venueId, clientId, hotelClient))
}

export const clickAddAsNewClient = (venue, searchTerms) => {
  const { firstName, lastName, phoneNumberFormatted, emailAddress } = splitIntoContactInfo(searchTerms)
  const newClient = createNewClient(venue, firstName, lastName, phoneNumberFormatted, emailAddress)
  return changeSelectedClient(newClient)
}

const findValidatedDefaultTags = (tagGroups, tagHashList) => findTags(tagGroups, tagHashList)

const mergeTags = listsOfTags => {
  const gTagHashMap = {}
  const results = []
  const addList = (tagList, tagHashMap, resultTagList) => {
    for (const tag of tagList) {
      if (!tag.tagHash) {
        continue
      }
      if (tag.tagHash in tagHashMap) {
        continue
      } else {
        // eslint-disable-next-line no-param-reassign
        tagHashMap[tag.tagHash] = 1
        resultTagList.push(tag)
      }
    }
  }
  for (const tagList of listsOfTags) {
    addList(tagList, gTagHashMap, results)
  }
  return results
}

/**
 * Hotel search client objects come from third party integrations
 * such as MGM and Opera
 */
export const onSelectHotelClient = (dispatch, getState, venue, hotelClient, selectedHotelId) => {
  const {
    first_name,
    last_name,
    phone_number,
    phone_number_formatted,
    email,
    venue_group_client_id,
    reservation_tags,
    client_tags,
    loyalty_id,
    loyalty_tier,
    external_user_id,
    is_status_active,
    profile_notes: notes,
  } = hotelClient
  const store = getState()
  if (venue_group_client_id) {
    // hydrate client profile
    dispatch(selectExistingClient(venue_group_client_id, { ...hotelClient, selectedHotelId }))
  } else {
    const clientTagGroups = store.bookClientState.clientTagGroupsByVenue[venue.id]
    let clientTags
    if (clientTagGroups && client_tags) {
      clientTags = findClientTags(clientTagGroups, client_tags)
    }
    // create a new client profile
    const newClient = createNewClient(
      venue,
      first_name,
      last_name,
      phone_number_formatted || phone_number,
      email,
      clientTags,
      loyalty_id,
      loyalty_tier,
      external_user_id,
      notes,
      is_status_active
    )
    newClient.hotel_client = { ...hotelClient, selectedHotelId }
    dispatch(changeSelectedClient(newClient))
  }
  const reservationTagGroups = store.bookDetailsState.reservationTagGroupsByVenue[venue.id]
  if (reservationTagGroups) {
    const existingReservationTags = store.bookDetailsState.reservationTags
    const foundTags = findValidatedDefaultTags(reservationTagGroups, reservation_tags)
    const mergedTags = mergeTags([existingReservationTags, foundTags])
    dispatch(changeReservationTags(mergedTags))
  }
}

export const createNewClient = (
  // Also used by BookSourceActions
  venue,
  first_name,
  last_name,
  phone_number_formatted,
  email_address,
  client_tags_display,
  loyalty_id,
  loyalty_tier,
  external_user_id,
  notes = '',
  is_status_active = null
) => ({
  first_name,
  last_name,
  phone_number_formatted,
  email_address,
  external_user_id: external_user_id ?? null,
  is_status_active: is_status_active ?? null,
  anniversary_day: null,
  anniversary_display: '',
  anniversary_month: null,
  birthday_day: null,
  birthday_display: '',
  birthday_month: null,
  company: '',
  email_address_alt: '',
  gender: null,
  gender_display: '',
  has_venue_in_marketing_opt_in_list: false,
  has_venue_in_sizzle_unsubscribed_venues_list: false,
  id: null,
  is_contact_info_viewable: true,
  is_email_address_editable: true,
  is_email_address_alt_editable: true,
  is_phone_editable: true,
  is_phone_alt_editable: true,
  name_display: _.compact([first_name, last_name]).join(' '),
  notes,
  phone_number_alt_formatted: '',
  phone_number_alt_locale: venue.countryCode,
  phone_number_locale: venue.countryCode,
  photos: null,
  salutation: '',
  status: '',
  loyalty_id: loyalty_id ?? '',
  loyalty_tier: loyalty_tier ?? '',
  tags_display: client_tags_display ?? [],
  tags_group_display: [],
  title: '',
  total_cancellations: 0,
  total_noshows: 0,
  total_spend_formatted_no_decimals: '$0',
  total_spend_per_cover_formatted: '$0',
  total_spend_per_visit_formatted: '$0',
  total_visits: 0,
  custom_group_0: '',
  custom_group_1: '',
  custom_group_2: '',
  custom_group_3: '',
  custom_group_4: '',
  custom_venue_0: '',
  custom_venue_1: '',
  custom_venue_2: '',
  custom_venue_3: '',
  custom_venue_4: '',
  custom_unindexed: [],
})

/* workflow to initialize a client search keywords or hotel id change */
export const onChangeClientSearch = (dispatch, getState, isHotelOnlyChange = false) => {
  const state = getState()
  const venueId = state.bookState.selectedVenue.id
  const { selectedHotelId, searchTerms } = state.bookClientState
  const canViewThirdPartyClientDbSearch = state.appState.userDomain.can_view_third_party_client_db_search

  const promises = [
    dispatch(tryGetClientLookupAction(venueId, canViewThirdPartyClientDbSearch && selectedHotelId, searchTerms, isHotelOnlyChange)),
  ]

  return Promise.all(promises)
}

/* CLIENT LOOKUP */

const getClientLookupBegin = () => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_LOOKUP_START,
})
const getClientLookupSuccess = clientResults => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_LOOKUP_SUCCESS,
  clientResults,
})
const getClientLookupFail = () => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_LOOKUP_FAIL,
})

const getClientHotelLookupBegin = () => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_START,
})
const getClientHotelLookupSuccess = clientResults => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_SUCCESS,
  clientResults,
})
const getClientHotelLookupFail = () => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_FAIL,
})

let debouncePMSSearchTimeout = null

const tryGetClientLookupAction = (venueId, hotelId, searchTerms, isHotelOnlyChange) => (dispatch, getState) => {
  const promises = []
  if (!isHotelOnlyChange) {
    dispatch(getClientLookupBegin())
    const clientErrHandler = error =>
      dispatch(batchActions([getClientLookupFail(), SlideoutActions.showNotificationError(`Error looking up client: ${error}`)]))
    const isSource = false
    const clientLookup = ClientServices.fetchClientLookup(venueId, searchTerms, isSource, clientErrHandler).then(clientResults => {
      const latestStore = getState()
      if (searchTerms !== latestStore.bookClientState.searchTerms) {
        window.svrDebug('Ignoring slow request response for fetchClientLookup')
        return
      }
      // eslint-disable-next-line consistent-return
      return dispatch(getClientLookupSuccess(clientResults))
    })
    promises.push(clientLookup)
  }
  if (!_.isNil(hotelId)) {
    clearTimeout(debouncePMSSearchTimeout)
    debouncePMSSearchTimeout = setTimeout(() => {
      dispatch(getClientHotelLookupBegin())
      const hotelErrHandler = error =>
        dispatch(
          batchActions([getClientHotelLookupFail(), SlideoutActions.showNotificationError(`Error looking up hotel clients: ${error}`)])
        )
      const hotelLookup = ClientServices.fetchHotelClientLookup(venueId, hotelId, searchTerms, hotelErrHandler).then(clientResults => {
        const latestStore = getState()
        if (searchTerms !== latestStore.bookClientState.searchTerms || hotelId !== latestStore.bookClientState.selectedHotelId) {
          window.svrDebug('Ignoring slow request response for fetchHotelClientLookup')
          return
        }
        const hotelName = latestStore.bookClientState.hotelLookupOptions[hotelId]
        for (const client of clientResults) {
          client.hotel_name = hotelName
        }
        // eslint-disable-next-line consistent-return
        return dispatch(getClientHotelLookupSuccess(clientResults))
      })
      promises.push(hotelLookup)
    }, 1000)
  }
  return Promise.all(promises)
}

/* CLIENT HYDRATE */

const getClientHydratedBegin = () => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_HYDRATED_START,
})
const getClientHydratedSuccess = () => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_HYDRATED_SUCCESS,
})
const getClientHydratedFail = () => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_HYDRATED_FAIL,
})

const tryGetClientHydratedAction = (venueId, clientId, hotelClient) => dispatch => {
  dispatch(getClientHydratedBegin())
  const errHandler = error =>
    dispatch(batchActions([getClientHydratedFail(), SlideoutActions.showNotificationError(`Error looking up client: ${error}`)]))
  return ClientServices.fetchClientHydrated(venueId, clientId, errHandler).then(({ venueId, client, actuals }) => {
    if (hotelClient) {
      // eslint-disable-next-line no-param-reassign
      client.hotel_client = hotelClient
    }
    return dispatch(batchActions([getClientHydratedSuccess(), changeSelectedClient(client, actuals)]))
  })
}

/* CLIENT TAGS */

const getClientTagGroupsBegin = () => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_START,
})

const getClientTagGroupsSuccess = (venueId, tagGroups) => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_SUCCESS,
  venueId,
  tagGroups,
})

const getClientTagGroupsFail = () => ({
  type: ActionTypes.BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_FAIL,
})

const tryGetClientTagGroupsAction = venueId => dispatch => {
  dispatch(getClientTagGroupsBegin())
  return GenericTagsServices.fetchVenueClientTagGroups(venueId).then(
    ({ venueId, tagGroups }) => dispatch(getClientTagGroupsSuccess(venueId, tagGroups)),
    error =>
      dispatch(batchActions([getClientTagGroupsFail(), SlideoutActions.showNotificationError(`Error getting client tags: ${error}`)]))
  )
}
