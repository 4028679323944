import React, { PureComponent } from 'react'
import styled from 'styled-components'
import VmsIcons, { StyledIcons } from 'svr/common/VmsIcons'
import { connect } from 'react-redux'
import { selectLanguageStrings } from '../../../widget/dining/src/selectors/languageSelectors'
import Banner from 'svr/widget/dining/src/components/Banner'

const SlideoutHeader = styled.div`
  height: 42px;
  width: 100%;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.navigationDark};
  padding: 0;
  position: relative;
`

const SlideoutTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  height: 42px;
  line-height: 42px;
  margin-left: 20px;
`

const CloseButton = styled(StyledIcons.S)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 42px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 200;
  user-select: none;
  :hover {
    background-color: ${props => props.theme.primaryRgba10};
  }
`

const SlideoutBody = styled.div`
  position: absolute;
  top: 42px;
  bottom: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  ${props => props.bodyCSS};
  padding-top: ${props => props.paddingTop || '0px'};
`

// FIXME: Need to come up with a strategy for how
// to make the slideout itself responsive.
const SlideoutContainer = styled.div`
  height: 100%;
  border-collapse: separate;
  width: 80%;
  max-width: ${props => props.maxWidth};
  background-color: ${props => props.theme.margin};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: ${props => (props.zIndex ? props.zIndex : 400)};
  top: 0;
  font-family: ${props => props.theme.fontFamily} !important;
  font-feature-settings: normal;
  font-kerning: auto;
  font-size-adjust: none;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  font-variant-caps: normal;
  visibility: ${props => (props.isSlideoutOpen ? 'visible' : 'hidden')};
  right: ${props => (props.isSlideoutOpen ? '0' : '-100%')};
  transition: all 300ms ease-in-out;
`

const SlideoutBlurView = styled.div`
  position: fixed;
  z-index: ${props => (props.zIndex ? props.zIndex - 1 : 399)};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: ${props => (props.isSlideoutOpen ? 'visible' : 'hidden')};
  opacity: ${props => (props.isSlideoutOpen ? '1' : '0')};
  background-color: ${props => props.theme.navigationDarkRgba60};
  transition: all 300ms ease-in-out;
`

const colorSummaryBar = 'rgba(1, 25, 41, 1)'
const fontsColorSummaryBar = '#ffffff'
const colorLines = 'rgba(238, 238, 238, 1)'
const styleHoldSubBanner = {
  position: 'absolute',
  borderTop: 'unset',
  maxWidth: '100%',
  minWidth: '100%',
  width: '100%',
  display: 'block',
}

const overlayStyle = {
  position: 'absolute',
  bottom: 0,
  background: 'lightblue',
  color: '#f1f1f1',
  width: '100%',
  transition: '.5s ease',
  opacity: 0.2,
  fontSize: '20px',
  textAlign: 'center',
  zIndex: 10_000,
  height: '100%',
  verticalAlign: 'middle',
  alignItems: 'center',
  display: 'flex',
}

const overlayImageStyle = {
  display: 'block',
  margin: '0px auto',
  zIndex: 20_000,
  position: 'relative',
  width: '50px',
  opacity: 1,
}

const SlideoutLayout = ({
  title,
  showBlurBackground,
  isSlideoutOpen,
  footer,
  onCloseHandler,
  actions,
  children,
  maxWidth,
  zIndex,
  bodyCSS,
  info,
  errorMessageForHoldReservationFailure,
  logLevel,
  venueId,
  gettingReservationHold,
  mediaUrl,
}) => {
  const { textHoldingTable, textHoldExpired } = selectLanguageStrings()

  const logLevelStyle = logLevel === 'warning' ? { color: '#fff', backgroundColor: '#000', ...styleHoldSubBanner } : styleHoldSubBanner

  const hasBanner = info.expirationMoment || errorMessageForHoldReservationFailure

  const handleClose = () => actions.closeSlideout()
  return (
    <div>
      {showBlurBackground && <SlideoutBlurView {...{ isSlideoutOpen, zIndex }} />}
      <SlideoutContainer {...{ isSlideoutOpen, maxWidth, zIndex }}>
        <SlideoutHeader>
          {React.isValidElement(title) ? title : <SlideoutTitle data-test="sr-slideout-title">{title}</SlideoutTitle>}

          {!gettingReservationHold && info.expirationMoment && (
            <Banner
              info={info}
              onBackClick={() => {}}
              textHoldingTable={textHoldingTable}
              textHoldExpired={textHoldExpired}
              styleBannerWrapper={{ display: 'none' }}
              styleHoldSubBanner={styleHoldSubBanner}
              colorSummaryBar={colorSummaryBar}
              fontsColorSummaryBar={fontsColorSummaryBar}
              colorLines={colorLines}
            />
          )}
          {!gettingReservationHold && errorMessageForHoldReservationFailure && (
            <Banner
              info={info}
              onBackClick={() => {}}
              styleBannerWrapper={{ display: 'none' }}
              styleHoldSubBanner={logLevelStyle}
              colorSummaryBar={colorSummaryBar}
              fontsColorSummaryBar={fontsColorSummaryBar}
              colorLines={colorLines}
              errorMessageForHoldReservationFailure={errorMessageForHoldReservationFailure}
            />
          )}

          <CloseButton id="sr-slideout-close" onClick={onCloseHandler}>
            {VmsIcons.Close}
          </CloseButton>
        </SlideoutHeader>

        <div>
          <SlideoutBody {...{ bodyCSS }} paddingTop={hasBanner ? '20px' : '0px'}>
            {children}
          </SlideoutBody>
          {gettingReservationHold && (
            <div style={overlayStyle}>
              {mediaUrl && <img alt="sevenrooms-spinner" style={overlayImageStyle} src={`${mediaUrl}images/SevenRoomsLoader.svg`} />}
            </div>
          )}
        </div>
        {footer}
      </SlideoutContainer>
    </div>
  )
}

SlideoutLayout.defaultProps = {
  title: '',
  showBlurBg: false,
  maxWidth: '796px',
  bodyCSS: '',
  onCloseHandler: () => {},
}

SlideoutLayout.propTypes = {
  title: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.node]),
  showBlurBackground: React.PropTypes.bool,
  isSlideoutOpen: React.PropTypes.bool,
  footer: React.PropTypes.object,
  onCloseHandler: React.PropTypes.func.isRequired,
  maxWidth: React.PropTypes.string,
  bodyCSS: React.PropTypes.string,
}

const mapStateToProps = state => ({
  info: {
    dateStr: state.bookAvailabilityState?.date,
    timeStr: state.bookAvailabilityState?.selectedTimeSlot?.time,
    expirationMoment: state.bookAvailabilityState?.expirationMoment,
    partySizeStr: state.bookAvailabilityState?.partySize,
  },
  errorMessageForHoldReservationFailure: state.bookAvailabilityState?.errorMessageForHoldReservationFailure,
  logLevel: state.bookAvailabilityState?.logLevel,
  venueId: state.bookAvailabilityState?.searchVenues[0]?.urlKey,
  gettingReservationHold: state.bookAvailabilityState?.gettingReservationHold,
  mediaUrl: state.appState?.mediaUrl,
})

const mapDispatchToProps = () => ({})

const SlideoutLayoutExt = connect(mapStateToProps, mapDispatchToProps)(SlideoutLayout)
export default SlideoutLayoutExt
