import { srGet } from '@sevenrooms/core/api'

const fetchClientLookup = (venueId, searchTerms, isSource, errHandler) =>
  srGet(
    `/api-yoa/clients`,
    {
      venue: venueId,
      npeq: searchTerms,
      is_source: isSource ? '1' : '0',
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response.error)
    }

    return {
      areThirdPartyErrors: response.data.are_third_party_errors,
      clients: response.data.clients.map(c => {
        // Juggle those API models
        // eslint-disable-next-line no-param-reassign
        c.tags_display = c.client_tags_display
        // eslint-disable-next-line no-param-reassign
        c.email_address = c.email
        // eslint-disable-next-line no-param-reassign
        c.name_display = c.name_first_first_display
        return c
      }),
    }
  })

const fetchHotelClientLookup = (venueId, hotelId, searchTerms, errHandler) =>
  srGet(
    `/api-yoa/hotel_clients`,
    {
      venue: venueId,
      hotel_id: hotelId,
      q: searchTerms,
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response.errorMsg)
    }
    return response.data.clients
  })

const fetchHotelClientBenefits = profileId =>
  srGet(
    `/api-yoa/hotel_clients/benefits`,
    {
      profile_id: profileId,
    },
    { skipLoadClickBlocker: true }
  ).then(response => response.data.benefits_url)

const fetchClientHydrated = (venueId, clientId, errHandler) =>
  srGet(
    `/manager/${venueId}/clients/matched/${clientId}`,
    {},
    {
      headers: { Accept: 'application/json' },
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).then(response => {
    if (response.statusCode !== 200 && errHandler) {
      errHandler(response.error)
    }
    const client = response.payload.venue_group_client
    const { actuals } = response.payload
    return { venueId, client, actuals }
  })

export default {
  fetchClientLookup,
  fetchHotelClientLookup,
  fetchClientHydrated,
  fetchHotelClientBenefits,
}
