import { useMemo } from 'react'
import type { ReservationWidget } from '@sevenrooms/core/domain'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { CustomFormSelect } from '../customComponents/CustomFormSelect'
import { calculateTotal } from '../payment-utils'
import { paymentMessages } from '../Payment.locales'
import { ChargeLine } from './ChargeLine'
import type { ChargesForm } from '../Payment.zod'

interface TaxProps {
  amount: number | null
  field: Field<ChargesForm>
  oldValue?: string | null
  currencyCode: string
  override: boolean
  taxGroups: ReservationWidget.TaxGroup[]
  forceDisableEdit?: boolean // need for beta
}

export function Tax({ amount, field, oldValue, override, currencyCode, taxGroups, forceDisableEdit }: TaxProps) {
  const { formatMessage } = useLocales()
  const taxOptions = useMemo(() => taxGroups.map(({ id, taxName }) => ({ id, label: taxName })), [taxGroups])

  const [applyTax, taxId, applyService, service] = useWatchMany(field, ['applyTax', 'taxId', 'applyService', 'service'])
  const isShowInput = applyTax

  const chargeAmount =
    amount && applyTax
      ? calculateTotal(amount, { taxId: applyTax ? taxId : null, applyTax, service, applyService }, taxGroups, false).taxAmount
      : 0
  const taxRate = taxGroups.find(group => group.id === taxId)?.taxRate ?? 0
  const oldTaxRate = taxGroups.find(group => group.id === oldValue)?.taxRate ?? 0

  return (
    <ChargeLine
      title={formatMessage(paymentMessages.tax)}
      field={taxGroups.length && override && !forceDisableEdit ? field.prop('applyTax') : undefined}
      testId="sr-supafly-apply-tax"
      oldAmount={oldValue !== undefined && oldTaxRate !== taxRate ? oldTaxRate : null}
      amount={chargeAmount}
      currencyCode={currencyCode}
    >
      {isShowInput && (
        <CustomFormSelect
          data-test="sr-supafly-tax-group"
          field={field.prop('taxId')}
          withEmpty
          hideEmptyOption
          options={taxOptions}
          searchable={false}
          hideErrorMessage
          disabled={!override || forceDisableEdit}
        />
      )}
    </ChargeLine>
  )
}
