// Constant Action Types for Night Life Booking Widget

export const INITIALIZE = 'INITIALIZE'
export const REVERT_STAGE = 'REVERT_STAGE'
export const ADVANCE_STAGE = 'ADVANCE_STAGE'
export const TOGGLE_FIELD = 'TOGGLE_FIELD'
export const CHANGE_PHONE_NUM = 'CHANGE_PHONE_NUM'
export const CHANGE_FIELD = 'CHANGE_FIELD'
export const POST_CHECKOUT_SUCCESS = 'POST_CHECKOUT_SUCCESS'
export const VALIDATE_ALL_FIELDS = 'VALIDATE_ALL_FIELDS'
export const TRY_POST_CHECKOUT = 'TRY_POST_CHECKOUT'
export const POST_CHECKOUT_FAILURE = 'POST_CHECKOUT_FAILURE'
export const POST_CHECKOUT_RELOAD_ON_FAILURE = 'POST_CHECKOUT_RELOAD_ON_FAILURE'
export const HOVER_DATE = 'HOVER_DATE'
export const CHANGE_FORM_FIELD = 'CHANGE_FORM_FIELD'
export const CHANGE_GRATUITY_PERCENTAGE = 'CHANGE_GRATUITY_PERCENTAGE'
export const OPEN_SELECT = 'OPEN_SELECT'
export const SELECT_DETAIL = 'SELECT_DETAIL'
export const PERFORM_SEARCH = 'PERFORM_SEARCH'
export const RECEIVE_RESULTS = 'RECEIVE_RESULTS'
export const DISPLAY_MODAL = 'DISPLAY_MODAL'
export const DISMISS_MODAL = 'DISMISS_MODAL'
export const STRIPE_ERROR = 'STRIPE_ERROR'
export const SELECT_TIME_SLOT = 'SELECT_TIME_SLOT'
export const TOGGLE_SEARCH_COMPONENT = 'TOGGLE_SEARCH_COMPONENT'
export const SELECT_QUERY_DATE = 'SELECT_QUERY_DATE'
export const SELECT_QUERY_PARTY_SIZE = 'SELECT_QUERY_PARTY_SIZE'
export const SELECT_QUERY_TIME = 'SELECT_QUERY_TIME'
export const REVEAL_MORE_AVAILABILITY = 'REVEAL_MORE_AVAILABILITY'
export const TRY_AGAIN = 'TRY_AGAIN'
export const PASS_STRIPE_CARD_ELEMENT = 'PASS_STRIPE_CARD_ELEMENT'
export const SET_NETWORK_REDUX_RESPONSE = 'SET_NETWORK_REDUX_RESPONSE'
export const SET_PAYMENT_PENDING_RESPONSE = 'SET_PAYMENT_PENDING_RESPONSE'
export const SET_FREEDOMPAY_INITIAL_DATA = 'SET_FREEDOMPAY_INITIAL_DATA'
export const SET_FREEDOMPAY_VISIBLE = 'SET_FREEDOMPAY_VISIBLE'
export const SET_FREEDOMPAY_IFRAME_INITIALIZED = 'SET_FREEDOMPAY_IFRAME_INITIALIZED'
export const HIDE_LOADING = 'HIDE_LOADING'
export const DISABLE_INPUTS_AND_SUBMIT = 'DISABLE_INPUTS_AND_SUBMIT'
