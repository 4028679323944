import { SET_DATA_FOR_3D_SECURE, SET_DATA_FOR_PENDING_PAYMENT, SET_PAYMENT_ENGINE, SET_ADYEN_PAYMENT_TYPE } from 'svr/component-lib/Widget/Payments/ActionTypes'

export const setDataFor3Dsecure = dataFor3Dsecure => ({
  type: SET_DATA_FOR_3D_SECURE,
  dataFor3Dsecure,
})

export const setPendingPaymentContext = data => ({
  type: SET_DATA_FOR_PENDING_PAYMENT,
  data,
})

export const setAdyenPaymentType = data => ({
  type: SET_ADYEN_PAYMENT_TYPE,
  data,
})

export const setPaymentEngine = paymentEngine => ({
  type: SET_PAYMENT_ENGINE,
  paymentEngine,
})
