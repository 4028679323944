import _ from 'lodash'
import React, { PureComponent } from 'react'
import { Beforeunload } from 'react-beforeunload'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as BookActions from 'mgr/actualslideout/actions/BookActions'
import * as BookAvailabilityActions from 'mgr/actualslideout/actions/BookAvailabilityActions'
import * as BookClientActions from 'mgr/actualslideout/actions/BookClientActions'
import * as BookDetailsActions from 'mgr/actualslideout/actions/BookDetailsActions'
import * as PaymentActions from 'mgr/actualslideout/actions/PaymentActions'
import VmsIcons, { StyledVmsIconS } from 'svr/common/VmsIcons'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import MessageBox from '../../../../component-lib/Manager/MessageBox'
import AvailabilityStep from './booksteps/AvailabilityStep'
import ClientStep from './booksteps/ClientStep'
import DetailsStep from './booksteps/DetailsStep'
import MessagingStep from './booksteps/MessagingStep'
import PaymentStep from './booksteps/PaymentStep'
import SourceStep from './booksteps/SourceStep'
import { UpgradesStep } from './booksteps/UpgradesStep'
import ReservationSlideout from './ReservationSlideout'
import { addMultiVenueAvailabilityTimeslot } from '@sevenrooms/core/api'
import MissingEmailConfirmationModal from '../components/view/MissingEmailConfirmationModal'
import { SubmitButton } from './view/SubmitButton'

const BookStepWrapper = styled.div`
  padding: 2px 0 73px;
`

const BookFooter = styled.div`
  height: 54px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 7px 17px;
  z-index: 10;
  background-color: ${props => props.theme.mediumGrey};
`

const ErrorDialogWrapper = styled.div`
  position: absolute;
  bottom: 78px;
  left: 18px;
  right: 18px;
  background-color: ${props => props.theme.error};
  box-shadow: 0 2px 4px 0 rgba(72, 72, 76, 0.5);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  transition: all 100ms ease-in-out;
  opacity: ${props => (props.isVisible ? '1' : '0')};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`

const ErrorIcon = styled(StyledVmsIconS)`
  width: 38px;
  text-align: right;
  color: ${props => props.theme.white};
`

const ErrorBody = styled.ul`
  flex-grow: 5;
  color: ${props => props.theme.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin: 0 15px !important;
  padding: 15px 0 !important;
  > li {
    list-style-position: inside;
  }
`

const ErrorDismiss = styled(StyledVmsIconS)`
  width: 38px;
  padding: 15px 0;
  text-align: left;
  color: ${props => props.theme.white};
  cursor: pointer;
`

// Define constant step keys, since can't find a way to get a class identifier prop that works in all browsers
// and we want the key to persist when steps change order
const AvailabilityStepDef = [AvailabilityStep, 'AvailabilityStep']
const ClientStepDef = [ClientStep, 'ClientStep']
const PaymentStepDef = [PaymentStep, 'PaymentStep']
const SourceStepDef = [SourceStep, 'SourceStep']
const DetailsStepDef = [DetailsStep, 'DetailsStep']
const MessagingStepDef = [MessagingStep, 'MessagingStep']
const UpgradesStepDef = [UpgradesStep, 'UpgradesStep']

export const validateFieldMapsDefault = {
  availability: {},
  client: {},
  payment: {},
  source: {},
  details: {},
  messaging: {},
  upgrades: {},
}

class BookReservation extends PureComponent {
  constructor(props) {
    super(props)
    this.validateFieldMaps = { ...validateFieldMapsDefault }
  }

  componentDidMount() {
    const { actions, isEditMode } = this.props
    actions.changeBookStepHeight(isEditMode ? 'all' : 'messaging')
  }

  onSubmitClickHandler = async () => {
    const { actions, isFormSubmitting } = this.props
    if (isFormSubmitting) {
      return
    }

    const findInvalidFieldsInSection = async validateFieldMap => {
      const entries = Object.entries(validateFieldMap)
      const errors = {}
      for (const [key, input] of entries) {
        const invalidText = !_.isNil(input) && ((input.props && input.props.disabled) || (await input.isValid()))
        if (typeof invalidText === 'string') {
          errors[key] = invalidText
        }
      }
      return errors
    }

    const invalidAvailabilityFields = await findInvalidFieldsInSection(this.validateFieldMaps.availability)
    const invalidClientFields = await findInvalidFieldsInSection(this.validateFieldMaps.client)
    const invalidDetailsFields = await findInvalidFieldsInSection(this.validateFieldMaps.details)
    const invalidMessagingFields = await findInvalidFieldsInSection(this.validateFieldMaps.messaging)
    const invalidSourceFields = await findInvalidFieldsInSection(this.validateFieldMaps.source)
    const invalidUpgradesFields = await findInvalidFieldsInSection(this.validateFieldMaps.upgrades)

    let invalidPaymentFields = {}
    let paymentFields = {}

    if (this.props.validatePaymentsPaylink) {
      paymentFields.paylink_auto_cancel = this.validateFieldMaps.payment.paylink_auto_cancel
      if (this.props.validatePayments) {
        paymentFields = { ...this.validateFieldMaps.payment }
      }
    } else if (this.props.validatePayments) {
      paymentFields = { ...this.validateFieldMaps.payment }
      delete paymentFields.paylink_auto_cancel
    }

    invalidPaymentFields = await findInvalidFieldsInSection(paymentFields)
    if (this.props.validatePayments && !this.props.validatePaymentsPaylink && this.props.takePaymentOrSave !== 'none') {
      const ce = this.props.stripeCardElement
      if (ce && (ce._empty || !ce._complete) && !invalidPaymentFields.card_holder_name) {
        invalidPaymentFields.card_holder_name = 'Invalid credit card details'
      }
    }

    const { selectedTimeSlot, actual, partySize } = this.props
    const selectedTableIds = this.props.selectedTableIds.map(({ value }) => value).filter(notEmpty => notEmpty)
    const cannotSaveFields = {}

    if (selectedTimeSlot) {
      if (selectedTimeSlot.status === 'overbook_enforced_shift_party_size' && !this.props.canOverbookEnforcedShiftPartySize) {
        cannotSaveFields.cannotOverbookEnforcedShiftPartySize =
          'You do not have the needed permissions to book this time, please select another time and try again.'
      } else if (selectedTimeSlot.status === 'blocked') {
        const requiresPrivilege = actual
          ? !(
              actual.date_moment.isSame(selectedTimeSlot.timeMoment, 'day') &&
              actual.arrival_time_sort_order === selectedTimeSlot.sort_order &&
              actual.duration === selectedTimeSlot.duration &&
              actual.venue_id === selectedTimeSlot.venue_id &&
              actual.max_guests === partySize
            )
          : true

        let hasPrivilege = true
        if (requiresPrivilege) {
          // Blocked tables
          if (selectedTimeSlot.blocked.length > 0) {
            if (selectedTableIds.length > 0) {
              hasPrivilege = selectedTimeSlot.blocked
                .filter(({ id }) => selectedTableIds.includes(id))
                .every(table => table.has_permissions)
            } else {
              hasPrivilege = selectedTimeSlot.blocked.some(table => table.has_permissions)
            }
          }

          // Held tables
          if (selectedTimeSlot.held.length > 0) {
            hasPrivilege = hasPrivilege && this.props.canOverbookAccessBlocks
          }
        }

        if (!hasPrivilege) {
          cannotSaveFields.cannotOverride = `You do not have permissions to override this block.`
        }
      } else if (selectedTimeSlot.status === 'no_tables') {
        let canOverbookSpecific = this.props.canOverbookSmallerTables || this.props.canOverbookLargerTables
        if (selectedTableIds.length > 0) {
          const requiresOverbookSmall = selectedTimeSlot.table_size_too_small.filter(({ id }) => selectedTableIds.includes(id)).length > 0
          const requiresOverbookLarge = selectedTimeSlot.table_size_too_large.filter(({ id }) => selectedTableIds.includes(id)).length > 0
          canOverbookSpecific =
            (!requiresOverbookSmall || this.props.canOverbookSmallerTables) &&
            (!requiresOverbookLarge || this.props.canOverbookLargerTables)
        }
        if (!(this.props.canOverbook || canOverbookSpecific)) {
          cannotSaveFields.cannotOverbook =
            'You do not have the needed permissions to book this time, please select another time and try again.'
        }
      }
    }

    const formErrors = {
      ...invalidAvailabilityFields,
      ...invalidClientFields,
      ...invalidPaymentFields,
      ...invalidDetailsFields,
      ...invalidMessagingFields,
      ...invalidSourceFields,
      ...invalidUpgradesFields,
      ...cannotSaveFields,
    }

    const firstInvalidStep = (() => {
      switch (true) {
        case !_.isEmpty(invalidAvailabilityFields):
          return 'availability'
        case !_.isEmpty(invalidClientFields):
          return 'client'
        case !_.isEmpty(invalidPaymentFields):
          return 'payment'
        case !_.isEmpty(invalidSourceFields):
          return 'source'
        case !_.isEmpty(invalidDetailsFields):
          return 'details'
        case !_.isEmpty(invalidMessagingFields):
          return 'messaging'
        case !_.isEmpty(invalidUpgradesFields):
          return 'upgrades'
        default:
          return null
      }
    })()

    actions.bookFormValidated(formErrors, firstInvalidStep)

    if (_.isEmpty(formErrors)) {
      actions.clickBookReservation()
    }
  }

  renderErrorDialog() {
    const { actions, formErrors, didDismissBookErrorDisplay } = this.props
    const formErrorMessages = _.compact(_.values(formErrors)).sort()
    const isVisible = !didDismissBookErrorDisplay && !_.isEmpty(formErrorMessages)
    return (
      <ErrorDialogWrapper data-test="sr-error-dialog" {...{ isVisible }}>
        <ErrorIcon>{VmsIcons.WarningLine}</ErrorIcon>
        <ErrorBody>
          {formErrorMessages.map(e => (
            <li key={e}>
              {e}
              <br />
            </li>
          ))}
        </ErrorBody>
        <ErrorDismiss onClick={actions.dismissBookErrorDisplay}>{VmsIcons.Close}</ErrorDismiss>
      </ErrorDialogWrapper>
    )
  }

  handleOnBeforePageUnload = () => {
    if (this.props.isDirty) {
      this.props.actions.onBeforePageUnload()
      const { actions } = this.props
      window.setTimeout(() => window.setTimeout(() => actions.dismissUnsavedWarning(), 200), 1)
      return 'You sure about that?'
    }

    return null
  }

  render() {
    const { actions, isEditMode, isStepOrderAvailabilityFirst, isFormSubmitting, validatePaymentsPaylink } = this.props
    const { validateFieldMaps } = this
    const title = isEditMode ? 'Edit Reservation' : 'Add Reservation'
    const showBlurBackground = true

    let unsavedModal = null

    if (this.props.doShowUnsavedWarning) {
      unsavedModal = (
        <MessageBox
          handleActionClick={this.props.actions.ignoreUnsavedWarning}
          handleCloseClick={this.props.actions.dismissUnsavedWarning}
          dialogType={MessageBox.DialogType.WARNING}
          header="Unsaved reservation"
          actionButtonText="Discard Changes"
          explanation="The reservation has been edited but not saved"
        />
      )
    }

    if (this.props.doShowHigherChargeWarning) {
      unsavedModal = (
        <MessageBox
          handleActionClick={() => {
            this.props.actions.ignoreHigherChargeWarning()
            this.props.actions.clickBookReservation(true)
          }}
          handleCloseClick={this.props.actions.dismissHigherChargeWarning}
          dialogType={MessageBox.DialogType.WARNING}
          header="Are you sure?"
          actionButtonText={validatePaymentsPaylink ? 'Save and Send Paylink' : 'Save and Charge Card'}
          explanation="Your changes will result in a higher payment required for the reservation"
        />
      )
    }

    if (this.props.doShowMissingEmailWarning && !this.props.missingEmailWarningIgnored) {
      unsavedModal = (
        <MissingEmailConfirmationModal
          onClose={() => this.props.actions.dismissMissingEmailWarning()}
          onSubmit={() => {
            this.props.actions.ignoreMissingEmailWarning()
            this.props.actions.clickBookReservation()
          }}
        />
      )
    }

    const footer = (
      <BookFooter {...{ actions }}>
        {this.renderErrorDialog()}
        <SubmitButton onSubmit={this.onSubmitClickHandler} isLoading={isFormSubmitting} />
      </BookFooter>
    )

    const stepOrderings = [
      ...(isStepOrderAvailabilityFirst ? [AvailabilityStepDef, ClientStepDef] : [ClientStepDef, AvailabilityStepDef]),
      UpgradesStepDef,
      PaymentStepDef,
      SourceStepDef,
      DetailsStepDef,
      MessagingStepDef,
    ]
    return (
      <div>
        {unsavedModal}
        <Beforeunload onBeforeunload={this.handleOnBeforePageUnload}>
          <ReservationSlideout
            {...{
              title,
              footer,
              showBlurBackground,
            }}
          >
            <BookStepWrapper>
              {stepOrderings.map(([StepClass, StepKey]) => (
                <StepClass {...{ actions, validateFieldMaps }} key={StepKey} />
              ))}
            </BookStepWrapper>
          </ReservationSlideout>
        </Beforeunload>
      </div>
    )
  }
}

BookReservation.propTypes = {
  actions: React.PropTypes.object,
  isEditMode: React.PropTypes.bool,
  isStepOrderAvailabilityFirst: React.PropTypes.bool,
  formErrors: React.PropTypes.object,
  didDismissBookErrorDisplay: React.PropTypes.bool,
}

const mapStateToProps = state => {
  const hasPayment =
    state.viewResState.actual && (state.viewResState.actual.prepayment_formatted || state.viewResState.actual.payments_billing_profile)
  const requireCard = state.bookPaymentState.cardRequired && !state.bookPaymentState.override && !hasPayment
  const hasTotalPayment =
    state.bookPaymentState.internalUpsellsEnabled &&
    Number(state.bookPaymentState.chargeTotal) > 0 &&
    state.bookPaymentState.takePaymentOrSave === 'take'

  const { saferpayNameEmpty } = state.bookPaymentState
  const saferpayCheckout = state.bookState.selectedVenue.bookSettings.paymentSystem === AccountTypes.SAFERPAY && !saferpayNameEmpty

  return {
    isEditMode: !_.isEmpty(state.slideoutState.actualId),
    isStepOrderAvailabilityFirst: state.bookState.isStepOrderAvailabilityFirst,
    isFormSubmitting: state.bookState.isFormSubmitting,
    formErrors: state.bookState.formErrors,
    didDismissBookErrorDisplay: state.bookState.didDismissBookErrorDisplay,
    validatePayments: !!(requireCard || state.bookPaymentState.cardHolderName || saferpayCheckout || hasTotalPayment),
    validatePaymentsPaylink: state.bookPaymentState.cardEntryOption === 'paylink',
    isDirty: state.bookState.isDirty,
    doShowUnsavedWarning: state.bookState.doShowUnsavedWarning,
    doShowHigherChargeWarning: state.bookState.doShowHigherChargeWarning,
    doShowMissingEmailWarning: state.bookState.doShowMissingEmailWarning,
    missingEmailWarningIgnored: state.bookState.missingEmailWarningIgnored,
    isMotoEnabled: state.bookState.selectedVenue.bookSettings.isMotoEnabled,
    stripeCardElement: state.bookPaymentState.stripeCardElement,
    chargeAmountDiff: state.bookPaymentState.chargeAmountDiff,
    canOverbook: state.appState.userDomain.can_overbook,
    canOverbookSmallerTables: state.appState.venue.features.can_overbook_smaller_tables,
    canOverbookLargerTables: state.appState.venue.features.can_overbook_larger_tables,
    canOverbookEnforcedShiftPartySize: state.appState.venue.features.can_overbook_enforced_shift_party_size,
    canOverbookAccessBlocks: state.appState.venue.features.can_overbook_access_blocks,
    selectedTableIds: state.bookDetailsState.selectedTableIds,
    selectedTimeSlot: state.bookAvailabilityState.selectedTimeSlot,
    partySize: state.bookAvailabilityState.partySize,
    actual: state.viewResState.actual,
    takePaymentOrSave: state.bookPaymentState.takePaymentOrSave,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changeClientPhone: BookClientActions.changeClientPhone,
      changeClientAltPhone: BookClientActions.changeClientAltPhone,
      changeSearchVenues: BookActions.changeSearchVenues,
      changeSearchResultsPage: BookAvailabilityActions.changeSearchResultsPage,
      toggleStep: BookActions.toggleStep,
      clearScrollIntoView: BookActions.clearScrollIntoView,
      changeBookStepHeight: BookActions.changeBookStepHeight,
      bookFormValidated: BookActions.bookFormValidated,
      dismissBookErrorDisplay: BookActions.dismissBookErrorDisplay,
      clickBookReservation: BookActions.clickBookReservation,
      changeDate: BookAvailabilityActions.changeDate,
      changePartySize: BookAvailabilityActions.changePartySize,
      changeShift: BookAvailabilityActions.changeShift,
      changeSearchTimeSlot: BookAvailabilityActions.changeSearchTimeSlot,
      changeSelectedTimeSlot: BookAvailabilityActions.changeSelectedTimeSlot,
      changeExternalAvailabilityTimeSlot: BookAvailabilityActions.changeExternalAvailabilityTimeSlot,
      addMultiVenueAvailabilityTimeslot,
      restoreShiftCharges: PaymentActions.restoreShiftCharges,
      changeDuration: BookAvailabilityActions.changeDuration,
      changeSeatingArea: BookAvailabilityActions.changeSeatingArea,
      changeShowAccessRules: BookAvailabilityActions.changeShowAccessRules,
      changeAudience: BookAvailabilityActions.changeAudience,
      changeType: BookAvailabilityActions.changeType,
      changeClientSearchTerms: BookClientActions.changeSearchTerms,
      changeSelectedHotelId: BookClientActions.changeSelectedHotelId,
      selectExistingClient: BookClientActions.selectExistingClient,
      selectHotelClient: BookClientActions.selectHotelClient,
      deselectClient: BookClientActions.deselectClient,
      changeSelectedClient: BookClientActions.changeSelectedClient,
      changeClientField: BookClientActions.changeClientField,
      changeClientCustomUnindexedField: BookClientActions.changeClientCustomUnindexedField,
      changeClientTags: BookClientActions.changeClientTags,
      toggleShowMoreClientFields: BookClientActions.toggleShowMoreClientFields,
      clickAddAsNewClient: BookClientActions.clickAddAsNewClient,
      changeCardEntryOption: PaymentActions.changeCardEntryOption,
      changeCardHolderName: PaymentActions.changeCardHolderName,
      changeCardNumber: PaymentActions.changeCardNumber,
      changeCardExpMonth: PaymentActions.changeCardExpMonth,
      changeCardExpYear: PaymentActions.changeCardExpYear,
      changeCardCcv: PaymentActions.changeCardCcv,
      changeChargeAmount: PaymentActions.changeChargeAmount,
      changeChargeApplyTax: PaymentActions.changeChargeApplyTax,
      changeApplyServiceCharge: PaymentActions.changeApplyServiceCharge,
      changeServiceCharge: PaymentActions.changeServiceCharge,
      changeApplyGratuityCharge: PaymentActions.changeApplyGratuityCharge,
      changeGratuityCharge: PaymentActions.changeGratuityCharge,
      changePaylinkGratuityTypes: PaymentActions.changePaylinkGratuityTypes,
      changeTaxGroupId: PaymentActions.changeTaxGroupId,
      changePaymentForm: PaymentActions.changePaymentForm,
      changeChargeDescription: PaymentActions.changeChargeDescription,
      changeChargeSendNotification: PaymentActions.changeChargeSendNotification,
      changeOverride: PaymentActions.changeOverride,
      changeUseGuestProfilePhoneNumber: PaymentActions.changeUseGuestProfilePhoneNumber,
      changeCardPhoneNumber: PaymentActions.changeCardPhoneNumber,
      changeCardCountryPhoneNumber: PaymentActions.changeCardCountryPhoneNumber,
      passStripeElement: PaymentActions.passStripeElement,
      saferpayOnValidate: PaymentActions.saferpayOnValidate,
      changePaylinkAutoCancel: PaymentActions.changePaylinkAutoCancel,
      changeTakePaymentOrSave: PaymentActions.changeTakePaymentOrSave,
      changeReservationNotes: BookDetailsActions.changeReservationNotes,
      changeReservationTags: BookDetailsActions.changeReservationTags,
      changeSelectedTables: BookDetailsActions.changeSelectedTables,
      changeSelectedBookedBy: BookDetailsActions.changeSelectedBookedBy,
      changeMessagingField: BookDetailsActions.changeMessagingField,
      addAdditionalBookedBySlot: BookDetailsActions.addAdditionalBookedBySlot,
      changeAdditionalBookedBySlot: BookDetailsActions.changeAdditionalBookedBySlot,
      removeAdditionalBookedBySlot: BookDetailsActions.removeAdditionalBookedBySlot,
      removeAdditionalBookedBy: BookDetailsActions.removeAdditionalBookedBy,
      setBookedByFirstTime: BookDetailsActions.setBookedByFirstTime,
      enableCustomBookedBy: BookDetailsActions.enableCustomBookedBy,
      changeCustomBookedBy: BookDetailsActions.changeCustomBookedBy,
      onBeforePageUnload: BookActions.onBeforePageUnload,
      displayHigherChargeWarning: BookActions.displayHigherChargeWarning,
      dismissUnsavedWarning: BookActions.dismissUnsavedWarning,
      dismissHigherChargeWarning: BookActions.dismissHigherChargeWarning,
      dismissMissingEmailWarning: BookActions.dismissMissingEmailWarning,
      ignoreHigherChargeWarning: BookActions.ignoreHigherChargeWarning,
      ignoreUnsavedWarning: BookActions.ignoreUnsavedWarning,
      ignoreMissingEmailWarning: BookActions.ignoreMissingEmailWarning,
      changePerksField: BookDetailsActions.changePerksField,
      changeSelectedCostOption: BookDetailsActions.changeSelectedCostOption,
      changeCostOptionAmount: BookDetailsActions.changeCostOptionAmount,
      changeIsCustomAssign: BookDetailsActions.changeIsCustomAssign,
      changeCustomIndexedField: BookDetailsActions.changeCustomIndexedField,
      changeCustomUnindexedField: BookDetailsActions.changeCustomUnindexedField,
    },
    dispatch
  ),
})
export default connect(mapStateToProps, mapDispatchToProps)(BookReservation)
