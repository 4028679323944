import { useNavigation } from '@sevenrooms/core/navigation'
import type { DateOnly } from '@sevenrooms/core/timepiece/index'
import { routes } from '@sevenrooms/routes'

export function useGetAccessRuleUrl() {
  const nav = useNavigation()
  return (venueKey: string, date: DateOnly, accessRuleId: string) =>
    nav
      ? nav.href(routes.manager2.settings.availability.accessRulesSettings, {
          params: { venueKey },
          query: { date: date.toIso(), selected_access_rule_id: accessRuleId },
        })
      : `/manager2/${venueKey}/settings/availability/accessrules?date=${date.toIso()}&selected_access_rule_id=${accessRuleId}`
}
