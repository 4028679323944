/* eslint-disable no-param-reassign, no-nested-ternary */
import { CardElement, Elements } from '@stripe/react-stripe-js'
import _ from 'lodash'
import moment from 'moment-timezone'
import money from 'money-math'
import styled from 'styled-components'
import AdyenForm from 'mgr/actualslideout/components/payment/AdyenForm'
import SaferpayForm from 'mgr/actualslideout/components/payment/SaferpayForm'
import { isFreedomPayHpc } from 'mgr/actualslideout/utils/payments'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import SegmentedControl from 'mgr/lib/components/SegmentedControl'
import Checkbox from 'mgr/lib/forms/Checkbox'
import TextInput, { InputRestrictions, ValidatorTypes } from 'mgr/lib/forms/TextInput'
import { getVenueLocalTime } from 'svr/common/TimeUtil'
import { PhoneTextInput } from 'svr/component-lib/Generic/TextInputs/PhoneNumberInput'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import { useChangedReservationInfoWithPaylinkFlow } from 'mgr/actualslideout/components/payment/hooks'
import Shift4Form from 'svr/component-lib/Widget/Payments/Shift4/Shift4Form'
import { PaylinkBanner } from 'mgr/actualslideout/components/view/PaylinkBanner'

const HorizSeparator = styled.div`
  border-bottom: 1px dashed ${props => props.theme.lightGrey};
`

const FieldGroup = styled.div`
  margin: 15px 12px;
  ${props => props.theme.clearFix};
`

const CardInputHolder = styled.div`
  float: left;
  margin-right: 2%;
  width: ${props => (props.isChargeModal ? '49%' : '27%')};
  min-width: ${props => (props.isChargeModal ? '49%' : '27%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    width: 100%;
    min-width: 100%;
    margin-bottom: 10px;
  }
`

const CardInputNumber = styled.div`
  float: left;
  margin-bottom: 12px;
  margin-right: ${props => (props.isChargeModal ? '0' : '2%')};
  width: ${props => (props.isChargeModal ? '49%' : '29%')};
  min-width: ${props => (props.isChargeModal ? '49%' : '29%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    width: 100%;
    min-width: 100%;
    margin-bottom: 10px;
  }
`

const CardInputMonth = styled.div`
  height: 40px;
  float: left;
  margin-right: 2%;
  margin-top: 2px;
  min-width: ${props => (props.isChargeModal ? '22%' : '13%')};
  width: ${props => (props.isChargeModal ? '22%' : '13%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    min-width: 30%;
    width: 30%;
  }
`

const CardInputYear = styled.div`
  height: 40px;
  float: left;
  margin-right: 2%;
  margin-top: 2px;
  min-width: ${props => (props.isChargeModal ? '22%' : '14%')};
  width: ${props => (props.isChargeModal ? '22%' : '14%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    min-width: 30%;
    width: 30%;
  }
`

const CardInputCvv = styled.div`
  height: 40px;
  float: left;
  margin-top: 2px;
  min-width: ${props => (props.isChargeModal ? '15%' : '9%')};
  width: ${props => (props.isChargeModal ? '15%' : '9%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    min-width: 30%;
    width: 30%;
  }
`

const InlineNote = styled.div`
  color: #999;
  font-size: 12px;
  font-style: oblique;
  margin: 9px;
`

const ChargeLine = styled.div`
  width: 100%;
  display: block;
  margin-top: 10px;
`

const ChargeLineInner = styled.div`
  display: block;
  margin-top: 5px;
`

const PaymentsNotAvailableErrorMessage = styled.div`
  font-size: 14px;
  color: red;
`

const CC_AUTO_CANCEL_MINUTES = [-1, 30, 60, 120, 360, 720, 1440, 2880, 4320, 5760, 7200, 8640, 10080, 14400, 20160, 30240]
const CC_AUTO_CANCEL_STRINGS = [
  'Do not auto-cancel',
  '30 minutes',
  '1 hour',
  '2 hours',
  '6 hours',
  '12 hours',
  '24 hours',
  '48 hours',
  '3 days',
  '4 days',
  '5 days',
  '6 days',
  '7 days',
  '10 days',
  '14 days',
  '21 days',
]
const autoCancelReservationOptions = CC_AUTO_CANCEL_MINUTES.map((minutes, index) => ({
  name: CC_AUTO_CANCEL_STRINGS[index],
  value: minutes,
}))

const CreditCardCollectionOptionsEnum = {
  BOTH_DEFAULT_MANUAL: 'BOTH_DEFAULT_MANUAL',
  BOTH_DEFAULT_PAYLINK: 'BOTH_DEFAULT_PAYLINK',
  ONLY_MANUAL: 'ONLY_MANUAL',
  ONLY_PAYLINK: 'ONLY_PAYLINK',
}

function CardInputForm({
  actions,
  currencySymbol,
  paylinkOnly,
  selectedClient,
  isEditMode,
  isChargeModal,
  validateFieldMap,
  formErrors,
  cardDetails,
  chargeDetails,
  resCardDetails,
  paymentRules,
  chargeOnlyModal,
  stripeInstance,
  venueTimezone,
  selectedTimeSlot,
  venue,
}) {
  const [isEditWithOutstandingPaylinkFlow, changedReservationInfo] = useChangedReservationInfoWithPaylinkFlow()

  const paylinkAllowed =
    isChargeModal || chargeDetails.creditCardCollection !== CreditCardCollectionOptionsEnum.ONLY_MANUAL || chargeDetails.outstandingPaylink
  const paylinkOnlyCombined =
    paylinkOnly ||
    !paymentRules.isMotoEnabled ||
    (!isChargeModal && chargeDetails.creditCardCollection === CreditCardCollectionOptionsEnum.ONLY_PAYLINK)

  const isPaymentProviderAvailable = !!stripeInstance || chargeDetails.paymentSystem !== AccountTypes.STRIPE
  const entryOption = paylinkOnlyCombined && !resCardDetails.resCardId ? 'paylink' : chargeDetails.cardEntryOption
  const canAcceptPaymentInfo = entryOption !== 'manual' || isPaymentProviderAvailable

  if (!isChargeModal && entryOption === 'paylink') {
    const fromReservationToNowMinutes = selectedTimeSlot
      ? moment(selectedTimeSlot.real_datetime_of_slot ?? selectedTimeSlot.time_iso).diff(getVenueLocalTime(venueTimezone), 'minutes')
      : Infinity
    const message = 'Auto-Cancel Reservation interval should be less reservation time'
    validateFieldMap.paylink_auto_cancel = {
      isValid: () => {
        const skipNoAmount =
          chargeDetails.takePaymentOrSave === 'none' ||
          (chargeDetails.takePaymentOrSave === 'take' && !parseFloat(chargeDetails.chargeAmount))
        return fromReservationToNowMinutes < chargeDetails.paylinkAutoCancel && chargeDetails.paylinkAutoCancel !== -1 && !skipNoAmount
          ? message
          : true
      },
    }
  }

  const manualEntry = { name: 'Manually Enter Details', value: 'manual' }
  const paylinkEntry = { name: 'Collect Details via Paylink', value: 'paylink' }

  let cardEntryOptions = []

  if (!paylinkOnlyCombined) {
    cardEntryOptions.push(manualEntry)
  }

  if (paylinkAllowed) {
    cardEntryOptions.push(paylinkEntry)
  }

  if (resCardDetails.resCardId) {
    const cardOption = {
      name: `${resCardDetails.resCardType} ....${resCardDetails.resCardLast4}`,
      value: resCardDetails.resCardId,
    }
    cardEntryOptions.push(cardOption)
  }

  const clientCards = (chargeDetails.clientCards || []).map(card => ({
    name: `${card.brand} ....${card.last_4}`,
    value: card.card_id,
  }))

  cardEntryOptions = cardEntryOptions.concat(clientCards)
  const showEntryOptions = cardEntryOptions.length > 1 || cardEntryOptions[0]?.value === 'paylink'

  const thisYear = new Date().getFullYear()

  const expMonthOptions = _.range(1, 13).map(i => ({ name: `${i}`, value: i }))

  // Turns out cards that expire in 50 years are a real thing
  const expYearOptions = _.range(thisYear, thisYear + 50).map(i => ({ name: `${i}`, value: i }))

  const chargeTotalFormatted = chargeDetails.chargeTotal
    ? money.format(chargeDetails.currencyCode, chargeDetails.chargeTotal)
    : money.floatToAmount(0)

  const taxGroupsOptions = _.map(chargeDetails.taxGroups, taxGroup => ({
    name: taxGroup.tax_name,
    value: taxGroup.id,
  }))

  const paylinkGratuityOptions = [
    {
      name: 'Charge Specific Gratuity',
      value: 'gratuity_percentage',
    },
    {
      name: 'Allow Client to Select Gratuity',
      value: 'client_select_gratuity',
    },
    {
      name: 'Require Client to Select Gratuity',
      value: 'require_client_select_charge',
    },
  ]

  const overrideField = (
    <Checkbox
      name="override_payment_requirement"
      label="Override: do not enforce required payment"
      value={chargeDetails.override}
      on={chargeDetails.override}
      onChange={actions.changeOverride}
    />
  )

  const overrideBlock = !isChargeModal && paymentRules.canOverridePayment && (
    <div>
      <FieldGroup key="FieldGroup_OverrideReqPayment">{overrideField}</FieldGroup>
      <HorizSeparator />
    </div>
  )

  const entryOptionsField = (
    <DropdownArrowsPicker
      name="CREDIT CARD DETAILS"
      choices={cardEntryOptions}
      value={entryOption}
      onChangeHandler={actions.changeCardEntryOption}
      isLightTheme
      disabled={cardEntryOptions.length === 1}
      style={{
        float: 'left',
        height: 44,
        width: '51%',
        marginBottom: 10,
      }}
    />
  )

  const cardHolderNameField = (
    <TextInput
      label="CARD HOLDER'S NAME"
      placeholder={formErrors.card_holder_name ? 'Required' : ''}
      value={cardDetails.cardHolderName}
      onChange={actions.changeCardHolderName}
      validator={ValidatorTypes.nameRequired}
      ref={e => (validateFieldMap.card_holder_name = e)}
      isValid={!formErrors.card_holder_name}
    />
  )

  const cardNumberField = (
    <TextInput
      label="CARD NUMBER"
      placeholder={formErrors.card_number ? 'Required' : ''}
      inputRestriction={InputRestrictions.integer}
      value={cardDetails.cardHolderNumber}
      onChange={actions.changeCardNumber}
      validator={ValidatorTypes.cardNumber}
      ref={e => (validateFieldMap.card_number = e)}
      isValid={!formErrors.card_number}
    />
  )

  const cardExpMonthField = (
    <DropdownArrowsPicker
      name="EXP. MONTH"
      placeholder=""
      choices={expMonthOptions}
      value={cardDetails.cardExpMonth}
      onChangeHandler={actions.changeCardExpMonth}
      useOutsideLabel
      isLightTheme
      validator={ValidatorTypes.cardMonthExp}
      ref={e => (validateFieldMap.exp_month = e ? e.instanceRef : null)}
      isValid={!formErrors.exp_month}
      style={{
        width: '100%',
        minWidth: '100%',
      }}
    />
  )

  const cardExpYearField = (
    <DropdownArrowsPicker
      name="EXP. YEAR"
      placeholder=""
      choices={expYearOptions}
      value={cardDetails.cardExpYear}
      onChangeHandler={actions.changeCardExpYear}
      useOutsideLabel
      isLightTheme
      validator={ValidatorTypes.cardYearExp}
      ref={e => (validateFieldMap.exp_year = e ? e.instanceRef : null)}
      isValid={!formErrors.exp_year}
      style={{
        width: '100%',
        minWidth: '100%',
      }}
    />
  )

  const cardCvvField = (
    <TextInput
      label="CVV"
      placeholder={formErrors.card_cvv ? "Req'd" : ''}
      inputRestriction={InputRestrictions.integer}
      value={cardDetails.cardCcv}
      onChange={actions.changeCardCcv}
      validator={ValidatorTypes.cardCvv}
      ref={e => (validateFieldMap.card_cvv = e)}
      isValid={!formErrors.card_cvv}
      style={{
        width: '100%',
        minWidth: '100%',
      }}
    />
  )

  const takePaymentOptions = [
    {
      name: 'Charge Card Now',
      value: 'take',
      testId: 'sr-button-charge_card_now',
      disabled: !chargeDetails.canCharge,
    },
  ]

  if (!isChargeModal) {
    takePaymentOptions.unshift({
      name: 'No Payment',
      value: 'none',
      testId: 'sr-button-no_payment',
    })
  }

  if (paymentRules.canSaveCard) {
    takePaymentOptions.push({
      name: 'Save Card for Later',
      value: 'save',
      testId: 'sr-button-save_card_for_later',
    })
  }

  const showPaymentOptions =
    ((entryOption === 'manual' || entryOption === 'paylink') &&
      paymentRules.paymentRule == null &&
      paymentRules.isMotoEnabled &&
      isPaymentProviderAvailable) ||
    chargeDetails.override ||
    (chargeDetails.takePaymentOrSave === 'none' && !parseFloat(chargeDetails.chargeAmount)) ||
    (entryOption === 'paylink' && takePaymentOptions.length > 1 && isChargeModal)

  const takePaymentOptionsField = (
    <SegmentedControl
      options={takePaymentOptions}
      value={chargeDetails.takePaymentOrSave}
      width={takePaymentOptions.length > 2 ? 388 : 262}
      height={37}
      onChangeHandler={actions.changeTakePaymentOrSave}
    />
  )

  const takePaymentOptionsBlock = showPaymentOptions && !chargeOnlyModal && (
    <FieldGroup key="FieldGroup_TakePaymentOrSave" data-test="takePaymentOrSave-container">
      {takePaymentOptions.length > 1 && <div style={{ position: 'relative' }}>{takePaymentOptionsField}</div>}
      <InlineNote>
        {chargeDetails.takePaymentOrSave === 'take' && 'You will be charging this card now.'}
        {chargeDetails.takePaymentOrSave === 'save' && 'You will be able to charge this card up to 7 days following the reservation date.'}
      </InlineNote>
    </FieldGroup>
  )

  const cardAmountField = (
    <TextInput
      label="Amount"
      labelStyle={{
        color: 'black',
        fontSize: '14px',
        fontWeight: 'normal',
        textTransform: 'none',
      }}
      placeholder={currencySymbol}
      inputRestriction={InputRestrictions.number}
      value={chargeDetails.formattedChargeAmount}
      onChange={actions.changeChargeAmount}
      validator={ValidatorTypes.amountRequired}
      ref={e => (validateFieldMap.amount = e)}
      isValid={!formErrors.amount}
      prefixSymbol={currencySymbol}
      disabled={paymentRules.paymentRule === 'advanced_payment' && !chargeDetails.override}
    />
  )

  const cardTotalField = (
    <TextInput
      label="Total"
      labelStyle={{
        color: 'black',
        fontSize: '14px',
        fontWeight: 'normal',
        textTransform: 'none',
      }}
      placeholder=""
      value={currencySymbol + chargeTotalFormatted}
      disabled
    />
  )

  const applyServiceChargeCheckbox = (
    <Checkbox
      testId="sr-supafly-apply-service-charge"
      name="apply_service_charge"
      label="Apply Service Charge (taxed)"
      value={chargeDetails.applyServiceCharge}
      on={chargeDetails.applyServiceCharge}
      enabled={!(paymentRules.paymentRule === 'advanced_payment' && !chargeDetails.override)}
      onChange={actions.changeApplyServiceCharge}
    />
  )

  const cardServiceChargeField = (
    <TextInput
      testId="sr-supafly-service-charge"
      label=""
      placeholder=""
      postfixSymbol="%"
      inputRestriction={InputRestrictions.number}
      value={chargeDetails.serviceCharge}
      onChange={actions.changeServiceCharge}
      contentAfter="%"
      disabled={Boolean(paymentRules.paymentRule === 'advanced_payment' && !chargeDetails.override)}
    />
  )

  const applyGratuityChargeCheckbox = (
    <Checkbox
      testId="sr-supafly-apply-gratuity-charge"
      name="apply_gratuity_charge"
      label="Apply Gratuity (not taxed)"
      value={chargeDetails.applyGratuityCharge}
      on={chargeDetails.applyGratuityCharge}
      enabled={!(paymentRules.paymentRule === 'advanced_payment' && !chargeDetails.override)}
      onChange={actions.changeApplyGratuityCharge}
    />
  )

  const cardGratuityChargeField = chargeDetails.requiredGratuityCharge ? (
    <TextInput
      testId="sr-supafly-gratuity-charge"
      label="GRATUITY"
      showLabel={false}
      placeholder=""
      postfixSymbol="%"
      inputRestriction={InputRestrictions.number}
      value={chargeDetails.gratuityCharge}
      onChange={actions.changeGratuityCharge}
      contentAfter="%"
      validator={ValidatorTypes.gratuityChargeRequired}
      isValid={!formErrors.gratuity_charge}
      ref={e => (validateFieldMap.gratuity_charge = e)}
    />
  ) : (
    <TextInput
      testId="sr-supafly-gratuity-charge"
      label="GRATUITY"
      showLabel={false}
      placeholder=""
      postfixSymbol="%"
      inputRestriction={InputRestrictions.number}
      value={chargeDetails.gratuityCharge}
      onChange={actions.changeGratuityCharge}
      contentAfter="%"
    />
  )

  const paylinkGratuityTypes = (
    <DropdownArrowsPicker
      testId="sr-supafly-paylink-gratuity-type"
      choices={paylinkGratuityOptions}
      value={chargeDetails.paylinkGratuityType}
      onChangeHandler={value => actions.changePaylinkGratuityTypes(value)}
      useOutsideLabel
      noHeader
      isLightTheme
      validator={ValidatorTypes.notEmpty}
      style={{
        width: '100%',
      }}
    />
  )

  const cardPaylinkGratuityChargeField = (
    <div>
      <ChargeLineInner>{paylinkGratuityTypes}</ChargeLineInner>
      {chargeDetails.paylinkGratuityType === 'gratuity_percentage' && <ChargeLineInner>{cardGratuityChargeField}</ChargeLineInner>}
    </div>
  )

  const cardApplyTaxField = (
    <Checkbox
      testId="sr-supafly-apply-tax"
      name="apply_tax_rate"
      label="Apply Tax"
      value={chargeDetails.chargeApplyTax}
      on={chargeDetails.chargeApplyTax}
      enabled={!((paymentRules.paymentRule === 'advanced_payment' && !chargeDetails.override) || _.isEmpty(taxGroupsOptions))}
      onChange={value => actions.changeChargeApplyTax(value, chargeDetails.taxGroups)}
    />
  )

  const taxGroupIdField = (
    <DropdownArrowsPicker
      noHeader
      testId="sr-supafly-tax-group"
      placeholder="Tax Group"
      choices={taxGroupsOptions}
      value={chargeDetails.taxGroupId}
      onChangeHandler={value => actions.changeTaxGroupId(value, chargeDetails.taxGroups)}
      useOutsideLabel
      isLightTheme
      validator={ValidatorTypes.notEmpty}
      height={40}
      disabled={paymentRules.paymentRule === 'advanced_payment' && !chargeDetails.override}
      style={{
        width: '100%',
      }}
    />
  )

  const autoCancelReservationField = (
    <DropdownArrowsPicker
      useOutsideLabel
      name="Auto-Cancel Reservation if paylink not completed within:"
      testId="sr-supafly-auto-cancel-reservation"
      choices={autoCancelReservationOptions}
      value={chargeDetails.paylinkAutoCancel}
      onChangeHandler={actions.changePaylinkAutoCancel}
      isValid={!formErrors.paylink_auto_cancel}
      isLightTheme
      height={40}
      labelStyle={{
        color: '#000000',
        fontSize: '14px',
        fontWeight: 400,
      }}
      style={{
        width: '100%',
      }}
      disabled={chargeDetails.cardRequired && !chargeDetails.override}
    />
  )

  const cardDescriptionField = (
    <TextInput
      label={entryOption === 'paylink' ? `${isEditWithOutstandingPaylinkFlow ? 'UPDATED' : ''} NOTE TO GUEST` : 'DESCRIPTION'}
      placeholder=""
      inputRestriction={InputRestrictions.none}
      value={chargeDetails.chargeDescription}
      onChange={actions.changeChargeDescription}
    />
  )

  const cardSendNotificationField = (
    <Checkbox
      name="send_notification"
      label={entryOption === 'paylink' ? 'Send Paylink Request' : 'Send Payment Notification'}
      value={chargeDetails.chargeSendNotification}
      on={chargeDetails.chargeSendNotification}
      enabled
      onChange={actions.changeChargeSendNotification}
    />
  )

  const cardNotificationEmail = (
    <TextInput
      disabled={!selectedClient.is_email_address_editable}
      label="EMAIL (FOR FUTURE RECEIPTS)"
      placeholder=""
      inputRestriction={InputRestrictions.email}
      value={chargeDetails.notificationEmail}
      ref={e => (validateFieldMap.email = e)}
      isValid={!formErrors.email}
      onChange={actions.changeNotificationEmail}
      {...(chargeDetails.takePaymentOrSave === 'take' && { validator: ValidatorTypes.emailRequired })}
    />
  )

  if (isEditWithOutstandingPaylinkFlow) {
    return (
      <div>
        {overrideBlock}
        <FieldGroup key="FieldGroup_PaylinkTimer" style={{ width: '367px' }}>
          <PaylinkBanner autoCancelDatetime={chargeDetails.outstandingPaylink} />
        </FieldGroup>
        {(changedReservationInfo.dateTime || changedReservationInfo.partySize) && chargeDetails.takePaymentOrSave === 'take' && (
          <FieldGroup key={`FieldGroup_Description-${entryOption}`}>{cardDescriptionField}</FieldGroup>
        )}
      </div>
    )
  }

  if (chargeDetails.takePaymentOrSave === 'none' && showPaymentOptions) {
    return (
      <div>
        {overrideBlock}
        {takePaymentOptionsBlock}
        <HorizSeparator />
      </div>
    )
  }

  const clientsPhone = selectedClient?.phone_number_formatted
  const phoneField = (
    <FieldGroup key="FieldGroup_CardPhoneField">
      <Checkbox
        name="override_payment_phone"
        label="Use guest profile phone number"
        value={chargeDetails.useGuestProfilePhoneNumber}
        on={chargeDetails.useGuestProfilePhoneNumber}
        onChange={actions.changeUseGuestProfilePhoneNumber}
      />

      <PhoneTextInput
        disabled={chargeDetails.useGuestProfilePhoneNumber}
        label="CARD HOLDER'S PHONE"
        dataTest="sr-slideout-cc-mobile-number"
        country={cardDetails.cardPhoneCountry ?? venue.countryCode}
        displayInitialValueAsLocalNumber={venue.countryCode === selectedClient?.phone_number_locale}
        value={!chargeDetails.useGuestProfilePhoneNumber ? cardDetails.cardPhoneNumber : ''}
        onChange={actions.changeCardPhoneNumber}
        onCountryChange={actions.changeCardCountryPhoneNumber}
        placeholder={!chargeDetails.useGuestProfilePhoneNumber ? '' : clientsPhone}
        validator={ValidatorTypes.phoneRequired}
        ref={e => (validateFieldMap.cardholder_phone = e)}
        isValid={!formErrors.cardholder_phone}
      />
      {/* Err: {formErrors.cardholder_phone} */}
    </FieldGroup>
  )
  const needPhone = isFreedomPayHpc(venue)

  return (
    <div>
      {overrideBlock}
      {takePaymentOptionsBlock}
      <HorizSeparator />
      {!chargeOnlyModal && showEntryOptions && <FieldGroup key="FieldGroup_EntryOptions">{entryOptionsField}</FieldGroup>}
      {!canAcceptPaymentInfo && (
        <FieldGroup>
          <PaymentsNotAvailableErrorMessage>
            {chargeDetails.chargeTotal === '0' || paymentRules.canOverridePayment
              ? `We are unable to process payments right now.
                 Please refresh the page, try again later or proceed by saving this reservation without payment.`
              : `We are unable to process payments right now and this reservation requires payment.
                 Please refresh the page or try again later.`}
          </PaymentsNotAvailableErrorMessage>
        </FieldGroup>
      )}
      {isPaymentProviderAvailable &&
        entryOption === 'manual' &&
        (chargeDetails.paymentSystem === AccountTypes.STRIPE ? (
          <Elements stripe={stripeInstance} key={stripeInstance._key}>
            <div>
              <div style={{ margin: '15px 12px' }}>{cardHolderNameField}</div>
              <div
                style={{
                  padding: '15px 12px',
                  border: '1px solid #dedede',
                  borderRadius: '4px',
                  margin: '15px 12px',
                }}
              >
                <CardElement
                  onReady={actions.passStripeElement}
                  options={{
                    style: {
                      base: {
                        paddingLeft: '10px',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Elements>
        ) : chargeDetails.paymentSystem === AccountTypes.SAFERPAY ? (
          <SaferpayForm onValidate={actions.saferpayOnValidate} />
        ) : chargeDetails.paymentSystem === AccountTypes.ADYEN ? (
          <AdyenForm />
        ) : chargeDetails.paymentSystem === AccountTypes.SHIFT_4 ? (
          <Shift4Form isSlideOut />
        ) : (
          <>
            <FieldGroup key="FieldGroup_CardHolder">
              <CardInputHolder>{cardHolderNameField}</CardInputHolder>
              <CardInputNumber>{cardNumberField}</CardInputNumber>
              <CardInputMonth>{cardExpMonthField}</CardInputMonth>
              <CardInputYear>{cardExpYearField}</CardInputYear>
              <CardInputCvv>{cardCvvField}</CardInputCvv>
            </FieldGroup>
            {needPhone && phoneField}
          </>
        ))}

      {canAcceptPaymentInfo && !chargeOnlyModal && chargeDetails.canCharge && <HorizSeparator />}

      {canAcceptPaymentInfo && (chargeOnlyModal || chargeDetails.takePaymentOrSave === 'take') && (
        <div>
          <FieldGroup key="FieldGroup_TakePayment">
            <ChargeLine>{cardAmountField}</ChargeLine>
            <ChargeLine>
              {applyServiceChargeCheckbox}
              {chargeDetails.applyServiceCharge && <ChargeLineInner>{cardServiceChargeField}</ChargeLineInner>}
            </ChargeLine>
            <ChargeLine>
              {cardApplyTaxField}
              {chargeDetails.chargeApplyTax && <div>{taxGroupIdField}</div>}
            </ChargeLine>
            <ChargeLine>
              {applyGratuityChargeCheckbox}
              {chargeDetails.applyGratuityCharge &&
                (entryOption !== 'paylink' ? (
                  <ChargeLineInner>{cardGratuityChargeField}</ChargeLineInner>
                ) : (
                  <div>{cardPaylinkGratuityChargeField}</div>
                ))}
            </ChargeLine>
            <ChargeLine>{cardTotalField}</ChargeLine>
          </FieldGroup>
        </div>
      )}
      {!isChargeModal && entryOption === 'paylink' && <FieldGroup key="FieldGroup_AutoCancel">{autoCancelReservationField}</FieldGroup>}
      {canAcceptPaymentInfo && (chargeOnlyModal || chargeDetails.takePaymentOrSave === 'take') && (
        <FieldGroup key={`FieldGroup_Description-${entryOption}`}>{cardDescriptionField}</FieldGroup>
      )}
      {isChargeModal &&
      canAcceptPaymentInfo &&
      (chargeOnlyModal || chargeDetails.takePaymentOrSave === 'take' || entryOption === 'paylink') ? (
        <div>
          <FieldGroup key="FieldGroup_SendNotification">{cardSendNotificationField}</FieldGroup>
          {chargeDetails.chargeSendNotification && <FieldGroup key="FieldGroup_NotificationEmail">{cardNotificationEmail}</FieldGroup>}
        </div>
      ) : null}
      {isChargeModal && chargeDetails.takePaymentOrSave === 'save' && entryOption === 'manual' && (
        <FieldGroup>{cardNotificationEmail}</FieldGroup>
      )}
    </div>
  )
}

CardInputForm.propTypes = {
  actions: React.PropTypes.object.isRequired,
  selectedClient: React.PropTypes.object,
  currencySymbol: React.PropTypes.string,
  isEditMode: React.PropTypes.bool,
  isChargeModal: React.PropTypes.bool,
  validateFieldMap: React.PropTypes.object.isRequired,
  formErrors: React.PropTypes.object.isRequired,
  cardDetails: React.PropTypes.object,
  resCardDetails: React.PropTypes.object,
  chargeDetails: React.PropTypes.object,
  stripeInstance: React.PropTypes.object,
  venueTimezone: React.PropTypes.string,
  selectedTimeSlot: React.PropTypes.object,
}

export default CardInputForm
