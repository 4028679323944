import type { ReservationWidget } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Gratuity } from './Gratuity'
import { Service } from './Service'
import { Tax } from './Tax'
import type { PaymentForm, CategoryForm, ChargesForm, ClientSelectGratuityForm } from '../Payment.zod'

interface ChargesProps extends Omit<ChargesBaseProps, 'amount' | 'field'> {
  field: Field<PaymentForm>
  clientSelectGratuity: Field<ClientSelectGratuityForm>
}

export function Charges({ field, clientSelectGratuity, ...props }: ChargesProps) {
  const amount = useWatch(field.prop('amount'))
  return <ChargesBase amount={amount} field={field.prop('charges')} clientSelectGratuity={clientSelectGratuity} {...props} />
}

interface ChargesCategoryProps extends Omit<ChargesBaseProps, 'amount' | 'field'> {
  field: Field<CategoryForm>
  clientSelectGratuity: Field<ClientSelectGratuityForm>
}

export function ChargesCategory({ field, clientSelectGratuity, ...props }: ChargesCategoryProps) {
  const upgrades = useWatch(field.prop('upgrades'))
  const amount = Object.entries(upgrades ?? {}).reduce((acc, [, { amount }]) => (amount ? acc + amount : acc), 0)
  // force disabled edit charges for beta
  return (
    <ChargesBase amount={amount} field={field.prop('charges')} clientSelectGratuity={clientSelectGratuity} forceDisableEdit {...props} />
  )
}

interface ChargesBaseProps {
  override: boolean
  currencyCode: string
  taxGroups: ReservationWidget.TaxGroup[]
  amount: number | null
  field: Field<ChargesForm>
  oldValue?: ChargesForm
  clientSelectGratuity: Field<ClientSelectGratuityForm>
  forceDisableEdit?: boolean // needed for beta
}

function ChargesBase({ taxGroups, oldValue, clientSelectGratuity, ...props }: ChargesBaseProps) {
  return (
    <VStack>
      <Gratuity oldValue={oldValue?.gratuity} clientSelectGratuity={clientSelectGratuity} {...props} />
      <Service oldValue={oldValue?.service} {...props} />
      <Tax taxGroups={taxGroups} oldValue={oldValue?.taxId} {...props} />
    </VStack>
  )
}
