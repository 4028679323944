import React from 'react'
import DropdownArrowsPicker from './DropdownArrowsPicker'
import _ from 'lodash'
import { getShiftReportingToUrlId, getShiftReportingPeriodName } from 'mgr/lib/utils/ShiftReportingPeriodUtils'

const ShiftDropDown = ({
  shiftCategory,
  shiftPersistentId,
  placeholder,
  shifts,
  onChangeShift,
  width,
  style,
  showNavigation,
  isLightTheme,
  isDisabled,
  shiftReportingPeriods,
  shiftReportingPeriodId,
}) => {
  const handleShiftChange = value => {
    const shift = _.find(shifts, { persistent_id: value })
    const shiftReportingPeriod = shift ? undefined : value
    onChangeShift(shift, shiftReportingPeriod)
  }

  let choices = !_.isEmpty(shifts)
    ? [
        {
          name: 'Shifts',
          choices: shifts.map(shift => ({ name: shift.name, value: shift.persistent_id })),
        },
      ]
    : []
  if (!_.isEmpty(shiftReportingPeriods)) {
    choices.push({
      name: 'Shift Reporting Periods',
      choices: shiftReportingPeriods.map(shiftReportingPeriod => ({
        name: getShiftReportingPeriodName(shiftReportingPeriod),
        value: getShiftReportingToUrlId(shiftReportingPeriod),
      })),
    })
  } else {
    choices = choices[0]?.choices || []
  }
  let value = shiftPersistentId
  if (shiftReportingPeriodId && shiftReportingPeriods) {
    const parts = shiftReportingPeriodId.split('-')
    if (parts.length >= 3) {
      const shift_name = parts.pop()
      const period_type = parts.pop()
      const id = parts.join('-')
      if (_.find(shiftReportingPeriods, { id, period_type, shift_name })) {
        value = shiftReportingPeriodId
      }
    }
  }
  if (!value) {
    const shift = _.find(shifts, { category: shiftCategory })
    value = shift ? shift.persistent_id : ''
  }

  return (
    <DropdownArrowsPicker
      name="SHIFT"
      choices={choices}
      value={value}
      placeholder={placeholder}
      onChangeHandler={handleShiftChange}
      style={{
        float: 'left',
        width,
        ...style,
      }}
      disabled={isDisabled}
      showNavigation={showNavigation}
      isLightTheme={isLightTheme}
    />
  )
}

ShiftDropDown.defaultProps = {
  showNavigation: true,
  isLightTheme: false,
  width: 230,
  style: {},
  onChangeShift: () => {},
  isDisabled: false,
  shiftReportingPeriodId: undefined,
  shiftReportingPeriods: [],
}

ShiftDropDown.propTypes = {
  shiftCategory: React.PropTypes.string,
  shiftPersistentId: React.PropTypes.string,
  shifts: React.PropTypes.array.isRequired,
  shiftReportingPeriods: React.PropTypes.array,
  shiftReportingPeriodId: React.PropTypes.object,
  showNavigation: React.PropTypes.bool.isRequired,
  placeholder: React.PropTypes.string,
  isLightTheme: React.PropTypes.bool.isRequired,
  width: React.PropTypes.number.isRequired,
  style: React.PropTypes.object.isRequired,
  onChangeShift: React.PropTypes.func.isRequired,
  isDisabled: React.PropTypes.bool.isRequired,
}

export default ShiftDropDown
