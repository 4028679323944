import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as BookActions from 'mgr/actualslideout/actions/BookActions'
import * as BookSourceActions from 'mgr/actualslideout/actions/BookSourceActions'
import SelectedClientForm from 'mgr/actualslideout/components/client/SelectedClientForm'

const sourceTagValidator = tags => {
  if (_.isEmpty(tags) || _.findIndex(tags, tag => tag.tagGroup.is_source) === -1) {
    return 'At least one source tag is required'
  }
  return true
}
const mapStateToProps = state => {
  const venue = state.bookState.selectedVenue
  const { selectedSource } = state.bookSourceState
  const { lockContactInfoOnPresenceOfThisField } = state.appState.venue
  const doLockFields = !!(
    _.isObject(selectedSource) &&
    lockContactInfoOnPresenceOfThisField &&
    // eslint-disable-next-line no-prototype-builtins
    selectedSource.hasOwnProperty(lockContactInfoOnPresenceOfThisField) &&
    !_.isEmpty(selectedSource[lockContactInfoOnPresenceOfThisField])
  )

  return {
    venue,
    selectedClient: selectedSource,
    bypassRequiredFields: !_.isObject(selectedSource),
    venueClientTagGroups: venue ? state.bookSourceState.sourceTagGroupsByVenue[venue.id] || [] : [],
    showMoreClientFields: state.bookSourceState.showMoreSourceFields,
    formErrors: state.bookState.firstInvalidStep === 'source' ? state.bookState.formErrors : {},
    tagValidator: sourceTagValidator,
    isDisabled: doLockFields,
    lockableClientFields: venue.lockableClientFields,
    alwaysLockedClientFields: venue.alwaysLockedClientFields,
    salutationOptions: state.slideoutState.salutationOptions,
    isSourceMode: true,
  }
}
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      clickAddAsNewClient: BookSourceActions.clickAddAsNewSource,
      changeBookStepHeight: BookActions.changeBookStepHeight,
      changeClientCustomUnindexedField: BookSourceActions.changeSourceCustomUnindexedField,
      changeClientField: BookSourceActions.changeSourceField,
      changeClientSearchTerms: BookSourceActions.changeSearchTerms,
      changeClientTags: BookSourceActions.changeSourceTags,
      changeSelectedClient: BookSourceActions.changeSelectedSource,
      deselectClient: BookSourceActions.deselectSourceClient,
      selectExistingClient: BookSourceActions.selectExistingSource,
      toggleShowMoreClientFields: BookSourceActions.toggleShowMoreSourceFields,
    },
    dispatch
  ),
})
export default connect(mapStateToProps, mapDispatchToProps)(SelectedClientForm)
